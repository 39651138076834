import React from "react";

import "./styles.scss";

const BenefitCard = ({ Icon, title, description, id }) => {
  return (
    <div className="benefitCard" id={id}>
      <div className="benefitCard__iconWrapper">
        <Icon />
      </div>
      <div className="benefitCard__contentWrapper">
        <div className="benefitCard__contentWrapper_title">{title}</div>
        <div className="benefitCard__contentWrapper_description">
          {description}
        </div>
      </div>
    </div>
  );
};

const CardsSection = ({ cardInfo, cardDetails }) => {
  return (
    <div className="cardsSectionWrapper">
      {cardInfo.subtitle && (
        <div className="cardsSectionWrapper__subheading">
          {cardInfo.subtitle}
        </div>
      )}
      <div>
        <div className="cardsSectionWrapper__heading">{cardInfo.title}</div>
        {cardInfo.description && (
          <div className="cardsSectionWrapper__description">
            {cardInfo.description}
          </div>
        )}
      </div>
      <div className="cardsSectionWrapper__benefits">
        {Object.values(cardDetails).map(({ icon, title, description, id }) => (
          <BenefitCard
            key={title}
            Icon={icon}
            title={title}
            description={description}
            id={id}
          />
        ))}
      </div>
    </div>
  );
};

export default CardsSection;
