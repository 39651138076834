import { Motion, spring } from "react-motion";
import React, { Component } from "react";

import Form from "../../RequestModal/Adwords/Form";
import { ReactComponent as TickIcon } from "../../../assets/images/modal/tick.svg";
import { ReactComponent as QualityIcon } from "../../../assets/images/adwords/usps/star.svg";
import { ReactComponent as VisibilityIcon } from "../../../assets/images/adwords/usps/ic-visibilityBlack.svg";
import { ReactComponent as TurnaroundsIcon } from "../../../assets/images/adwords/usps/ic-turnaroundsBlack.svg";
import { ReactComponent as ManagerIcon } from "../../../assets/images/adwords/usps/ic-managerBlack.svg";

import "./styles.scss";

const HIDDEN = "hidden";
const VISIBLE = "visible";
const USPs = [
  {
    title: "Best Prices",
    icon: <QualityIcon />,
  },
  {
    title: "Fastest Delivery",
    icon: <TurnaroundsIcon />,
  },
  {
    title: "End-to-end visibility",
    icon: <VisibilityIcon />,
  },
  {
    title: "Dedicated Account Manager",
    icon: <ManagerIcon />,
  },
];
export default class BottomSheet extends Component {
  state = {
    opacity: 0,
    translate: 100,
    display: HIDDEN,
    isSubmitSuccess: false,
  };

  componentWillMount() {
    if (this.props.startHidden === false) {
      this.setState(
        {
          opacity: 0.5,
          translate: 0,
          display: VISIBLE,
        },
        () => {
          this.disableBodyScroll();
        }
      );
    }
  }

  disableBodyScroll = () => {
    document.body.style.overflow = "hidden";
  };

  enableBodyScroll = () => {
    document.body.style.overflow = "unset";
  };

  componentDidUpdate() {
    if (this.state.display === HIDDEN) {
      this.enableBodyScroll();
    } else {
      this.disableBodyScroll();
    }
  }
  animate = () => {
    this.setState(
      {
        opacity: this.state.opacity === 0.5 ? 0 : 0.5,
        translate: this.state.opacity === 0 ? 0 : 100,
      },
      () => {
        if (this.state.opacity === 0) {
          setTimeout(() => {
            this.setState({
              display: HIDDEN,
            });
          }, 200);
        } else {
          this.setState({
            display: VISIBLE,
          });
        }
      }
    );
  };

  setSubmitSuccess = () => {
    this.setState(
      {
        isSubmitSuccess: true,
      },
      () => {
        this.animate();
      }
    );
  };

  componentWillUnmount() {
    this.enableBodyScroll();
  }

  render() {
    return (
      <>
        <div className="bottomSheet adwordsFormContainer">
          {this.state.isSubmitSuccess ? (
            <div className="adwordsFormContainer__bs_success">
              <TickIcon />
              <div className="heading" style={{fontSize:16}}>
              One of our experts will reach out to you in 24 hours.
              </div>
            </div>
          ) : (
            <button className="btn btn-primary" onClick={this.animate}>
              Request a call back
            </button>
          )}

          <Motion
            style={{
              opacity: spring(this.state.opacity),
              translate: spring(this.state.translate),
            }}
          >
            {({ opacity, translate }) => (
              <div
                style={{
                  visibility: this.state.display,
                }}
                className="containerr"
              >
                <div
                  style={{
                    opacity: opacity,
                  }}
                  className="backgroundContainer"
                  onClick={this.animate}
                />
                <Form
                  containerClass="bottomSheetForm"
                  submitText="Submit"
                  closedText="Close"
                  setSubmitSuccess={this.setSubmitSuccess}
                  onClick={this.animate}
                  closed={true}
                />
              </div>
            )}
          </Motion>
        </div>
        <div className="hero__usps">
          {USPs.map(({ title, icon }, index) => (
            <div className="hero__usps-item" key={index}>
              {icon}
              <span>{title}</span>
            </div>
          ))}
        </div>
      </>
    );
  }
}
