import product1 from "../../assets/images/product/1.png";
import product2 from "../../assets/images/product/2.png";
import product3 from "../../assets/images/product/3.png";
import product4 from "../../assets/images/product/4.png";
import product5 from "../../assets/images/product/5.png";
import product6 from "../../assets/images/product/6.png";
import product7 from "../../assets/images/product/7.png";
import product8 from "../../assets/images/product/8.png";
import product9 from "../../assets/images/product/9.png";
import product10 from "../../assets/images/product/10.png";
import product11 from "../../assets/images/product/11.png";
import product12 from "../../assets/images/product/12.png";
import product13 from "../../assets/images/product/13.png";
import product14 from "../../assets/images/product/14.png";
import product15 from "../../assets/images/product/15.png";
import product16 from "../../assets/images/product/16.png";
import image17 from "../../assets/images/product/17.png";
import image18 from "../../assets/images/product/18.png";
import image19 from "../../assets/images/product/19.png";
import image20 from "../../assets/images/product/20.png";
import image21 from "../../assets/images/product/21.png";
import image22 from "../../assets/images/product/22.png";
import image23 from "../../assets/images/product/23.png";
import image24 from "../../assets/images/product/24.png";
import image25 from "../../assets/images/product/25.png";
import image26 from "../../assets/images/product/26.png";
import image27 from "../../assets/images/product/27.png";
import image28 from "../../assets/images/product/28.png";
import image29 from "../../assets/images/product/29.png";
import image30 from "../../assets/images/product/30.png";
import image31 from "../../assets/images/product/31.png";
import image32 from "../../assets/images/product/32.png";
import image33 from "../../assets/images/product/33.png";
import image34 from "../../assets/images/product/34.png";
import image35 from "../../assets/images/product/35.png";
import image36 from "../../assets/images/product/36.png";
import image37 from "../../assets/images/product/37.png";
import image38 from "../../assets/images/product/38.png";
import image39 from "../../assets/images/product/39.png";
import image40 from "../../assets/images/product/40.png";
import image41 from "../../assets/images/product/41.png";
import image42 from "../../assets/images/product/42.png";
import image43 from "../../assets/images/product/43.png";
import image44 from "../../assets/images/product/44.png";
import image45 from "../../assets/images/product/45.png";
import image46 from "../../assets/images/product/46.png";
import image47 from "../../assets/images/product/47.png";
import image48 from "../../assets/images/product/48.png";
import image49 from "../../assets/images/product/49.png";
import image50 from "../../assets/images/product/50.png";
import image51 from "../../assets/images/product/51.png";
import image52 from "../../assets/images/product/52.png";
import image53 from "../../assets/images/product/53.png";
import image54 from "../../assets/images/product/54.png";
import image55 from "../../assets/images/product/55.png";
import image56 from "../../assets/images/product/56.png";
import image57 from "../../assets/images/product/57.png";
import image58 from "../../assets/images/product/58.png";
import image59 from "../../assets/images/product/59.png";
import image60 from "../../assets/images/product/60.png";
import image61 from "../../assets/images/product/61.png";
import image62 from "../../assets/images/product/62.png";
import image63 from "../../assets/images/product/63.png";
import image64 from "../../assets/images/product/64.png";
import image65 from "../../assets/images/product/65.png";
import image66 from "../../assets/images/product/66.png";
import image67 from "../../assets/images/product/67.png";
import image68 from "../../assets/images/product/68.png";
import image69 from "../../assets/images/product/69.png";
import image70 from "../../assets/images/product/70.png";
import image71 from "../../assets/images/product/71.png";
import image72 from "../../assets/images/product/72.png";
import image73 from "../../assets/images/product/73.png";
import image74 from "../../assets/images/product/74.png";
import image75 from "../../assets/images/product/75.png";
import image76 from "../../assets/images/product/76.png";
import image77 from "../../assets/images/product/77.png";
import image78 from "../../assets/images/product/78.png";
import image79 from "../../assets/images/product/79.png";
import image80 from "../../assets/images/product/80.png";
import image81 from "../../assets/images/product/81.png";
import image82 from "../../assets/images/product/82.png";
import image83 from "../../assets/images/product/83.png";
import image84 from "../../assets/images/product/84.png";
import image85 from "../../assets/images/product/85.png";
import image86 from "../../assets/images/product/86.png";
import image87 from "../../assets/images/product/87.png";
import image88 from "../../assets/images/product/88.png";
import image89 from "../../assets/images/product/89.png";
import image90 from "../../assets/images/product/90.png";
import image91 from "../../assets/images/product/91.png";

export const ProductArray = [
  {
    img: product1,
    fabric: "European Summer",
    price: "₹ 999.00",
    colors: [
      {
        color: "#C7ADA9",
      },
      {
        color: "#D3D0CB",
      },
      {
        color: "#DBCC4F",
      },
      {
        color: "#B4B3B8",
      },
      {
        color: "#D3B3BB",
      },
      {
        color: "#D4A0A7",
      },
    ],
    link: "https://swatchbox.locofast.com/products/european-summer",
    images: [image17, image18, image19, image20],
  },
  {
    img: product2,
    fabric: "Cotton Yarn Dyed Fall",
    price: "₹ 999.00",
    colors: [
      {
        color: "#D27938",
      },
      {
        color: "#E5CCA9",
      },
      {
        color: "#B75657",
      },
      {
        color: "#47665A",
      },
      {
        color: "#D98389",
      },
      {
        color: "#696451",
      },
    ],
    link: "https://swatchbox.locofast.com/products/cotton-yarn-dyed-fall",
    images: [image21, image22, image23, image24, image25],
  },
  {
    img: product3,
    fabric: "Nursery",
    price: "₹ 999.00",
    colors: [
      {
        color: "#B9C5B7",
      },
      {
        color: "#E8DFC3",
      },
      {
        color: "#EBC8BF",
      },
      {
        color: "#BFBFC5",
      },
      {
        color: "#C57265",
      },
      {
        color: "#1F1C22",
      },
    ],
    link: "https://swatchbox.locofast.com/products/nursery",
    images: [image26, image27, image28, image29, image30],
  },
  {
    img: product4,
    fabric: "Linen And Cotton Linen",
    price: "₹ 999.00",
    colors: [
      {
        color: "#2E4871",
      },
      {
        color: "#7E93BB",
      },
      {
        color: "#E85955",
      },
      {
        color: "#A95D8E",
      },
      {
        color: "#B1C2AE",
      },
      {
        color: "#AF2943",
      },
    ],
    link: "https://swatchbox.locofast.com/products/linen-and-cotton-linen",
    images: [image84, image85, image86],
  },
  {
    img: product5,
    fabric: "Rayon Floral Print",
    price: "₹ 999.00",
    colors: [
      {
        color: "#1A1914",
      },
      {
        color: "#6486A0",
      },
      {
        color: "#F0BD37",
      },
      {
        color: "#D44F30",
      },
      {
        color: "#AC5D5B",
      },
      {
        color: "#94BAB5",
      },
    ],
    link: "https://swatchbox.locofast.com/products/rayon-print-floral",
    images: [image31, image32, image33, image34, image35],
  },
  {
    img: product6,
    fabric: "Linen Floral Print",
    price: "₹ 999.00",
    colors: [
      {
        color: "#A9A38C",
      },
      {
        color: "#131418",
      },
      {
        color: "#B14543",
      },
      {
        color: "#3E7C35",
      },
      {
        color: "#BE9A45",
      },
      {
        color: "#6A957F",
      },
    ],
    link: "https://swatchbox.locofast.com/products/linen-floral-print",
    images: [image87, image88, image89, image90, image91],
  },
  {
    img: product7,
    fabric: "Cotton Lurex",
    price: "₹ 999.00",
    colors: [
      {
        color: "#B4B192",
      },
      {
        color: "#BB9B4E",
      },
      {
        color: "#928D6E",
      },
      {
        color: "#BBB897",
      },
      {
        color: "#9A8842",
      },
      {
        color: "#B7AF93",
      },
    ],
    link: "https://swatchbox.locofast.com/products/cotton-lurex",
    images: [image36, image37, image38, image39, image40],
  },
  {
    img: product8,
    fabric: "Cotton Gingham Yarn Dyed",
    price: "₹ 999.00",
    colors: [
      {
        color: "#2E195E",
      },
      {
        color: "#D3955F",
      },
      {
        color: "#365CBD",
      },
      {
        color: "#6EA0A8",
      },
      {
        color: "#D95F5F",
      },
      {
        color: "#C1C47D",
      },
    ],
    link: "https://swatchbox.locofast.com/products/cotton-gingham-yarn-dyed-swatch-box",
    images: [image41, image42, image43, image44, image45],
  },
  {
    img: product9,
    fabric: "Cotton Lehariya Print",
    price: "₹ 999.00",
    colors: [
      {
        color: "#034A48",
      },
      {
        color: "#810F23",
      },
      {
        color: "#C19D14",
      },
      {
        color: "#42967E",
      },
      {
        color: "#66671C",
      },
      {
        color: "#275DA0",
      },
    ],
    link: "https://swatchbox.locofast.com/products/cotton-lehariya-print",
    images: [image46, image47, image48, image49, image50],
  },
  {
    img: product10,
    fabric: "Cotton Bandhani Print",
    price: "₹ 999.00",
    colors: [
      {
        color: "#BA0308",
      },
      {
        color: "#059579",
      },
      {
        color: "#36495F",
      },
      {
        color: "#CA8C07",
      },
      {
        color: "#98B842",
      },
      {
        color: "#C382B2",
      },
    ],
    link: "https://swatchbox.locofast.com/products/cotton-bandhani-print",
    images: [image51, image52, image53, image54, image55],
  },
  {
    img: product11,
    fabric: "Rayon Tie and Dye",
    price: "₹ 999.00",
    colors: [
      {
        color: "#B5787F",
      },
      {
        color: "#4A5770",
      },
      {
        color: "#9CA38B",
      },
      {
        color: "#53653F",
      },
      {
        color: "#643950",
      },
      {
        color: "#02050B",
      },
    ],
    link: "https://swatchbox.locofast.com/products/rayon-tie-and-dye",
    images: [image56, image57, image58, image59, image60],
  },
  {
    img: product12,
    fabric: "Tropical",
    price: "₹ 999.00",
    colors: [
      {
        color: "#BF98A2",
      },
      {
        color: "#CA7F65",
      },
      {
        color: "#58B8D2",
      },
      {
        color: "#82A787",
      },
      {
        color: "#C19559",
      },
      {
        color: "#85A195",
      },
    ],
    link: "https://swatchbox.locofast.com/products/tropical",
    images: [image61, image62, image63, image64, image65],
  },
  {
    img: product13,
    fabric: "On The Deck",
    price: "₹ 999.00",
    colors: [
      {
        color: "#C78994",
      },
      {
        color: "#B6B2BF",
      },
      {
        color: "#D6D7D8",
      },
      {
        color: "#7383A5",
      },
      {
        color: "#CA2131",
      },
      {
        color: "#495F7A",
      },
    ],
    link: "https://swatchbox.locofast.com/products/on-the-deck",
    images: [image66, image67, image68, image69, image70],
  },
  {
    img: product14,
    fabric: "Lavender",
    price: "₹ 999.00",
    colors: [
      {
        color: "#E585B7",
      },
      {
        color: "#B5B3C9",
      },
      {
        color: "#CFCFC4",
      },
      {
        color: "#DBAFD1",
      },
      {
        color: "#DCBFCA",
      },
      {
        color: "#A36996",
      },
    ],
    link: "https://swatchbox.locofast.com/products/lavender",
    images: [image71, image72, image73],
  },
  {
    img: product15,
    fabric: "Cotton Shirting",
    price: "₹ 999.00",
    colors: [
      {
        color: "#B6B59C",
      },
      {
        color: "#C9BF9C",
      },
      {
        color: "#A8AAA3",
      },
      {
        color: "#BDC8D8",
      },
      {
        color: "#BABFCE",
      },
      {
        color: "#8D9FCA",
      },
    ],
    link: "https://swatchbox.locofast.com/products/cotton-shirting",
    images: [image74, image75, image76, image77, image78],
  },
  {
    img: product16,
    fabric: "Cotton Plaid Yarn Dyed",
    price: "₹ 999.00",
    colors: [
      {
        color: "#B4716B",
      },
      {
        color: "#A79259",
      },
      {
        color: "#003CA5",
      },
      {
        color: "#88816E",
      },
      {
        color: "#660902",
      },
      {
        color: "#847FA8",
      },
    ],
    link: "https://swatchbox.locofast.com/products/cotton-plaid-yarn-dyed-swatch-box",
    images: [image79, image80, image81, image82, image83],
  },
];
