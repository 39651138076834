import React, { useRef, useEffect } from "react";
import { Helmet } from "react-helmet";

import Footer from "../../components/Footer";
import FaqSection from "../../components/FAQSection";

const AboutUs = ({ heroRef }) => {
  const featureSectionRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleScroll = () => {
    featureSectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Helmet>
        <title>Locofast: Cloud Textile Factory | About</title>
        <meta
          name="description"
          content="Locofast is the leading marketplace for fabrics. We provide a wide range of cotton, rayon, and sustainable, eco-friendly fabrics at the best price.

"
        />
      </Helmet>
      <FaqSection handleScroll={handleScroll} />
      <Footer />
    </>
  );
};

export default AboutUs;
