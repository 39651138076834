import React, { useEffect, useRef, useState } from "react";
import arrowForward from "../../assets/images/hero/arrow_forward.svg";
import { customerArray, heroSliderArray } from "./heroSliderArray.js";
import "./style.scss";
import Modal from "../Modal";
import CategorySubmissionForm from "../CategorySubmissionForm";

const Hero = ({ navigateToForm, heroRef, handleScroll ,isCustomer}) => {
  const [sliderState, setSliderState] = useState(0);
  const [formModal, setFormModal] = useState(false);
  const [fillDetails, setFillDetails] = useState(false);
  const [formType, setFormType] = useState("");
  const [isSubmitSuccessful, setSubmitSuccess] = useState(false);
  const formRef = useRef();

  const showSlides = (slideIndex) => {
    let i;
    let slides = document.getElementsByClassName("mySlides");
    let dots = document.getElementsByClassName("herodot");
    let radioWrapper = document.getElementsByClassName("radioWrapper");
    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    slideIndex++;
    if (slideIndex > slides.length) {
      slideIndex = 1;
    }
    for (i = 0; i < dots.length; i++) {
      dots[i].checked = false
      dots[i].className = dots[i].className.replace(" active", "");
      radioWrapper[i].className = radioWrapper[i].className.replace(
        " activeRadioWrapper",
        ""
      );
    }
    slides[slideIndex - 1].style.display = "block";
    dots[slideIndex - 1].className += " active";
    radioWrapper[slideIndex - 1].className += " activeRadioWrapper";
  };

  useEffect(() => {
    showSlides(sliderState);
  }, [sliderState]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSliderState((prev) => {
        let slides = document.getElementsByClassName("mySlides");
        if (prev + 2 > slides.length) {
          return 0;
        }
        return prev + 1;
      });
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const openActionModal = (index) => {
    if (index == 0) {
      setFormType("customer");
      setFormModal(true);
      setSubmitSuccess(false);
      formRef?.current?.resetForm();
    }
    if (index == 1) {
      setFormType("customer");
      setFormModal(true);
      setFillDetails(true);
      setSubmitSuccess(false);
      formRef?.current?.resetForm();
    }
    if (index == 2) {
      setFormType("supplier");
      setFormModal(true);
      setFillDetails(true);
      setSubmitSuccess(false);
      formRef?.current?.resetForm();
    }
    if (index == 3) {
      setFormType("credit");
      setFormModal(true);
      setFillDetails(true);
      setSubmitSuccess(false);
      formRef?.current?.resetForm();
    }
    if (index == 4) {
      setFormType("logistic");
      setFormModal(true);
      setFillDetails(true);
      setSubmitSuccess(false);
      formRef?.current?.resetForm();
    }
  };
  const dataArray=isCustomer?customerArray:heroSliderArray
  return (
    <section className="hero" ref={heroRef}>
      {dataArray.map((slideData, index) => {
        return (
          <div className=" mySlides fade" key={index}>
            <div className="sliders_wrapper">
              <div className="hero_content_area hero_section1" key={index}>
                <h1 className="hero_heading">{slideData?.heading}</h1>
                <p className="hero_subHeading">{slideData?.subHeading}</p>
                <button
                  className="hero_actionButton"
                  onClick={() => openActionModal(index)}
                >
                  {slideData?.actionButtonText}
                  <img
                    className="hero_arrow"
                    src={arrowForward}
                    alt="icon"
                    loading="lazy"
                  />
                </button>
              </div>
              <div className="hero_content_area section2">
                <img src={slideData?.img} className="section2Img" alt="icon" />
              </div>
            </div>
          </div>
        );
      })}

      <div className={`hero_radioContainer  ${isCustomer? 'customerdisplay':''}`}>
        {heroSliderArray.map((slide, sliderIndex) => {
          return (
            <div
              className="radioWrapper"
              onClick={() => {
                setSliderState(sliderIndex);
              }}
              key={sliderIndex}
            >
              <input
                className="herodot"
                type="radio"
                name="hero_sliders"
                id={slide?.key}
              />
            </div>
          );
        })}
      </div>
      <Modal
        title={
          !isSubmitSuccessful
            ? fillDetails
              ? "Fill details"
              : "Share your requirement"
            : ""
        }
        show={formModal}
        _handleClose={() => {
          setFormModal(false);
          setFillDetails(false);
        }}
      >
        <CategorySubmissionForm
          closedText={"Close"}
          submitText={"Request a call back"}
          _handleClose={() => {
            setFormModal(false);
            setFillDetails(false);
          }}
          isSubmitSuccessful={isSubmitSuccessful}
          setSubmitSuccess={setSubmitSuccess}
          fillDetails={fillDetails}
          requirementFormClass={"herorequirementFormClass"}
          formType={formType}
          formRef={formRef}
        />
      </Modal>
    </section>
  );
};

export default Hero;
