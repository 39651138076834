import React from "react";
import { PILLARS_INFO } from "./config";
import "./style.scss";

const PillarsSection = () => {
  return (
    <div className="pillars">
      <div className="info">
        <div className="heading">Our Pillars of Sustainability</div>
        <div className="para">
          At Locofast, sustainability is intrinsic to our business. We pave the
          way for sustainable growth by actively engaging with all the
          stakeholders. Our comprehensive ESG framework comprises four core
          pillars which enable a sustainable and ethical approach in all of our
          operations.
        </div>
      </div>
      <div className="pillars__images">
        {PILLARS_INFO.map(({ img, heading, para }, id) => (
          <div className="column" key={id} id={`column${id}`}>
            <img src={img} alt="pillar" className="pillar" />
            <div className="text">{heading}</div>
            <div className="subtext">{para}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PillarsSection;
