import React, { useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import Hero from "../../components/Hero/Suppliers";
import QueryForm from "../../components/RequestModal/Form";
import Footer from "../../components/Footer";
import PartnerSection from "../../components/PartnerSection";
import GettingStarted from "../../components/GettingStartedSection";
import BannerSection from "../../components/BannerSection";
import Icon from "../../assets/images/banner/SupplierIcon.svg";
import "./style.scss";
import { PARTNER_SECTION2_CONTENT } from "../../components/PartnerSection/partnerarray";
import SupplierCarousel from "../../components/SupplierCarousel";
import LinkPage from "../LinkPage";

const SupplierPage = ({ heroRef }) => {
  const formRef = useRef(null);
  const featureSectionRef = useRef(null);

  const navigateToForm = () => {
    formRef.current.scrollIntoView(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleScroll = () => {
    featureSectionRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <>
      <Helmet>
        <title>Locofast: Cloud Textile Factory | Suppliers</title>
        <meta
          name="description"
          content="Locofast is the leading marketplace for fabrics. We provide a wide range of cotton, rayon, and sustainable, eco-friendly fabrics at the best price.

"
        />
      </Helmet>
      <Hero
        navigateToForm={navigateToForm}
        heroRef={heroRef}
        handleScroll={handleScroll}
      />
      <PartnerSection
        featureSectionRef={featureSectionRef}
        navigateToForm={navigateToForm}
        imgDetails={PARTNER_SECTION2_CONTENT}
        page={"supplier"}
      />
      <GettingStarted />
      <SupplierCarousel heading="Delighted Partners" />
      <BannerSection
        image={Icon}
        isImage={true}
        text1="With Locofast,"
        text2=" Grow your business fast."
        link="https://play.google.com/store/apps/details?id=in.locofast.fabricapp.twa"
      />
      <QueryForm
        formRef={formRef}
        isButtonShow={false}
        user="supplier"
        isDirection={false}
        isMember={true}
      />
      <Footer />
      <LinkPage />
    </>
  );
};

export default SupplierPage;
