import React, { useRef, useState } from "react";
import ImageAlignmentSection from "../ImageAlignmentSection";
import InfoContainer from "../InfoContainer";
import img1 from "../../assets/images/advantage/brand1.webp";
import img2 from "../../assets/images/advantage/brand2.webp";
import img3 from "../../assets/images/advantage/brand3.webp";
import "./style.scss";
import CategorySubmissionForm from "../CategorySubmissionForm";
import Modal from "../Modal";
const BrandsSection = () => {
  const [showModal, setShowModal] = useState(false);
  const [isSubmitSuccessful, setSubmitSuccess] = useState(false);
  const formRef = useRef();
  const pointerData = [
    "Assistance with design and product development",
    "New and unique collections based on upcoming trends",
    "Inspiration to always get ahead of the competition",
  ];
  return (
    <div className="brandSection">
      <ImageAlignmentSection img1={img1} img2={img2} img3={img3} />
      <InfoContainer
        title="Brands"
        subHeading="If you're a brand looking to work with an all in one platform providing"
        btnTxt="Connect with us now"
        onClick={() => {
          setShowModal(true);
          formRef?.current?.resetForm();
        }}
        pointerData={pointerData}
      />
      <Modal
        show={showModal}
        _handleClose={() => {
          setShowModal(false);
          setSubmitSuccess(false);
        }}
        title={!isSubmitSuccessful ? "Fill details" : ""}
      >
        <CategorySubmissionForm
          closedText={"Close"}
          submitText={"Request a call back"}
          _handleClose={() => {
            setShowModal(false);
            setSubmitSuccess(false);
          }}
          isSubmitSuccessful={isSubmitSuccessful}
          setSubmitSuccess={setSubmitSuccess}
          fillDetails={true}
          formType="brand"
          formRef={formRef}
        />
      </Modal>
    </div>
  );
};

export default BrandsSection;
