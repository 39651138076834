import React from "react";

import { ReactComponent as CareIcon } from "../../assets/images/advantage/icon-care.svg";
import { ReactComponent as ChecksIcon } from "../../assets/images/advantage/icon-checks.svg";
import { ReactComponent as DeliveryIcon } from "../../assets/images/advantage/icon-delivery.svg";
import { ReactComponent as VisibilityIcon } from "../../assets/images/advantage/icon-visibility.svg";

import CardsSection from "../CardsSection";

const ADVANTAGE_BENEFIT_CARD_CONTENT = {
  CARE: {
    icon: CareIcon,
    title: "100% ownership",
    description: "Dedicated, hands-on team that takes complete responsibility",
    id: 'care',
  },
  VISIBILITY: {
    icon: VisibilityIcon,
    title: "Order tracking",
    description:
      "Track your production from start to finish, anywhere, on the app",
      id: 'visibility',
  },
  CHECKS: {
    icon: ChecksIcon,
    title: "Quality checks",
    description: "Easy-to-understand quality reports with each shipment",
    id: 'check',
  },
  DELIVERY: {
    icon: DeliveryIcon,
    title: "End-to-end logistics",
    description: "Packing, shipping, delivery - we take care of it",
    id: 'delivery',
  },
};

const ADVANTAGE_SECTION = {
  title: "The Locofast Advantage",
  description: `Our team comprises professionals from top-tier institutes, with rich
    experience in the textile manufacturing industry. Locofast works closely
    with its customers to help them with their textile requirements and
    takes complete responsibility from order placement to strike-off
    approvals to quality checks and final delivery.`,
};

const AdvantageSection = ({ cardInfo = ADVANTAGE_SECTION }) => {
  return (
    <CardsSection
      cardInfo={cardInfo}
      cardDetails={ADVANTAGE_BENEFIT_CARD_CONTENT}
    />
  );
};

export default AdvantageSection;
