import React from "react";
import { DESIGN } from "./config";
import Slider from "react-slick";
import left from "../../assets/images/design/left-arrow.svg";
import right from "../../assets/images/design/right-arrow.svg";
import "./style.scss";

const DesignSection = () => {
  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <img src={right} alt="right" onClick={onClick} className={className} />
    );
  }

  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <img src={left} alt="left" onClick={onClick} className={className} />
    );
  }
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    autoplay: false,
    swipeToSlide: true,
    pauseOnHover: false,
    nextArrow: <SampleNextArrow className="rightArrow" />,
    prevArrow: <SamplePrevArrow className="leftArrow" />,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          useTransform: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          useTransform: false,
        },
      },
    ],
  };
  return (
    <div className="design">
      <div className="left-box"></div>
      <div className="right-box"></div>
      <Slider {...settings} className="slider">
        {DESIGN.map(({ img, link }) => (
          <img
            src={img}
            alt="icon"
            key={img}
            className="image"
            onClick={() => window.open(link)}
          />
        ))}
      </Slider>
      <div className="design__images">
        {DESIGN.map(({ img, link }) => (
          <img
            src={img}
            alt="icon"
            key={img}
            className="image"
            onClick={() => window.open(link)}
          />
        ))}
      </div>
    </div>
  );
};

export default DesignSection;
