import image1 from "../../assets/images/feature/9.svg";
import image2 from "../../assets/images/feature/10.svg";
import image3 from "../../assets/images/feature/11.svg";
import image4 from "../../assets/images/feature/12.svg";
import image5 from "../../assets/images/feature/13.svg";
import image6 from "../../assets/images/feature/14.webp";
import image7 from "../../assets/images/feature/15.webp";
import image8 from "../../assets/images/feature/16.svg";
import image9 from "../../assets/images/category/1.webp";
import image10 from "../../assets/images/category/2.webp";
import image11 from "../../assets/images/category/3.webp";
import image12 from "../../assets/images/category/4.webp";
import image13 from "../../assets/images/category/5.webp";
import image14 from "../../assets/images/category/6.webp";
import image15 from "../../assets/images/category/7.webp";
import image16 from "../../assets/images/category/8.webp";
import image17 from "../../assets/images/category/9.webp";
import image18 from "../../assets/images/category/10.webp";
import image19 from "../../assets/images/category/11.webp";
import image20 from "../../assets/images/category/12.webp";

export const COMPLIANCE_INFO = [
  { img: image1, text: "Sedex" },
  { img: image2, text: "International Organization for Standardization" },
  { img: image3, text: "Zero Discharge of Hazardous Chemicals" },
  { img: image4, text: "Green Seal" },
  { img: image5, text: "bluesign" },
  { img: image6, text: "Worldwide Responsible Accredited Production" },
  { img: image7, text: "Fairtrade" },
  { img: image8, text: "Social Accountability International" },
];
export const COLLABORATION_INFO = [
  { img: image9, hoverImg: image15 },
  { img: image10, hoverImg: image16 },
  { img: image11, hoverImg: image17 },
  { img: image12, hoverImg: image18 },
  { img: image13, hoverImg: image19 },
  { img: image14, hoverImg: image20 },
];
