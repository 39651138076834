import React, { useRef, useEffect } from "react";
import { Helmet } from "react-helmet";

import TextileFutureSection from "../../components/TextileFutureSection";
import WorkWithUs from "../../components/WorkWithUs";
import HeroSection from "../../components/Hero/AboutUs";
import Footer from "../../components/Footer";
import BannerSection from "../../components/BannerSection/About";
import PrideSection from "../../components/PrideSection";
import Certifications from "../../components/Certifications";
import { BLOG_ROUTE } from "../../constants";
const AboutUs = ({ heroRef }) => {
  const featureSectionRef = useRef(null);
  const handleScroll = () => {
    featureSectionRef.current.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Locofast: Cloud Textile Factory | About Us</title>
        <meta
          name="description"
          content="Locofast is the leading marketplace for fabrics. We provide a wide range of cotton, rayon, and sustainable, eco-friendly fabrics at the best price.

"
        />
      </Helmet>
      <HeroSection heroRef={heroRef} handleScroll={handleScroll} />
      <TextileFutureSection />
      <WorkWithUs />
      <PrideSection />
      <Certifications />
      <BannerSection
        text="Know latest updates from Locofast blog"
        link={BLOG_ROUTE}
        buttonText="Read blogs"
      />
      <Footer isUpper={true} />
    </>
  );
};

export default AboutUs;
