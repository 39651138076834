import React from "react";
import { useState, useRef } from "react";
import BenefitCard from "../BenefitCard";
import "../Carousel/style.scss";
import { SUPPLIERTESTIMONIALS } from "../Carousel/Testimonialsarray";
import leftarw from "../../assets/images/testimonial/leftarrow.svg";
import rightarw from "../../assets/images/testimonial/rightarrow.svg";
import seperator from "../../assets/images/testimonial/separator.svg";
import disable from "../../assets/images/testimonial/disable.svg";
import disabler from "../../assets/images/testimonial/disabler.svg";
import styled from "styled-components";
const DEFAULT_INDEX = 0;
const SlideButton = styled.button`
  color: #ffffff;

  padding: 10px;
  background-color: #f6f7f9;

  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  margin-top: 20px;

  text-decoration: none;
  float: right;

  &:active {
    position: relative;
    top: 1px;
  }
  &:focus {
    outline: 0;
  }
`;
const CarouselStep = ({
  index,
  activeIndex,
  img,
  customer,
  subtitle,
  description,
  onMouseEnter,
  starImg
}) => {
  return (
    <div
      className={`carouselStep ${
        index === activeIndex && "carouselStep--active"
      }`}
    >
      <BenefitCard
        icon={img}
        title={customer}
        subtitle={subtitle}
        description={description}
        starImg={starImg}
      />
    </div>
  );
};
const CarouselTypesWrapper = (props) => {
  return (
    <div
      className="testimonialWrapper__testimonialsWrapper carouselView"
      style={{width:"100%"}}
      ref={props.reference}
    >
      {Object.values(SUPPLIERTESTIMONIALS).map((step, index) => (
        <CarouselStep
          index={index}
          key={index}
          {...step}
          activeIndex={props.state}
        />
      ))}
    </div>
  );
};

const SupplierCarousel = (props) => {
  const [activeIndex, setActiveIndex] = useState(DEFAULT_INDEX);
  const ref = useRef(null);

  const scrollRight = (scrollOffset) => {
    if (activeIndex >= 2) {
      setActiveIndex(2);
    } else {
      ref.current.scrollLeft += scrollOffset;
      setActiveIndex(activeIndex + 1);
    }
  };
  const scrollLeft = (scrollOffset) => {
    if (activeIndex <= 0) {
      setActiveIndex(0);
    } else {
      ref.current.scrollLeft -= scrollOffset;
      setActiveIndex(activeIndex - 1);
    }
  };
  return (
    <div className="slidercarousel">
      <div className="temp">
        <h1 className="heading">{props.heading}</h1>
        <div className="arrw">
          <SlideButton float="right">
            <img
              src={activeIndex >= 2 ? disabler : rightarw}
              alt="icon"
              onClick={() => scrollRight(650)}
            />
          </SlideButton>

          <SlideButton float="left">
            <img
              src={activeIndex <= 0 ? disable : leftarw}
              alt="icon"
              onClick={() => scrollLeft(650)}
            />
            <img
              src={seperator}
              alt="separator"
              style={{ paddingLeft: "20px" }}
            />
          </SlideButton>
        </div>
      </div>
      <CarouselTypesWrapper state={activeIndex} reference={ref} />
    </div>
  );
};

export default SupplierCarousel;
