import React from "react";
import "./style.scss";
import factory from "../../assets/images/feature/factory.svg";
import { COMPLIANCE_INFO } from "./config";
import { Tooltip as ReactTooltip } from "react-tooltip";

const Compliance = () => {
  return (
    <div className="compliance">
      <div className="compliance__first">
        <div className="header">
          <img src={factory} alt="factory-img" className="image" />
          <div className="heading">Compliance</div>
        </div>
        <div className="header-info">
          We consistently put special attention to who we conduct business with
          and for. We take conscious steps while onboarding a supplier or
          customer in which they go through a stringent on-boarding procedure.
          We monitor compliance of the businesses in all areas, including
          Social/Labor compliance, OHS Compliance, Product Quality Compliance
          and Environment Compliance.
        </div>
      </div>
      <div className="compliance__second">
        {COMPLIANCE_INFO.map(({ img, text }, id) => (
          <div id={`my-element${id}`} data-tooltip-content={text} key={id}>
            <img src={img} alt="certificate" className="image" key={id} />
            <ReactTooltip anchorId={`my-element${id}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Compliance;
