import React from "react";
import "react-phone-number-input/style.css";
import Phone, {
  getCountryCallingCode,
  isValidPhoneNumber,
} from "react-phone-number-input";
import "react-phone-input-2/lib/style.css";
import FormError from "./FormError";
import { requestDemo } from "../../services/ApiService";
import { ReactComponent as TickIcon } from "../../assets/images/modal/tick.svg";

import "./style.scss";
class Form extends React.Component {
  state = {
    form: {
      name: null,
      email: this.props.email,
      contact: null,
      message: null,
      companyName: null,
      user: this.props.user || "customer",
      touched: {},
      errors: {
        name: null,
        email: null,
        contact: null,
        message: null,
      },
      isSubmitDisabled: true,
    },
    modalTitle: "Tell us about yourself",
    countryCode: "IN",
    countryCallingCode: getCountryCallingCode("IN"),
    isSuccess: false,
    userX: null,
  };

  _resetForm = () => {
    this.setState({
      isSuccess: false,
      modalTitle: "Tell us about yourself",
      form: {
        name: "",
        email: "",
        companyName:"",
        contact: null,
        user: "customer",
        message: "",
        touched: {},
        isSubmitDisabled: true,
        errors: {
          name: null,
          email: null,
          contact: null,
          message: null,
        },
      },
    });
  };

  _handleChange = (e, arg) => {
    e.preventDefault && e.preventDefault();

    const { name, value } = e.target;

    if (name === "contact") {
      return this._handleContactChange(arg);
    } else if (name === "name") {
      return this._handleNameChange(e);
    } else if (name === "email") {
      return this._handleEmailChange(e);
    } else if (name === "formType") {
      return this._handleUserChange(e);
    }

    this.setState(
      {
        ...this.state,
        form: {
          ...this.state.form,
          [name]: value,
          errors: {
            ...this.state.form.errors,
            [name]: null,
          },
        },
      },
      () => this._enableSubmit()
    );
  };
  _enableSubmit = () => {
    const { email, name, contact, user, errors, companyName } = this.state.form;
    const valid = !Object.values(errors).some((value) => value);

    if (
      email &&
      name &&
      contact &&
      user &&
      valid &&
      (user === "supplier" 
        ? companyName
        : true)
    ) {
      this.setState({
        ...this.state,
        form: {
          ...this.state.form,
          isSubmitDisabled: false,
        },
      });
    } else {
      this.setState({
        ...this.state,
        form: {
          ...this.state.form,
          isSubmitDisabled: true,
        },
      });
    }
  };
  _handleNameChange = (e) => {
    e.preventDefault();
    let name = e.target.value;
    if (name) {
      let isNameValid = this._validateName(name);
      if (isNameValid) {
        this.setState(
          {
            ...this.state,
            form: {
              ...this.state.form,
              name: name,
              errors: {
                ...this.state.form.errors,
                name: null,
              },
            },
          },
          () => this._enableSubmit()
        );
      } else {
        this.setState(
          {
            ...this.state,
            form: {
              ...this.state.form,
              name: name,
              errors: {
                ...this.state.form.errors,
                name: "Enter a valid Name",
              },
            },
          },
          () => this._enableSubmit()
        );
      }
    } else {
      this.setState(
        {
          form: {
            ...this.state.form,
            name: name,
            errors: {
              ...this.state.form.errors,
              name: "Name is required",
            },
          },
        },
        () => this._enableSubmit()
      );
    }
  };
  _handleEmailChange = (e) => {
    e.preventDefault();

    let email = e.target.value;
    if (email) {
      let isEmailValid = this._validateEmail(email);
      if (isEmailValid) {
        this.setState(
          {
            ...this.state,
            form: {
              ...this.state.form,
              email: email,
              errors: {
                ...this.state.form.errors,
                email: null,
              },
            },
          },
          () => this._enableSubmit()
        );
      } else {
        this.setState(
          {
            ...this.state,
            form: {
              ...this.state.form,
              email: email,
              errors: {
                ...this.state.form.errors,
                email: "Enter a valid company Email ID",
              },
            },
          },
          () => this._enableSubmit()
        );
      }
    } else {
      this.setState(
        {
          form: {
            ...this.state.form,
            email: email,
            errors: {
              ...this.state.form.errors,
              email: "Email ID is required",
            },
          },
        },
        () => this._enableSubmit()
      );
    }
  };

  _handleContactChange = (contact) => {
    if (contact) {
      let isContactValid = this._validateContact(contact);
      if (isContactValid) {
        this.setState(
          {
            ...this.state,
            form: {
              ...this.state.form,
              contact: contact,
              errors: {
                ...this.state.form.errors,
                contact: null,
              },
            },
          },
          () => this._enableSubmit()
        );
      } else {
        this.setState(
          {
            ...this.state,
            form: {
              ...this.state.form,
              contact: contact,
              errors: {
                ...this.state.form.errors,
                contact: "Enter a valid contact number",
              },
            },
          },
          () => this._enableSubmit()
        );
      }
    }
  };
  _handleUserChange = (user) => {
    if (!this.state.isSuccess) {
      this.setState(
        {
          ...this.state,
          form: {
            ...this.state.form,
            companyName: null,
            user: user,
            errors: {
              ...this.state.form.errors,
            },
          },
        },
        () => this._enableSubmit()
      );
    }
  };
  _handleCountryChange = (countryCode) => {
    try {
      const countryCallingCode = getCountryCallingCode(countryCode);

      this.setState({
        countryCode,
        countryCallingCode,
      });
    } catch (ex) {}
  };

  _validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    // Check basic format
    let isValidEmailId = re.test(email);

    // Check for forbidden domains
    const forbiddenDomains = ["gmail.com", "yahoo.com", "outlook.com"];
    const domain = email.split("@")[1]?.toLowerCase();

    if (isValidEmailId && domain && !forbiddenDomains.includes(domain)) {
      return true;
    } else {
      return false;
    }
  }
  _validateName(name) {
    const re = /^[a-zA-Z0-9 ]*$/;
    return re.test(name);
  }
  _validate = (showErrors = true) => {
    const { email, name, contact } = this.state.form;
    const errors = {};

    if (!name || name.trim().length === 0) {
      errors.name = "Name required";
    }
    if (!this._validateEmail(email)) {
      errors.email =
        "Please enter your work email ID. Personal email ID(e.g Gmail, Yahoo, Outlook) are not allowed.";
    }

    if (!this._validateContact(contact)) {
      errors.contact = "Enter a valid contact number";
    }

    if (errors.name || errors.email || errors.contact || errors.message) {
      this.setState({
        ...this.state,
        form: {
          ...this.state.form,
          errors: showErrors ? errors : null,
          isSubmitDisabled: true,
        },
      });
      return false;
    }

    return true;
  };

  _handleSubmit = (e) => {
    const { form } = this.state;
    e.preventDefault();
    const isValid = this._validate();

    if (isValid) {
      requestDemo({
        name: form.name,
        email: form.email,
        contact: form.contact,
        ...(form.companyName ? { companyName: form.companyName } : {}),
        ...(form.message ? { message: form.message } : null),
        formType: form.user,
      })
        .then(() => {
          this.setState({
            isSuccess: true,
          });
        })
        .catch((err) => {
          let msg =
            "Error occurred while submitting the request. Please try again.";
          alert(msg);
        });
    }
  };

  _handleOnBlur = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        touched: {
          ...this.state.form.touched,
          [e.target.name]: true,
        },
      },
    });
  };

  _validateContact(contact) {
    const isValid = isValidPhoneNumber(`${contact}`);
    return isValid;
  }

  render() {
    const { form: formData, isSuccess } = this.state;
    const { touched, errors, isSubmitDisabled } = formData;
    const { formRef, sustain } = this.props;
    let marginClass = "mb-20";
    return (
      <div
        className={
          this.props.isHomePage
            ? "contact-section homePagePadding"
            : "contact-section"
        }
        id="#contact-us"
        ref={formRef}
      >
        <div
          className={
            this.props.isDirection
              ? "contact-section__content"
              : "contact-section__content2"
          }
        >
          <div
            className={
              this.props.isHomePage
                ? "contact-section__left "
                : "contact-section__left leftSectionWidth"
            }
          >
            <div className="contact-section__heading section-heading">
              <p>
                {" "}
                {isSuccess
                  ? "Thank you for your interest in Locofast"
                  : "Let's talk"}{" "}
              </p>
            </div>
            <div className="contact-section__subheading text-content section-description">
              <p>
                {" "}
                {isSuccess
                  ? "One of our team members will get in touch with you shortly (usually within 48 hrs.)"
                  : !sustain
                  ? "Share your requirement and our fabric experts will connect with you within 24 hrs."
                  : "Get more of Locofast Collection of fabrics straight to your inbox"}
              </p>
            </div>
          </div>
          <form
            className={this.props.isHomePage ? "form" : "form formWidth"}
            onSubmit={this._handleSubmit}
            
          >
            {this.props.isButtonShow && (
              <div className="buttons">
                <div
                  onClick={() => this._handleUserChange("customer")}
                  className={
                    this.state.form.user === "customer"
                      ? "button buttonClick"
                      : "button"
                  }
                >
                  Customer
                </div>
                <div
                  onClick={() => this._handleUserChange("supplier")}
                  className={
                    this.state.form.user === "supplier"
                      ? "button buttonClick"
                      : "button"
                  }
                >
                  Supplier
                </div>
                <div
                  onClick={() => this._handleUserChange("others")}
                  className={
                    this.state.form.user === "others"
                      ? "button buttonClick"
                      : "button"
                  }
                >
                  Others
                </div>
              </div>
            )}
            <div className="formContent">
              <div>
                <div className={marginClass}>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name ? formData.name : ""}
                    placeholder="Name"
                    onBlur={this._handleOnBlur}
                    onChange={(e) => this._handleChange(e)}
                    disabled={isSuccess}
                  />
                  {touched.name && errors.name ? (
                    <FormError error={errors.name} errorClass="modal-error" />
                  ) : null}
                </div>
                <div className={marginClass}>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email ? formData.email : ""}
                    placeholder="Email ID"
                    onBlur={this._handleOnBlur}
                    onChange={this._handleChange}
                    disabled={isSuccess}
                  />
                  {touched.email && errors.email ? (
                    <FormError error={errors.email} errorClass="modal-error" />
                  ) : null}
                </div>
                <div className={`field-contact ${marginClass}`}>
                  <Phone
                    placeholder='Mobile number'
                    id="contact"
                    name="contact"
                    defaultCountry="IN"
                    value={formData.contact}
                    onCountryChange={this._handleCountryChange}
                    onBlur={this._handleOnBlur}
                    onChange={(phone) =>
                      this._handleChange({ target: { name: "contact" } }, phone)
                    }
                    international
                    disabled={isSuccess}
                  />
                  {touched.contact && errors.contact ? (
                    <FormError
                      error={errors.contact}
                      errorClass="modal-error"
                    />
                  ) : null}
                </div>
                {formData.user == "supplier" ? (
                  <div className={marginClass}>
                    <input
                      type="text"
                      id="companyName"
                      name="companyName"
                      value={formData.companyName ? formData.companyName : ""}
                      placeholder="Company Name"
                      onBlur={this._handleOnBlur}
                      onChange={(e) => this._handleChange(e)}
                      disabled={isSuccess}
                    />
                    {touched.companyName && errors.companyName ? (
                      <FormError
                        error={errors.companyName}
                        errorClass="modal-error"
                      />
                    ) : null}
                  </div>
                ) : null}
              </div>
              <div className="h-full">
                <div className="h-full">
                  <textarea
                    type="text"
                    id="message"
                    name="message"
                    value={formData.message ? formData.message : ""}
                    onChange={this._handleChange}
                    placeholder="Leave a message"
                    disabled={isSuccess}
                  />
                </div>
              </div>
            </div>

            <div className="flex-center">
              {isSuccess ? (
                <div className="submittedBanner">
                  <TickIcon />
                  <span>Submitted successfully </span>
                </div>
              ) : (
                <button
                  type="submit"
                  id="submitBtn"
                  className="btn btn-primary"
                  value="submit"
                  disabled={isSubmitDisabled}
                >
                  Request a call back
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default Form;
