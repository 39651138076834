import React from "react";

import Visual from "../../assets/images/adwords/mobileapp.webp";
import { ReactComponent as PlayIcon } from "../../assets/images/generic/play-store.svg";

const About = () => (
  <>
    <section className="aboutSection">
      <div className="left">
        <h3 className="section__heading title">What is Locofast?</h3>
        <p className="section__subheading para">
          Locofast is the fastest-growing B2B textile marketplace in India
          connecting Fabric manufacturers and suppliers to brands, export &
          buying houses. We leverage technology to enable smart, cost-effective
          and sustainable fabric sourcing solutions to businesses around the
          world.
        </p>
        <div className="download">
          <a
            href="https://play.google.com/store/apps/dev?id=4992841798607401290"
            target="_blank"
            rel="noopener noreferrer"
          >
            <PlayIcon />
          </a>
        </div>
      </div>
      <div className="content">
        <img src={Visual} alt="Locofast app screen" />
      </div>
    </section>
    <div className="servicesPartners">
      <div className="heading">Our services</div>
      <div className="servicesPartners__content">
        <div>
          Source Fabric from Leading fabric marketplace - Locofast. We cater to
          all your Fabric needs through our extensive list of suppliers based
          all over the nation specializing in Greige, RFD, Yarn dyed, Printed,
          and Dyed Fabric manufacturing. We deal in a comprehensive range of
          qualities among Cotton, Rayon / Viscose, Linen, Polyester, Blends,
          Denim, Lycra, Nylon varieties.
        </div>
        <br />
        <div>
          Order bulk fabric - cotton organic fabric, cotton fabric, cotton
          blends, cotton blend fabric, cotton double cloth, polycotton, cotton
          poplin, cambric fabric, printed cotton fabric, cotton lycra fabric,
          cotton linen fabric, linen cotton, cotton slub fabric, pure cotton
          fabric, cotton lace.
        </div>
        <br />
        <div>
          Rayon qualities - rayon fabric, viscose fabric, rayon cloth, rayon
          material, viscose rayon, rayon slub fabric, rayon printed fabric, poly
          viscose, viscose blend fabric, rayon lurex, rayon dobbies.
        </div>
        <br />
        <div>
          Linen qualities- linen cotton blend, colour linen, printed linen
          fabric, linen blend fabric, pure linen, linen prints, floral linen
          fabric, 100% linen, 125, 150, 135 gsm linen, dyed linen, viscose
          linen.
        </div>
        <br />
        Denim qualities - cotton denim, buy denim fabric online, cotton poly
        spandex fabric, 100% denim fabric, Jean material, pure denim, denim
        blends, 98 cotton 2 spandex denim fabric. Polyester blends fabric, poly
        blends, polyester cotton material.
      </div>
    </div>
  </>
);

export default About;
