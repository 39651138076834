import img1 from "../../assets/images/hero/homepage_slide1.webp";
import img2 from "../../assets/images/hero/homepage_slider2.webp";
import img3 from "../../assets/images/hero/homepage_slider3.webp";
import img4 from "../../assets/images/hero/homepage_slider4.webp";
import img5 from "../../assets/images/hero/homepage_slider5.webp";
import img6 from "../../assets/images/hero/homePageCustomer.webp";

export const heroSliderArray = [
  {
    key:1,
    img: img1,
    heading: "Largest choice of fabrics at best prices",
    subHeading:
      "Source fabrics directly from the large base of textile mills, thereby enjoying competitive price, faster delivery and wide variety on a single online platform.",
      actionButtonText:'Share your requirement'
  },
  {
    key:2,
    img: img2,
    heading: `Procure fabrics from India's fastest growing platform`,
    subHeading:
      "Our robust supply chain, flexible solutions and technology helps customers around the world get superior outcomes in the shortest time possible.",
      actionButtonText:'Register now' 
  },
  {
    key:3,
    img: img3,
    heading: `Let’s grow together. Fast.`,
    subHeading:
      "Become a part of our Pan-India network and grow your business at no additional cost.",
     actionButtonText:'Become a partner'
  },
  {
    key:4,
    img: img4,
    heading: "Locofast credit program",
    subHeading: `We're thrilled to present the Locofast Credit Program: 
    An exclusive opportunity designed to elevate your fabric sourcing experience.`,
    actionButtonText:'Apply for credit'
  },
  {
    key:5,
    img: img5,
    heading: "Affordable and reliable logistics",
    subHeading:
      "Experience our seamless, reliable supply chain solution tailored to your needs. Our commitment to affordability ensures unmatched value, making us the top choice for cost-effective logistics.",
      actionButtonText:'Ship now'

  },
];
export const customerArray=[
    {
      key:2,
      img: img6,
      heading: `Procure fabrics from India's fastest growing platform`,
      subHeading:
        "Our robust supply chain, flexible solutions and technology helps customers around the world get superior outcomes in the shortest time possible.",
        actionButtonText:'Register now' 
    },
]