import React from "react";
import "react-perfect-scrollbar/dist/css/styles.css";
import Scrollbar from "react-perfect-scrollbar";

const TermsAndConditions = () => (
  <div className="privacy__policy">
    <Scrollbar>
      <p>
        This Terms of Service (the “ToS” or “Agreement”) applies with respect to
        the usage of the services (the “Locofast Service”) offered by Locofast
        Online Services Pvt Ltd (the “Company”), a company incorporated under
        the laws of India, and having its registered office at E-43, Okhla Phase
        II, New Delhi – 110020, India.
      </p>
      <p>
        Your usage of the Locofast Service is subject to your explicit
        acceptance of these ToS and the attached Privacy Policy (the “PP”). Your
        acceptance of the same will irrevocably and unconditionally bind you to
        comply with and abide by all the obligations and conditions stipulated
        herein (unless otherwise explicitly made optional). After your initial
        confirmation/consent, You will be assumed to continue consenting to
        these ToS until you explicitly withdraw your consent by notifying the
        Company of the same in writing. These ToS supersede all previous oral
        and written terms and conditions (if any) and shall act as a binding
        Agreement between the Company and You.
      </p>
      <h5>
        IF YOU DO NOT AGREE TO BE BOUND BY ALL CONDITIONS/CLAUSES CAPTURED IN
        THESE TOS, PLEASE DO NOT USE THE PLATFORM OR SERVICES.
      </h5>
      <ul>
        <li>
          {" "}
          <p>Definitions</p>
          <ul>
            <li>
              “Platform” shall mean the website, dashboard, the Locofast apps,
              and any other platform which may allow you to access and/or use
              the Locofast Services via any other device/medium;
            </li>
            <li>
              &quot;Company,&quot; &quot;we,&quot; &quot;us,&quot; or
              &quot;our&quot; includes any other companies that are the
              Company’s subsidiaries and affiliates.
            </li>
            <li>
              “Locofast Content” means and includes all ideas, concepts,
              inventions, systems, platforms, software, interfaces, tools,
              utilities, templates, forms, techniques, methods, processes,
              algorithms, know-how, trade secrets, Documentation, Report
              Templates, Locofast Code, and other technologies and information
              acquired, created, developed or licensed independently by us,
              prior to or outside the scope of this ToS and any improvement,
              modification, extension or other derivative works thereof and all
              intellectual property rights thereto. Locofast Content excludes
              all Customer Data;
            </li>
            <li>
              “Customer Data” means all electronic data, information, or other
              content (including information pertaining to End-Users and the
              content of any Messages), submitted or provided by or on behalf of
              a Customer to be stored, collected or processed by us in
              connection with a Customer’s authorised use of the Locofast
              Services;
            </li>
            <li>
              “Documentation” means the implementation guides, help information
              and other user documentation regarding the Locofast Service that
              is provided or made available by us to a Customer in electronic or
              other form;
            </li>
            <li>
              “End User” means any user of the Customer’s platforms, goods,
              services, or applications;
            </li>
            <li>
              “Message” means any communication made by a Customer with an
              End-User via the Locofast Services, whether by email, SMS, push
              notification, web push notification, on-site notification, or
              in-app notifications;
            </li>
            <li>
              {" "}
              “Report Template” means the content, formatting, look and feel of
              the templates used for the reports, charts, graphs and other
              presentation in which the Customer Data (whether in the form of
              raw data or in an analysed format) are provided to the Customer
              (whether on a Platform or as exported data). It is hereby
              clarified that any and all data contained in any Report Templates
              provided by us shall fall within the purview of Customer Data, and
              Locofast shall have no title, rights, ownership or obligations
              with respect to the same;
            </li>
            <li>
              “Locofast Libraries” or “Locofast Code” shall mean the code
              developed, owned, maintained and provided by us, and to be
              installed on our Customers’ websites and mobile applications for
              the purposes of accessing and using the Locofast Services;
            </li>
            <li>
              “You” or “Customer” shall mean any customer of Locofast, or
              his/her representatives or affiliates who are permitted to use the
              Locofast Services;
            </li>
          </ul>
        </li>
        <li>
          <p>Updation of Terms</p>
          <ul>
            <li>
              These ToS may be updated, amended, modified or revised by us from
              time to time. Each such updation or modification to the ToS will
              be communicated to You via email, and You will be required to give
              your separate consent to each such revised version to be able to
              continue to use the Locofast Services. As a best practice, we
              encourage You to refer to these ToS from time to time to ensure
              that You are aware of any additions, revisions, amendments or
              modifications that we may have made.
            </li>
          </ul>
        </li>
        <li>
          <p>Service(s) provided to Customers</p>
          <ul>
            <li>
              The Company provides an array of features and functionalities to
              tis Customer, which then enable its Customers’ engagement with
              their End-users. These features include, inter alia:
              <ul>
                <li>End-User behaviour tracking and analytics;</li>
                <li>
                  Customised engagement with End-Users via multiple Messaging
                  channels;
                </li>
              </ul>
            </li>
            <li>
              The Company reserves the right, in its sole discretion, to add,
              change, suspend, or discontinue all or any part of the Locofast
              Services at any time by posting a notice on the Platform and by
              sending You an email. Your continued use of Locofast Services
              following the posting of any changes to the same (including the
              addition or removal of features) constitutes Your acceptance of
              those changes. Any information You provide to us for the purposes
              of registering and setting up your account (as specified below)
              shall be considered as accurate, complete and updated. You are
              solely responsible for the activity that occurs on Your account
              (including those of other authorised to access and operate your
              account) and for maintaining the security of your account and any
              information You input. We are not responsible for the accuracy or
              legitimacy of any information, data, or Customer Data uploaded,
              posted, or downloaded by you or any of your employees/agents on
              the Platform during your usage of the services. For the purposes
              of clarity, as long as your account is accessed with the correct
              username and password and unless you notify us of any unauthorised
              access, the Company is not and will not be responsible for
              verifying the nature of the individual/entity accessing your
              account, including if that person exports/downloads any Customer
              Data, and then shares the same with any third-party.
            </li>
          </ul>
        </li>
        <li>
          <p>Registration and Account Integrity</p>
          <ul>
            <li>
              As part of the registration process, you will need to accept our
              ToS and PP, and also provide us with your name (or the name of
              your organization/company) and your email ID. You will be required
              to create a password in order to secure and limit access to your
              account. We do not have access to and do not at any time request
              you to provide us with access to your password, unless you have
              given us explicit written permission to access your account
              pursuant to a request for assistance or support.
            </li>
            <li>
              It is Your responsibility to ensure that the information You
              provide is accurate, secure, and not misleading. You cannot create
              an account username and password using (i) the names and
              information of another person; or (ii) using words that are the
              trademarks or the property of another party (including ours); or
              (iii) words that are vulgar, obscene or in any other way
              inappropriate.
            </li>
          </ul>
        </li>
        <li>
          <p>Use of Your Information and Content</p>
          <ul>
            <li>
              All Customer Data that you create, transmit, transfer to us,
              submit, display or otherwise make available while using the
              Locofast Services, should only be information that You own or have
              the right to use or share (as may be applicable). If you transfer
              any End-User information or data to us (including any End-User
              PII), we will assume that you have informed the End-User and
              procured the necessary consent for such transfering, sharing,
              processing and/or using the information in the way envisaged under
              the Locofast Services (including for the purposes of sending
              Messages).
            </li>
            <li>
              We may use the above information for as long as your account is
              active and in use, and only as specified/permitted by our Privacy
              Policy and by applicable law. For example, we will never share
              personally identifiable information pertaining to You or your
              End-Users, without the relevant prior explicit disclosure and
              permission.
            </li>
            <li>
              While we make commercially reasonable efforts to ensure that the
              data stored on our servers is persistent and always available to
              our Customers, we will not be responsible in the event of failure
              of the third-party servers or any other factors outside our
              reasonable control, that may cause the Customer Data to be
              permanently deleted, irretrievable, or temporarily inaccessible.
            </li>
          </ul>
        </li>
        <li>
          <p>Third Party Services</p>
          <ul>
            <li>
              We use multiple third-party service providers in order to power
              and provide you with the Locofast Services. In this process, we
              share the Customer Data with some of the third-party service
              providers, as is necessary to enable them to provide the requisite
              services. We will attempt to ensure that all our third-party
              service providers are bound by and adhere to the same obligations
              of confidentiality and data privacy as we guarantee to You.
            </li>
            <li>
              Further, in the course of using the Locofast Services you may
              integrate and use certain third-party services along with the
              Locofast Services. You understand and agree that you have an
              independent engagement and legal relationship with such
              third-party service providers, and any action, inaction, breach,
              fault, delay, omission or other incident involving such
              third-parties shall be your sole liability and responsibility, and
              you shall not engage or involve the Company in such situation in
              any way.
            </li>
          </ul>
        </li>
        <li>
          <p>Rules and Conduct</p>
          <ul>
            <li>
              As a condition of use, You promise not to use the service for any
              purpose that is prohibited by the ToS, by law, or other rules or
              policies implemented by us from time to time. The Locofast Service
              and Locofast Data is provided only for Your own personal,
              non-commercial use.
            </li>
            <li>
              By way of example, and not as a limitation, You shall not (and
              shall not permit any third party to) take any action that: (i)
              would constitute a violation of any applicable law, rule or
              regulation; (ii) infringes on any intellectual property or other
              right of any other person or entity; (iii) is threatening,
              abusive, harassing, defamatory, libellous, deceptive, fraudulent,
              invasive of another’s privacy, tortuous, obscene, offensive, or
              profane; or (iv) impersonates any person or entity
            </li>
            <li>
              Furthermore, You shall not (directly or indirectly): (i) take any
              action that imposes or may impose an unreasonable or
              disproportionately large load on Company’s (or its third party
              providers’) infrastructure; (ii) interfere or attempt to interfere
              with the proper working of the services; (iii) bypass any measures
              we may use to prevent or restrict access to the Locofast Service
              (or parts thereof); (iv) decipher, decompile, disassemble, reverse
              engineer or otherwise attempt to derive any source code or
              underlying ideas or algorithms of any part of the Platform, except
              to the limited extent applicable laws specifically prohibit such
              restriction; (v) modify, translate, or otherwise create derivative
              works of any part of the Locofast Services or the Platform; or
              (vi) copy, rent, lease, distribute, or otherwise transfer any or
              all of the rights that You receive hereunder.
            </li>
          </ul>
        </li>
        <li>
          <p>Content Ownership and Copyright Conditions of Access</p>
          <ul>
            <li>
              Your copying, distributing, displaying, reproducing, modifying, or
              in any other way using of the Locofast Content in any manner not
              specified in these ToS, will be a violation of copyright and other
              intellectual property rights and Company reserves its rights under
              applicable law accordingly. We reserve all rights, title, and
              ownership in and to the Locofast Content.
            </li>
            <li>
              {" "}
              You will at all times remain the complete owner and retain all
              rights and title in and to the Customer Data. This includes
              assuming liability and responsibility in cases where the Customer
              Data breaches any conditions of confidentiality, any intellectual
              property rights, or any other third-party rights.
            </li>
          </ul>
        </li>
        <li>
          <p>Reviews and Feedback</p>
          <ul>
            <li>
              You agree that we may contact You through telephone, email, SMS,
              or any other electronic means of communication for the purpose of:
              <ul>
                <li>
                  Obtaining feedback in relation to Platform or Locofast; and/or
                </li>
                <li>
                  Resolving any complaints, information, or queries received
                  from you regarding the same;
                </li>
              </ul>
            </li>
            <li>
              and you agree to provide your fullest cooperation further to such
              communication by Company.
            </li>
          </ul>
        </li>
        <li>
          <p>Rights and Obligation relating to the Content</p>
          <ul>
            <li>
              You are prohibited from:
              <ul>
                <li>
                  violating or attempting to violate the integrity or security
                  of the Platform or any content thereof;
                </li>
                <li>
                  transmitting any information (including messages and
                  hyperlinks) on or through the Platform that is disruptive or
                  competitive to the provision of our services;
                </li>
                <li>
                  intentionally submitting on the Platform any incomplete, false
                  or inaccurate information;
                </li>
                <li>
                  using any engine, software, tool, agent or other device or
                  mechanism (such as spiders, robots etc.) to navigate or search
                  the Platform;
                </li>
                <li>
                  attempting to decipher, decompile, disassemble or reverse
                  engineer any part of the Platform;
                </li>
                <li>
                  copying or duplicating in any manner any of the Locofast
                  Content or other information available from the Platform;
                </li>
                <li>
                  circumventing or disabling any digital rights management,
                  usage rules, or other security features of the Platform.
                </li>
              </ul>
            </li>
            <li>
              The Company shall, upon obtaining knowledge by itself or been
              brought to actual knowledge in writing or through email about any
              breach of the above conditions, may temporarily suspend/delete
              your account, impose penalties, or take any other actions that the
              Company may deem fit. We shall also be entitled to preserve such
              information and associated records for at least 90 (ninety) days
              for production to governmental authorities for investigation
              purposes or as may be required by applicable law.
            </li>
            <li>
              In case of non-compliance with any applicable laws, rules or
              regulations, or this ToS/PP by a Customer, we shall have the right
              to immediately terminate Your access or usage rights to the
              Platform and to remove non-compliant information from the same.
            </li>
          </ul>
        </li>
        <li>
          <p>Support</p>
          <ul>
            <li>
              The Company offers email-based/online support and query-resolution
              tools. You may access support resources or contact our support by
              emailing at mail@locofast.com. The Company shall use commercially
              reasonable best efforts to respond to Your request for support, or
              to fix any problems You may be having, as is applicable. Any
              suggestions by the Company to You regarding use of Locofast /the
              Platform shall not be construed as a warranty.
            </li>
          </ul>
        </li>
        <li>
          <p>Termination</p>
          <ul>
            <li>
              The Company reserves the right to suspend or terminate Your access
              to the Platform and the services with or without notice and to
              exercise any other remedy available under law, in cases where,
              <ul>
                <li>
                  You are in breach of any terms and conditions of this ToS;
                </li>
                <li>
                  The Company is unable to verify or authenticate any
                  information provided to Company by You;
                </li>
                <li>
                  The Company has reasonable grounds for suspecting any illegal,
                  fraudulent or abusive activity on your part; or
                </li>
              </ul>
            </li>
            <li>
              Once temporarily suspended, indefinitely suspended or terminated,
              You may not continue to use the Platform under the same account, a
              different account or re-register under a new account. The Company
              shall destroy and/or delete all the data and other information
              related to your account within 14 days of the date of termination.
              All provisions of the ToS, which by their nature should survive
              termination, shall survive termination, including without
              limitation, warranty disclaimers, indemnity and limitations of
              liability.
            </li>
          </ul>
        </li>
        <li>
          <p>Disclaimer</p>
          <ul>
            <li>
              THE SERVICES (INCLUDING, WITHOUT LIMITATION, ANY CONTENT) ARE
              PROVIDED “AS IS” AND “AS AVAILABLE” AND ARE WITHOUT WARRANTY OF
              ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO, THE
              IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY AND
              FITNESS FOR A PARTICULAR PURPOSE, AND ANY WARRANTIES IMPLIED BY
              ANY COURSE OF PERFORMANCE OR USAGE OF TRADE, ALL OF WHICH ARE
              EXPRESSLY DISCLAIMED. THE COMPANY AND ITS DIRECTORS, EMPLOYEES,
              AGENTS, SUPPLIERS, SPONSORS AND PARTNERS DO NOT WARRANT THAT: (A)
              THE LOCOFAST SERVICES AND THE PLATFORM WILL BE SECURE OR AVAILABLE
              AT ANY PARTICULAR TIME OR LOCATION; (B) ANY DEFECTS OR ERRORS WILL
              BE CORRECTED; (C) ANY CONTENT OR SOFTWARE AVAILABLE AT OR THROUGH
              THE PLATFORM IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS; OR
              (D) THE RESULTS OF USING THE PLATFORM WILL MEET YOUR REQUIREMENTS.
              YOUR USE OF THE PLATFORM AND THE SERVICES IS SOLELY AT YOUR OWN
              RISK.
            </li>
          </ul>
        </li>
        <li>
          <p>Indemnification</p>
          <ul>
            <li>
              You shall defend, indemnify, and hold harmless the Company, its
              affiliates/subsidiaries/joint venture partners and each of its,
              and its affiliates’/subsidiaries/JV partners’ employees,
              contractors, directors, suppliers and representatives from all
              liabilities, losses, claims, and expenses, including reasonable
              attorneys’ fees, that arise from or relate to (i) Your use or
              misuse of, or access to, the Platform and Locofast; or (ii) Your
              violation of the Terms of Use or any applicable law, contract,
              policy, regulation or other obligation. We reserve the right to
              assume the exclusive defence and control of any matter otherwise
              subject to indemnification by You, in which event You will assist
              and cooperate with us in connection therewith.
            </li>
          </ul>
        </li>
        <li>
          <p>Limitation of Liability</p>
          <ul>
            <li>
              TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT SHALL THE
              COMPANY (NOR ITS DIRECTORS, EMPLOYEES, AGENTS, SPONSORS, PARTNERS,
              SUPPLIERS, CONTENT PROVIDERS, LICENSORS OR RESELLERS,) BE LIABLE
              UNDER CONTRACT, TORT, STRICT LIABILITY, NEGLIGENCE OR ANY OTHER
              LEGAL OR EQUITABLE THEORY WITH RESPECT TO THE SERVICE (I) FOR ANY
              LOST PROFITS, DATA LOSS, LOSS OF GOODWILL OR OPPORTUNITY, OR
              SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES
              OF ANY KIND WHATSOEVER; OR (II) FOR YOUR RELIANCE ON THE SERVICE;
              OR (III) FOR ANY DIRECT DAMAGES IN EXCESS (IN THE AGGREGATE) OF
              THE INR [●]/-; OR (IV) FOR ANY MATTER BEYOND ITS OR THEIR
              REASONABLE CONTROL, EVEN IF COMPANY HAS BEEN ADVISED OF THE
              POSSIBILITY OF ANY OF THE AFOREMENTIONED DAMAGES.
            </li>
          </ul>
        </li>
        <li>
          <p>Governing Law</p>
          <ul>
            <li>
              This Agreement shall be governed by and construed in accordance
              with the laws of India without regard to the conflict of laws
              provisions thereof. All claims, differences and disputes arising
              under or in connection with or in relation hereto the Platform,
              the ToS or any transactions entered into on or through the
              Platform shall be subject to the exclusive jurisdiction of the
              courts at New Delhi, India and You hereby accede to and accept the
              jurisdiction of such courts.
            </li>
          </ul>
        </li>
        <li>
          <p>Miscellaneous</p>
          <ul>
            <li>
              The ToS are the entire agreement between You and the Company with
              respect to Locofast, and supersede all prior or contemporaneous
              communications and proposals (whether oral, written or electronic)
              between You and Company with respect to the same. If any provision
              of the ToS is found to be unenforceable or invalid, that provision
              will be limited or eliminated to the minimum extent necessary so
              that the ToS will otherwise remain in full force and effect and
              enforceable. The failure of either party to exercise in any
              respect any right provided for herein shall not be deemed a waiver
              of any further rights hereunder. The ToS are personal to You, and
              are not assignable or transferable by You except with Company’s
              prior written consent. Company may assign, transfer or delegate
              any of its rights and obligations hereunder without consent. No
              agency, partnership, joint venture, or employment relationship is
              created as a result of the ToS and neither party has any authority
              of any kind to bind the other in any respect. All notices under
              the ToS will be in writing and will be deemed to have been duly
              given when received, if personally delivered or sent by certified
              or registered mail, return receipt requested; when receipt is
              electronically confirmed, if transmitted by facsimile or e-mail;
              or two days after it is sent, if sent for next day delivery by
              recognized overnight delivery service.
            </li>
          </ul>
        </li>
        <li>
          <p>Contact</p>
          <ul>
            <li>
              If You have any questions regarding the Platform or the Locofast
              Services, please contact us at mail@locofast.com.{" "}
            </li>
          </ul>
        </li>
      </ul>
    </Scrollbar>
  </div>
);

export default TermsAndConditions;
