export const materialData = {
  fabricCategory: [
    {
      value: 1,
      label: "Cotton",
    },
    {
      value: 2,
      label: "Rayon",
    },
    {
      value: 3,
      label: "Polyester",
    },
    {
      value: 4,
      label: "Blends",
    },
    {
      value: 56,
      label: "Nylon",
    },
    {
      value: 57,
      label: "Sustainable",
    },
    {
      value: 58,
      label: "Linen",
    },
    {
      value: 9999, // random 999 series for denim
      label: "Denim",
    },
    {
      value:4567,
      label:"Others"
    }
  ],
  fabricSubCategory: {
    1: [
      {
        value: 11,
        label: "Cambric",
        categoryId: 1,
        fabric_type: null,
      },
      {
        value: 12,
        label: "Voile",
        categoryId: 1,
        fabric_type: null,
      },
      {
        value: 10,
        label: "Poplin",
        categoryId: 1,
        fabric_type: null,
      },
      {
        value: 19,
        label: "Double cloth",
        categoryId: 1,
        fabric_type: null,
      },
      {
        value: 16,
        label: "Sheeting",
        categoryId: 1,
        fabric_type: null,
      },
      {
        value: 14,
        label: "Drill",
        categoryId: 1,
        fabric_type: null,
      },
      {
        value: 15,
        label: "Duck",
        categoryId: 1,
        fabric_type: null,
      },
      {
        value: 7,
        label: "Dobbies",
        categoryId: 1,
        fabric_type: null,
      },
      {
        value: 13,
        label: "Twill",
        categoryId: 1,
        fabric_type: null,
      },
      {
        value: 49,
        label: "Slub",
        categoryId: 1,
        fabric_type: null,
      },
      {
        value: 9,
        label: "Crepe",
        categoryId: 1,
        fabric_type: null,
      },
      {
        value: 8,
        label: "Jacquard",
        categoryId: 1,
        fabric_type: null,
      },
      {
        value: 18,
        label: "Gauze",
        categoryId: 1,
        fabric_type: null,
      },
      {
        value: 20,
        label: "Lurex",
        categoryId: 1,
        fabric_type: null,
      },
      {
        value: 51,
        label: "Velvet",
        categoryId: 1,
        fabric_type: null,
      },
      {
        value: 52,
        label: "Flannel",
        categoryId: 1,
        fabric_type: null,
      },
      {
        value: 17,
        label: "Satin",
        categoryId: 1,
        fabric_type: null,
      },
      {
        value: 50,
        label: "Canvas",
        categoryId: 1,
        fabric_type: null,
      },
      {
        value: 53,
        label: "Plain",
        categoryId: 1,
        fabric_type: null,
      },
      {
        value: 111,
        label: "Single Jersey",
        categoryId: 1,
        fabric_type: null,
      },
      {
        value: 112,
        label: "Pique",
        categoryId: 1,
        fabric_type: null,
      },
      {
        value: 113,
        label: "Terry",
        categoryId: 1,
        fabric_type: null,
      },
      {
        value: 114,
        label: "Fleece",
        categoryId: 1,
        fabric_type: null,
      },
      {
        value: 115,
        label: "Interlock",
        categoryId: 1,
        fabric_type: null,
      },
      {
        value: 134,
        label: "Flex",
        categoryId: 1,
        fabric_type: null,
      },
    ],
    2: [
      {
        value: 23,
        label: "Plain",
        categoryId: 2,
        fabric_type: null,
      },
      {
        value: 22,
        label: "Crepe",
        categoryId: 2,
        fabric_type: null,
      },
      {
        value: 54,
        label: "Slub",
        categoryId: 2,
        fabric_type: null,
      },
      {
        value: 27,
        label: "Dobbies",
        categoryId: 2,
        fabric_type: null,
      },
      {
        value: 26,
        label: "Twill",
        categoryId: 2,
        fabric_type: null,
      },
      {
        value: 32,
        label: "Modal",
        categoryId: 2,
        fabric_type: null,
      },
      {
        value: 29,
        label: "Lurex",
        categoryId: 2,
        fabric_type: null,
      },
      {
        value: 30,
        label: "Ecovera",
        categoryId: 2,
        fabric_type: null,
      },
      {
        value: 34,
        label: "Viscose Georgette",
        categoryId: 2,
        fabric_type: null,
      },
      {
        value: 36,
        label: "Viscose Satin",
        categoryId: 2,
        fabric_type: null,
      },
      {
        value: 39,
        label: "Viscose Chiffon",
        categoryId: 2,
        fabric_type: null,
      },
      {
        value: 116,
        label: "Santoon",
        categoryId: 2,
        fabric_type: null,
      },
      {
        value: 24,
        label: "Moss",
        categoryId: 2,
        fabric_type: null,
      },
      {
        value: 25,
        label: "Drill",
        categoryId: 2,
        fabric_type: null,
      },
      {
        value: 28,
        label: "Jacquard",
        categoryId: 2,
        fabric_type: null,
      },
      {
        value: 31,
        label: "Ecoliva",
        categoryId: 2,
        fabric_type: null,
      },
      {
        value: 33,
        label: "Viscose Moss",
        categoryId: 2,
        fabric_type: null,
      },
      {
        value: 35,
        label: "Viscose Crepe",
        categoryId: 2,
        fabric_type: null,
      },
      {
        value: 37,
        label: "Viscose Dull Crepe",
        categoryId: 2,
        fabric_type: null,
      },
      {
        value: 38,
        label: "Viscose Moroccan Crepe",
        categoryId: 2,
        fabric_type: null,
      },
      {
        value: 117,
        label: "Pique",
        categoryId: 2,
        fabric_type: null,
      },
      {
        value: 118,
        label: "Terry",
        categoryId: 2,
        fabric_type: null,
      },
      {
        value: 119,
        label: "Fleece",
        categoryId: 2,
        fabric_type: null,
      },
      {
        value: 120,
        label: "Interlock",
        categoryId: 2,
        fabric_type: null,
      },
      {
        value: 121,
        label: "Single Jersey",
        categoryId: 2,
        fabric_type: null,
      },
      {
        value: 135,
        label: "Flex",
        categoryId: 2,
        fabric_type: null,
      },
    ],
    3: [
      {
        value: 59,
        label: "Crepe",
        categoryId: 3,
        fabric_type: 2,
      },
      {
        value: 61,
        label: "Georgette",
        categoryId: 3,
        fabric_type: 2,
      },
      {
        value: 62,
        label: "Chiffon",
        categoryId: 3,
        fabric_type: 2,
      },
      {
        value: 63,
        label: "Satin",
        categoryId: 3,
        fabric_type: 2,
      },
      {
        value: 65,
        label: "Twill",
        categoryId: 3,
        fabric_type: 2,
      },
      {
        value: 66,
        label: "Tafetta",
        categoryId: 3,
        fabric_type: 2,
      },
      {
        value: 68,
        label: "Jacquard",
        categoryId: 3,
        fabric_type: 1,
      },
      {
        value: 70,
        label: "Velvet",
        categoryId: 3,
        fabric_type: 2,
      },
      {
        value: 71,
        label: "Organza",
        categoryId: 3,
        fabric_type: 2,
      },
      {
        value: 76,
        label: "Fleece",
        categoryId: 3,
        fabric_type: 1,
      },
      {
        value: 124,
        label: "Interlock",
        categoryId: 3,
        fabric_type: 1,
      },
      {
        value: 132,
        label: "Single Jersey",
        categoryId: 3,
        fabric_type: 1,
      },
      {
        value: 145,
        label: "Dyed",
        categoryId: 3,
        fabric_type: 1,
      },
      {
        value: 146,
        label: "Melange",
        categoryId: 3,
        fabric_type: 1,
      },
      {
        value: 147,
        label: "Print",
        categoryId: 3,
        fabric_type: 1,
      },
      {
        value: 148,
        label: "Rib",
        categoryId: 3,
        fabric_type: 1,
      },
      {
        value: 149,
        label: "Sherpa",
        categoryId: 3,
        fabric_type: 1,
      },
      {
        value: 150,
        label: "Pique",
        categoryId: 3,
        fabric_type: 1,
      },
      {
        value: 151,
        label: "Mesh",
        categoryId: 3,
        fabric_type: 1,
      },
      {
        value: 152,
        label: "Honeycomb",
        categoryId: 3,
        fabric_type: 1,
      },
      {
        value: 153,
        label: "Waffle",
        categoryId: 3,
        fabric_type: 1,
      },
      {
        value: 154,
        label: "Raschel Knit",
        categoryId: 3,
        fabric_type: 1,
      },
      {
        value: 155,
        label: "Net",
        categoryId: 3,
        fabric_type: 1,
      },
      {
        value: 156,
        label: "Plain",
        categoryId: 3,
        fabric_type: 2,
      },
      {
        value: 157,
        label: "Corduroy",
        categoryId: 3,
        fabric_type: 2,
      },
      {
        value: 158,
        label: "Shantoon",
        categoryId: 3,
        fabric_type: 2,
      },
      {
        value: 159,
        label: "Voil",
        categoryId: 3,
        fabric_type: 2,
      },
    ],
    4: [
      {
        value: 78,
        label: "Cotton",
        categoryId: 4,
        fabric_type: null,
      },
      {
        value: 80,
        label: "Linen",
        categoryId: 4,
        fabric_type: null,
      },
      {
        value: 81,
        label: "Lycra",
        categoryId: 4,
        fabric_type: null,
      },
      {
        value: 82,
        label: "Viscose",
        categoryId: 4,
        fabric_type: null,
      },
      {
        value: 83,
        label: "Spandex",
        categoryId: 4,
        fabric_type: null,
      },
      {
        value: 84,
        label: "Wool",
        categoryId: 4,
        fabric_type: null,
      },
      {
        value: 85,
        label: "Bamboo",
        categoryId: 4,
        fabric_type: null,
      },
      {
        value: 86,
        label: "Flex",
        categoryId: 4,
        fabric_type: null,
      },
      {
        value: 87,
        label: "Sushi Voile",
        categoryId: 4,
        fabric_type: null,
      },
      {
        value: 128,
        label: "Modal",
        categoryId: 4,
        fabric_type: null,
      },
      {
        value: 160,
        label: "Polyester",
        categoryId: 4,
        fabric_type: null,
      },
    ],
    55: [
      {
        value: 88,
        label: "Pure Cotton",
        categoryId: 55,
        fabric_type: null,
      },
      {
        value: 89,
        label: "Cotton Sapndex",
        categoryId: 55,
        fabric_type: null,
      },
      {
        value: 90,
        label: "Poly Cotton",
        categoryId: 55,
        fabric_type: null,
      },
      {
        value: 91,
        label: "Poly Cotton Spandex",
        categoryId: 55,
        fabric_type: null,
      },
      {
        value: 129,
        label: "100% Tencel",
        categoryId: 55,
        fabric_type: null,
      },
      {
        value: 130,
        label: "Tencel Cotton",
        categoryId: 55,
        fabric_type: null,
      },
    ],
    56: [
      {
        value: 92,
        label: "Satin",
        categoryId: 56,
        fabric_type: null,
      },
      {
        value: 93,
        label: "Dupion",
        categoryId: 56,
        fabric_type: null,
      },
      {
        value: 94,
        label: "Crepe",
        categoryId: 56,
        fabric_type: null,
      },
      {
        value: 95,
        label: "Tissue",
        categoryId: 56,
        fabric_type: null,
      },
      {
        value: 96,
        label: "Tuelle",
        categoryId: 56,
        fabric_type: null,
      },
      {
        value: 131,
        label: "Chiffon",
        categoryId: 56,
        fabric_type: null,
      },
    ],
    57: [
      {
        value: 97,
        label: "Micro Modal",
        categoryId: 57,
        fabric_type: null,
      },
      {
        value: 98,
        label: "Lyocell",
        categoryId: 57,
        fabric_type: null,
      },
      {
        value: 99,
        label: "Tencel",
        categoryId: 57,
        fabric_type: null,
      },
      {
        value: 100,
        label: "Bamboo",
        categoryId: 57,
        fabric_type: null,
      },
      {
        value: 101,
        label: "Bamboo Rayon",
        categoryId: 57,
        fabric_type: null,
      },
      {
        value: 102,
        label: "Soyabean",
        categoryId: 57,
        fabric_type: null,
      },
      {
        value: 103,
        label: "Hemp",
        categoryId: 57,
        fabric_type: null,
      },
      {
        value: 104,
        label: "Ramie",
        categoryId: 57,
        fabric_type: null,
      },
      {
        value: 105,
        label: "Bemberg Cupro",
        categoryId: 57,
        fabric_type: null,
      },
    ],
    58: [
      {
        value: 106,
        label: "100% Linen",
        categoryId: 58,
        fabric_type: null,
      },
      {
        value: 107,
        label: "Cotton Linen",
        categoryId: 58,
        fabric_type: null,
      },
      {
        value: 108,
        label: "Viscose Linen",
        categoryId: 58,
        fabric_type: null,
      },
      {
        value: 109,
        label: "Cotton Flax ",
        categoryId: 58,
        fabric_type: null,
      },
      {
        value: 110,
        label: "Viscose Flax",
        categoryId: 58,
        fabric_type: null,
      },
    ],
    137: [
      {
        value: 138,
        label: "Cotton",
        categoryId: 137,
        fabric_type: null,
      },
      {
        value: 139,
        label: "Spandex",
        categoryId: 137,
        fabric_type: null,
      },
      {
        value: 140,
        label: "Lycra",
        categoryId: 137,
        fabric_type: null,
      },
      {
        value: 141,
        label: "Polyester",
        categoryId: 137,
        fabric_type: null,
      },
      {
        value: 142,
        label: "Hemp",
        categoryId: 137,
        fabric_type: null,
      },
      {
        value: 143,
        label: "Silk",
        categoryId: 137,
        fabric_type: null,
      },
      {
        value: 144,
        label: "Linen",
        categoryId: 137,
        fabric_type: null,
      },
    ],
    9999: [
      {
        value: 9991,
        label: "Indigo",
      },
      {
        value: 9992,
        label: "Blue",
      },
      {
        value: 9993,
        label: "Black",
      },
      {
        value: 9994,
        label: "IBST",
      },
      {
        value: 9995,
        label: "Ecru",
      },
    ],
  },
  certification: [
    {
      value: 1,
      label: "BCI",
    },
    {
      value: 3,
      label: "Organic",
    },
    {
      value: 4,
      label: "Oeko-Tex",
    },
    {
      value: 5,
      label: "RCI",
    },
    {
      value: 6,
      label: "OCS",
    },
    {
      value: 7,
      label: "GOTS",
    },
    {
      value: 8,
      label: "Organic 100",
    },
    {
      value: 9,
      label: "GRS",
    },
    {
      value: 10,
      label: "Supima Cotton",
    },
    {
      value: 11,
      label: "Egyptian Cotton",
    },
    {
      value: 12,
      label: "bioRe Sustainable Cotton",
    },
    {
      value: 13,
      label: "FSC",
    },
    {
      value: 14,
      label: "Liva",
    },
    {
      value: 15,
      label: "Eco Liva",
    },
    {
      value: 16,
      label: "EcoVero",
    },
    {
      value: 17,
      label: "RCS",
    },
  ],
  currency: {
    1: [
      {
        id: 1,
        code: "INR",
        symbol: "₹",
      },
    ],
    2: [
      {
        id: 2,
        code: "USD",
        symbol: "$",
      },
    ],
  },
  unitByType: {
    1: [
      {
        id: 1,
        name: "kilograms",
        shortName: "kg",
        fabricType: 1,
      },
      {
        id: 9,
        name: "pounds",
        shortName: "lb",
        fabricType: 1,
      },
    ],
    2: [
      {
        id: 2,
        name: "meters",
        shortName: "meter",
        fabricType: 2,
      },
      {
        id: 8,
        name: "yards",
        shortName: "yd",
        fabricType: 2,
      },
    ],
  },
  denim: {
    fabricCategory: [
      {
        value: 136,
        label: "100% Cotton",
      },
      {
        value: 137,
        label: "Blends",
      },
    ],
    fabricSubCategory: {
      1: [
        {
          value: 11,
          label: "Cambric",
          categoryId: 1,
          fabric_type: null,
        },
        {
          value: 12,
          label: "Voile",
          categoryId: 1,
          fabric_type: null,
        },
        {
          value: 10,
          label: "Poplin",
          categoryId: 1,
          fabric_type: null,
        },
        {
          value: 19,
          label: "Double cloth",
          categoryId: 1,
          fabric_type: null,
        },
        {
          value: 16,
          label: "Sheeting",
          categoryId: 1,
          fabric_type: null,
        },
        {
          value: 14,
          label: "Drill",
          categoryId: 1,
          fabric_type: null,
        },
        {
          value: 15,
          label: "Duck",
          categoryId: 1,
          fabric_type: null,
        },
        {
          value: 7,
          label: "Dobbies",
          categoryId: 1,
          fabric_type: null,
        },
        {
          value: 13,
          label: "Twill",
          categoryId: 1,
          fabric_type: null,
        },
        {
          value: 49,
          label: "Slub",
          categoryId: 1,
          fabric_type: null,
        },
        {
          value: 9,
          label: "Crepe",
          categoryId: 1,
          fabric_type: null,
        },
        {
          value: 8,
          label: "Jacquard",
          categoryId: 1,
          fabric_type: null,
        },
        {
          value: 18,
          label: "Gauze",
          categoryId: 1,
          fabric_type: null,
        },
        {
          value: 20,
          label: "Lurex",
          categoryId: 1,
          fabric_type: null,
        },
        {
          value: 51,
          label: "Velvet",
          categoryId: 1,
          fabric_type: null,
        },
        {
          value: 52,
          label: "Flannel",
          categoryId: 1,
          fabric_type: null,
        },
        {
          value: 17,
          label: "Satin",
          categoryId: 1,
          fabric_type: null,
        },
        {
          value: 50,
          label: "Canvas",
          categoryId: 1,
          fabric_type: null,
        },
        {
          value: 53,
          label: "Plain",
          categoryId: 1,
          fabric_type: null,
        },
        {
          value: 111,
          label: "Single Jersey",
          categoryId: 1,
          fabric_type: null,
        },
        {
          value: 112,
          label: "Pique",
          categoryId: 1,
          fabric_type: null,
        },
        {
          value: 113,
          label: "Terry",
          categoryId: 1,
          fabric_type: null,
        },
        {
          value: 114,
          label: "Fleece",
          categoryId: 1,
          fabric_type: null,
        },
        {
          value: 115,
          label: "Interlock",
          categoryId: 1,
          fabric_type: null,
        },
        {
          value: 134,
          label: "Flex",
          categoryId: 1,
          fabric_type: null,
        },
      ],
      2: [
        {
          value: 23,
          label: "Plain",
          categoryId: 2,
          fabric_type: null,
        },
        {
          value: 22,
          label: "Crepe",
          categoryId: 2,
          fabric_type: null,
        },
        {
          value: 54,
          label: "Slub",
          categoryId: 2,
          fabric_type: null,
        },
        {
          value: 27,
          label: "Dobbies",
          categoryId: 2,
          fabric_type: null,
        },
        {
          value: 26,
          label: "Twill",
          categoryId: 2,
          fabric_type: null,
        },
        {
          value: 32,
          label: "Modal",
          categoryId: 2,
          fabric_type: null,
        },
        {
          value: 29,
          label: "Lurex",
          categoryId: 2,
          fabric_type: null,
        },
        {
          value: 30,
          label: "Ecovera",
          categoryId: 2,
          fabric_type: null,
        },
        {
          value: 34,
          label: "Viscose Georgette",
          categoryId: 2,
          fabric_type: null,
        },
        {
          value: 36,
          label: "Viscose Satin",
          categoryId: 2,
          fabric_type: null,
        },
        {
          value: 39,
          label: "Viscose Chiffon",
          categoryId: 2,
          fabric_type: null,
        },
        {
          value: 116,
          label: "Santoon",
          categoryId: 2,
          fabric_type: null,
        },
        {
          value: 24,
          label: "Moss",
          categoryId: 2,
          fabric_type: null,
        },
        {
          value: 25,
          label: "Drill",
          categoryId: 2,
          fabric_type: null,
        },
        {
          value: 28,
          label: "Jacquard",
          categoryId: 2,
          fabric_type: null,
        },
        {
          value: 31,
          label: "Ecoliva",
          categoryId: 2,
          fabric_type: null,
        },
        {
          value: 33,
          label: "Viscose Moss",
          categoryId: 2,
          fabric_type: null,
        },
        {
          value: 35,
          label: "Viscose Crepe",
          categoryId: 2,
          fabric_type: null,
        },
        {
          value: 37,
          label: "Viscose Dull Crepe",
          categoryId: 2,
          fabric_type: null,
        },
        {
          value: 38,
          label: "Viscose Moroccan Crepe",
          categoryId: 2,
          fabric_type: null,
        },
        {
          value: 117,
          label: "Pique",
          categoryId: 2,
          fabric_type: null,
        },
        {
          value: 118,
          label: "Terry",
          categoryId: 2,
          fabric_type: null,
        },
        {
          value: 119,
          label: "Fleece",
          categoryId: 2,
          fabric_type: null,
        },
        {
          value: 120,
          label: "Interlock",
          categoryId: 2,
          fabric_type: null,
        },
        {
          value: 121,
          label: "Single Jersey",
          categoryId: 2,
          fabric_type: null,
        },
        {
          value: 135,
          label: "Flex",
          categoryId: 2,
          fabric_type: null,
        },
      ],
      3: [
        {
          value: 59,
          label: "Crepe",
          categoryId: 3,
          fabric_type: 2,
        },
        {
          value: 61,
          label: "Georgette",
          categoryId: 3,
          fabric_type: 2,
        },
        {
          value: 62,
          label: "Chiffon",
          categoryId: 3,
          fabric_type: 2,
        },
        {
          value: 63,
          label: "Satin",
          categoryId: 3,
          fabric_type: 2,
        },
        {
          value: 65,
          label: "Twill",
          categoryId: 3,
          fabric_type: 2,
        },
        {
          value: 66,
          label: "Tafetta",
          categoryId: 3,
          fabric_type: 2,
        },
        {
          value: 68,
          label: "Jacquard",
          categoryId: 3,
          fabric_type: 1,
        },
        {
          value: 70,
          label: "Velvet",
          categoryId: 3,
          fabric_type: 2,
        },
        {
          value: 71,
          label: "Organza",
          categoryId: 3,
          fabric_type: 2,
        },
        {
          value: 76,
          label: "Fleece",
          categoryId: 3,
          fabric_type: 1,
        },
        {
          value: 124,
          label: "Interlock",
          categoryId: 3,
          fabric_type: 1,
        },
        {
          value: 132,
          label: "Single Jersey",
          categoryId: 3,
          fabric_type: 1,
        },
        {
          value: 145,
          label: "Dyed",
          categoryId: 3,
          fabric_type: 1,
        },
        {
          value: 146,
          label: "Melange",
          categoryId: 3,
          fabric_type: 1,
        },
        {
          value: 147,
          label: "Print",
          categoryId: 3,
          fabric_type: 1,
        },
        {
          value: 148,
          label: "Rib",
          categoryId: 3,
          fabric_type: 1,
        },
        {
          value: 149,
          label: "Sherpa",
          categoryId: 3,
          fabric_type: 1,
        },
        {
          value: 150,
          label: "Pique",
          categoryId: 3,
          fabric_type: 1,
        },
        {
          value: 151,
          label: "Mesh",
          categoryId: 3,
          fabric_type: 1,
        },
        {
          value: 152,
          label: "Honeycomb",
          categoryId: 3,
          fabric_type: 1,
        },
        {
          value: 153,
          label: "Waffle",
          categoryId: 3,
          fabric_type: 1,
        },
        {
          value: 154,
          label: "Raschel Knit",
          categoryId: 3,
          fabric_type: 1,
        },
        {
          value: 155,
          label: "Net",
          categoryId: 3,
          fabric_type: 1,
        },
        {
          value: 156,
          label: "Plain",
          categoryId: 3,
          fabric_type: 2,
        },
        {
          value: 157,
          label: "Corduroy",
          categoryId: 3,
          fabric_type: 2,
        },
        {
          value: 158,
          label: "Shantoon",
          categoryId: 3,
          fabric_type: 2,
        },
        {
          value: 159,
          label: "Voil",
          categoryId: 3,
          fabric_type: 2,
        },
      ],
      4: [
        {
          value: 78,
          label: "Cotton",
          categoryId: 4,
          fabric_type: null,
        },
        {
          value: 80,
          label: "Linen",
          categoryId: 4,
          fabric_type: null,
        },
        {
          value: 81,
          label: "Lycra",
          categoryId: 4,
          fabric_type: null,
        },
        {
          value: 82,
          label: "Viscose",
          categoryId: 4,
          fabric_type: null,
        },
        {
          value: 83,
          label: "Spandex",
          categoryId: 4,
          fabric_type: null,
        },
        {
          value: 84,
          label: "Wool",
          categoryId: 4,
          fabric_type: null,
        },
        {
          value: 85,
          label: "Bamboo",
          categoryId: 4,
          fabric_type: null,
        },
        {
          value: 86,
          label: "Flex",
          categoryId: 4,
          fabric_type: null,
        },
        {
          value: 87,
          label: "Sushi Voile",
          categoryId: 4,
          fabric_type: null,
        },
        {
          value: 128,
          label: "Modal",
          categoryId: 4,
          fabric_type: null,
        },
        {
          value: 160,
          label: "Polyester",
          categoryId: 4,
          fabric_type: null,
        },
      ],
      55: [
        {
          value: 88,
          label: "Pure Cotton",
          categoryId: 55,
          fabric_type: null,
        },
        {
          value: 89,
          label: "Cotton Sapndex",
          categoryId: 55,
          fabric_type: null,
        },
        {
          value: 90,
          label: "Poly Cotton",
          categoryId: 55,
          fabric_type: null,
        },
        {
          value: 91,
          label: "Poly Cotton Spandex",
          categoryId: 55,
          fabric_type: null,
        },
        {
          value: 129,
          label: "100% Tencel",
          categoryId: 55,
          fabric_type: null,
        },
        {
          value: 130,
          label: "Tencel Cotton",
          categoryId: 55,
          fabric_type: null,
        },
      ],
      56: [
        {
          value: 92,
          label: "Satin",
          categoryId: 56,
          fabric_type: null,
        },
        {
          value: 93,
          label: "Dupion",
          categoryId: 56,
          fabric_type: null,
        },
        {
          value: 94,
          label: "Crepe",
          categoryId: 56,
          fabric_type: null,
        },
        {
          value: 95,
          label: "Tissue",
          categoryId: 56,
          fabric_type: null,
        },
        {
          value: 96,
          label: "Tuelle",
          categoryId: 56,
          fabric_type: null,
        },
        {
          value: 131,
          label: "Chiffon",
          categoryId: 56,
          fabric_type: null,
        },
      ],
      57: [
        {
          value: 97,
          label: "Micro Modal",
          categoryId: 57,
          fabric_type: null,
        },
        {
          value: 98,
          label: "Lyocell",
          categoryId: 57,
          fabric_type: null,
        },
        {
          value: 99,
          label: "Tencel",
          categoryId: 57,
          fabric_type: null,
        },
        {
          value: 100,
          label: "Bamboo",
          categoryId: 57,
          fabric_type: null,
        },
        {
          value: 101,
          label: "Bamboo Rayon",
          categoryId: 57,
          fabric_type: null,
        },
        {
          value: 102,
          label: "Soyabean",
          categoryId: 57,
          fabric_type: null,
        },
        {
          value: 103,
          label: "Hemp",
          categoryId: 57,
          fabric_type: null,
        },
        {
          value: 104,
          label: "Ramie",
          categoryId: 57,
          fabric_type: null,
        },
        {
          value: 105,
          label: "Bemberg Cupro",
          categoryId: 57,
          fabric_type: null,
        },
      ],
      58: [
        {
          value: 106,
          label: "100% Linen",
          categoryId: 58,
          fabric_type: null,
        },
        {
          value: 107,
          label: "Cotton Linen",
          categoryId: 58,
          fabric_type: null,
        },
        {
          value: 108,
          label: "Viscose Linen",
          categoryId: 58,
          fabric_type: null,
        },
        {
          value: 109,
          label: "Cotton Flax ",
          categoryId: 58,
          fabric_type: null,
        },
        {
          value: 110,
          label: "Viscose Flax",
          categoryId: 58,
          fabric_type: null,
        },
      ],
      137: [
        {
          value: 138,
          label: "Cotton",
          categoryId: 137,
          fabric_type: null,
        },
        {
          value: 139,
          label: "Spandex",
          categoryId: 137,
          fabric_type: null,
        },
        {
          value: 140,
          label: "Lycra",
          categoryId: 137,
          fabric_type: null,
        },
        {
          value: 141,
          label: "Polyester",
          categoryId: 137,
          fabric_type: null,
        },
        {
          value: 142,
          label: "Hemp",
          categoryId: 137,
          fabric_type: null,
        },
        {
          value: 143,
          label: "Silk",
          categoryId: 137,
          fabric_type: null,
        },
        {
          value: 144,
          label: "Linen",
          categoryId: 137,
          fabric_type: null,
        },
      ],
    },
    certification: [
      {
        value: 1,
        label: "BCI",
      },
      {
        value: 3,
        label: "Organic",
      },
    ],
    weavePattern: [
      {
        value: 1,
        label: "3/1",
      },
      {
        value: 2,
        label: "2/1",
      },
      {
        value: 3,
        label: "Satin",
      },
      {
        value: 4,
        label: "Dobby",
      },
      {
        value: 5,
        label: "Knit",
      },
    ],
    unitByType: {
      null: [
        {
          id: 12,
          name: "ounces",
          shortName: "oz",
          fabricType: null,
        },
      ],
    },
  },
};
