import React from "react";
import "./styles.scss";
import { BlogsData } from "./.base";

const AllBlogs = () => {
  return (
    <div className="all-blogs-page">
      <div className="main-heading">
        <h2>All blogs</h2>
      </div>
      <div className="blogs_wrapper">
        {Object.values(BlogsData).map(({ title, link ,index}) => {
          return (
            <a
              href={link}
              key={index}
              style={{
                flexDirection: "row",
                display: "flex",
                cursor: "pointer",
                marginBottom:24,
                maxWidth:360
              }}

              target="_blank"
            >
              <p style={{ color: "#92979C", marginRight: 8 }}>|</p>
              <p className="blogsText">{title}</p>
            </a>
          );
        })}
      </div>
    </div>
  );
};
export default AllBlogs;
