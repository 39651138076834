import React from "react";
import "./style.scss";
const PrideCard = ({ img, heading, description }) => {
  return (
    <div className="pridecard">
      <div className="prideicon">
        <img className="prideimg" src={img} alt="prideImage"/>
      </div>
      <div className="prideheading">{heading}</div>
      <div className="pridedescription">{description}</div>
    </div>
  );
};
export default PrideCard;
