import React from "react";
import "./style.scss";
import iconarrow from "../../../assets/images/hero/Vector.svg";
const BannerSection = ({ text, link, buttonText }) => {
  return (
    <div className="aboutbanner">
      <div className="aboutbanner__left">
        <p className="para">{text}</p>
      </div>
      <div className="aboutbanner__right">
        <a href={link} rel="noopener noreferrer">
          <button type="button" className="btn">
            {buttonText}
            <img className="arrow" src={iconarrow} alt="icon" loading="lazy" />
          </button>
        </a>
      </div>
    </div>
  );
};

export default BannerSection;
