import React, { useEffect, useState } from "react";
import "./style.scss";
import Hero from "../../components/Hero/SupplierProfile";
import SupplierName from "../../components/SupplierName";
import SupplierProfileData from "../../components/SupplierProfileData";
import TeamMember from "../../components/TeamMember";
import { supplierDataApi } from "../../services/ApiService";
import { useParams } from "react-router-dom";
import ErrorUI from "../../components/ErrorUI";
import { SUPPLIER_SERVICES_CATEGORY_NAME } from "../LinkPage/base";
import { Helmet } from "react-helmet";
const SupplierProfilePage = () => {
  let params = useParams();
  const { id } = params;
  const [supplierData, setSupplierData] = useState();
  const [error, setError] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      try {
        const resp = await supplierDataApi(id);
        setSupplierData(resp?.data);
      } catch (err) {
        setError(true);
        console.error("Error occurred while fetching supplier data:", err);
      }
    };
    fetchData();
  }, [id]);

  const {
    productionTypes,
    companyName,
    category,
    addresses,
    fabricCategory,
    supplierUsers,
  } = supplierData || {};
  const { state } = addresses?.[0] || {};
  if (error) {
    return (
      <div className="supplierProfileContainer">
        <ErrorUI />
      </div>
    );
  }
  const supplierService =
    fabricCategory?.map((service) => service.categoryName).join(", ") || "--";
  return (
    <div className="supplierProfileContainer">
      <Helmet>
        <title>{`${companyName} | Suppliers`}</title>
        <meta
          name="description"
          content={`${companyName} is the leading ${SUPPLIER_SERVICES_CATEGORY_NAME[category]} supplier based out of ${state} for ${supplierService} fabrics.`}
        />
      </Helmet>
      <Hero productionTypes={productionTypes} />
      <SupplierName companyName={companyName} />
      <SupplierProfileData supplierData={supplierData} />
      {supplierUsers?.length ? (
        <TeamMember userDetails={supplierUsers} />
      ) : null}
    </div>
  );
};
export default SupplierProfilePage;
