export  const PRODUCTION_SUB_TYPE_REVERSE = {
    1: 'Greige',
    2: 'Yarn Dyed',
    3: 'RFD',
    4: 'Dyed',
    5: 'Printed',
    6: 'Denim',
  };
  export const SUPPLIER_SERVICES_CATEGORY_NAME = {
    1: 'Weaving',
    2: 'Processing',
    3: 'Weaving, Processing',
  };