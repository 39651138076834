import img1 from "../../assets/images/hero/section2_1.webp";
import img2 from "../../assets/images/hero/section2_pic2.webp";
import img3 from "../../assets/images/hero/section2_pic3.webp";
import img4 from "../../assets/images/hero/section2_pic4.webp";
import GriegeFabric from "../../assets/images/hero/Greige fabric.png";
import FinishedFabric from "../../assets/images/hero/Finished fabric.png";
import Credit from "../../assets/images/hero/Credit.png";
import Logistic from "../../assets/images/hero/Logistics.png"

export const platformArray = [
  {
    img: img1,
    heading: "Greige fabric",
    subHeading:
      `Get high-quality yarn spun using state-of-the-art machinery capable of producing yarns of various materials and counts to meet the diverse needs of customers.`,
      formType:"others"
  },
  {
    img: img2,
    heading: "Finished fabric",
    subHeading:
      'Access to processing services like dyeing, printing, RFD, embroidery done using advanced equipment that ensure highest quality outputs.',
      formType:"others"
  },
  {
    img: img3,
    heading: "Logistics",
    subHeading:
      "Experience our seamless, reliable supply chain solution tailored to your needs. Our commitment to affordability ensures unmatched value.",
      formType:"logistic"
  },
  {
    img: img4,
    heading: "Credit",
    subHeading: `We're thrilled to present the Locofast Credit Program: An exclusive opportunity designed to elevate your fabric sourcing experience.`,
    formType:"credit"
  },
];
export const  modalData=[
  {
    img:GriegeFabric,
    formType:'griege',
    heading:'Fill details'
  },
  {
    img:FinishedFabric,
    formType:'finishedFabric',
    heading:'Fill details'
  },
  
  {
    img:Logistic,
    formType:'logistic',
    heading:'Fill details'
  },
  
  {
    img:Credit,
    formType:'credit',
    heading:'Fill details'
  },
  
]