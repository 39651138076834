import React, { useEffect, useState } from "react";
import { supplierListingApi } from "../../services/ApiService";
import { Link } from "react-router-dom";
import { SUPPLIER_SERVICES_CATEGORY_NAME } from "./base";

const LinkPage = () => {
  const [supplierList, setSupplierList] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    supplierListingApi()
      .then((resp) => {
        setSupplierList(resp?.data?.supplierList);
      })
      .catch((err) => {
        let msg =
          "Error occurred while submitting the request. Please try again.";
        if (typeof err === "string") {
          msg = `Following e${msg.substr(1)}\n${err}`;
        }
      });
  }, []);
  return supplierList?.map((supplier) => {
    const category = SUPPLIER_SERVICES_CATEGORY_NAME[supplier?.category].toLowerCase();
    const name = supplier?.name?.trim().replace("/","").replace("&", "and").replace(/\s/g, "-").toLowerCase();
    const stateValue = supplier?.state?.trim().replace(/\s/g, "-").toLowerCase();
    return (
      <div>
        <Link
          to={`/suppliers/${category}/${stateValue}/${name}/id=${supplier?.id}`}
        ></Link>
      </div>
    );
  });
};
export default LinkPage;
