import React from "react";
import "./style.scss";
import conscious from "../../assets/images/feature/conscious.svg";
import image1 from "../../assets/images/certificates/1.webp";
import {
  CONSCIOUS_INFO,
  CONSCIOUS_INFO2,
  CONSCIOUS_INFO_MOBILE,
  CONSCIOUS_INITIAL_STATE,
} from "./config";
import { useState } from "react";

const ConsciousSection = () => {
  const [content, setContent] = useState(CONSCIOUS_INITIAL_STATE);
  return (
    <div className="conscious">
      <div className="info">
        <div className="header">
          <img src={conscious} alt="conscious-img" className="image" />
          <div className="heading">Conscious Sourcing</div>
        </div>
        <div className="header-info">
          By offering a diverse green product portfolio including organic
          textiles like organic cotton, linen, and tencel, we assist our
          customers make premium quality products which are long lasting and
          high on functionality.
        </div>
      </div>
      <div className="right">
        <div className="right__images">
          <div className="first">
            <div
              className="special"
              onMouseOver={() => {
                setContent({
                  text: "Birla Cellulose",
                  subtext:
                    "Birla Cellulose is a World leader in Viscose Staple Fibre (VSF). It's fibres are of natural origin, moisture absorbent, have soft feel, and are completely biodegradable.",
                });
              }}
              onMouseLeave={() => {
                setContent(CONSCIOUS_INITIAL_STATE);
              }}
            >
              <img src={image1} alt="logo" />
            </div>
            <div className="two-logo">
              {CONSCIOUS_INFO2.map(({ image, text, subtext }, id) => (
                <div
                  className="image"
                  key={id}
                  onMouseOver={() => {
                    setContent({ text: text, subtext: subtext });
                  }}
                  onMouseLeave={() => {
                    setContent(CONSCIOUS_INITIAL_STATE);
                  }}
                >
                  <img src={image} alt="logo" />
                </div>
              ))}
            </div>
          </div>
          <div className="second">
            {CONSCIOUS_INFO.map(({ image, text, subtext }, id) => (
              <div
                className="image"
                key={id}
                onMouseOver={() => {
                  setContent({ text: text, subtext: subtext });
                }}
                onMouseLeave={() => {
                  setContent(CONSCIOUS_INITIAL_STATE);
                }}
              >
                <img src={image} alt="logo" />
              </div>
            ))}
          </div>
        </div>
        <div className="right__mobile">
          {CONSCIOUS_INFO_MOBILE.map(({ image, text, subtext }, id) => (
            <div
              className="mobile-image"
              key={id}
              onMouseOver={() => {
                setContent({ text: text, subtext: subtext });
              }}
              onMouseLeave={() => {
                setContent(CONSCIOUS_INITIAL_STATE);
              }}
            >
              <img src={image} alt="logo" className={`${id===2 && 'image2'} ${id===8 && 'image8'} `}/>
            </div>
          ))}
        </div>
        <div className="description">
          <div className="text">{content.text}</div>
          <div className="subtext">{content.subtext}</div>
        </div>
      </div>
    </div>
  );
};

export default ConsciousSection;
