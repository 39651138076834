import React, { useRef, useEffect } from "react";
import { Helmet } from "react-helmet";

import Footer from "../../components/Footer";
import Hero from "../../components/Hero";
import QueryForm from "../../components/RequestModal/Form";
import PartnerSection from "../../components/PartnerSection";
import { PARTNER_SECTION_CONTENT } from "../../components/PartnerSection/partnerarray";
import "./style.scss";
import Carousel from "../../components/Carousel";
import AllBlogs from "../../components/AllBlogs";
import KnowMoreUpdatesSection from "../../components/KnowMoreUpdatesSection";
import HeroSectionFAQ from "../../components/HeroSectionFAQ";
import AppOutlookSlide from "../../components/AppOutlookSlide";
import CustomerBase from "../../components/CustomerBase";
import BuyingHouse from "../../components/BuyingHouse";
import BrandsSection from "../../components/BrandsSection";
import ManufactureSection from "../../components/ManufactureSection";
import { customerQuestionAnswers } from "../../components/HeroSectionFAQ/base";
import { knowMoreCustomerSectionArray } from "./_base";

const Careers = ({ heroRef }) => {
  const featureSectionRef = useRef(null);
  const formRef = useRef(null);
  const navigateToForm = () => {
    formRef.current.scrollIntoView(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleScroll = () => {
    featureSectionRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div className="careersWrapper">
      <Helmet>
        <title>Locofast: Cloud Textile Factory | Customers</title>
        <meta
          name="description"
          content="Locofast is the leading marketplace for fabrics. We provide a wide range of cotton, rayon, and sustainable, eco-friendly fabrics at the best price.

"
        />
      </Helmet>
      <Hero
        navigateToForm={navigateToForm}
        heroRef={heroRef}
        handleScroll={handleScroll}
        isCustomer
      />
      <PartnerSection
        featureSectionRef={featureSectionRef}
        navigateToForm={navigateToForm}
        imgDetails={PARTNER_SECTION_CONTENT}
        page={'customer'}
      />
      <BrandsSection/>
      <ManufactureSection/>
      <BuyingHouse/>
      <CustomerBase/>
      <AppOutlookSlide/>
      <Carousel heading="Best service begins here" />
      {/* <BannerSection
        image={Icon}
        isImage={true}
        text1="With Locofast,"
        text2="Procure fabrics fast."
        link="https://play.google.com/store/apps/details?id=com.locofastcustomerapp"
      /> */}
      <HeroSectionFAQ  faqData={customerQuestionAnswers}/>
      <QueryForm
        formRef={formRef}
        isButtonShow={false}
        user="customer"
        isDirection={true}
        isMember={false}
        isHomePage
      />
      <KnowMoreUpdatesSection knowMoreData={knowMoreCustomerSectionArray}/>
      <AllBlogs />
      <Footer  isHomePage={true}/>
    </div>
  );
};

export default Careers;
