import React from "react";
import { Features } from "./config";
import HeroPoster from "../../../assets/images/hero/HeroSwatch.png";
import video from "../../../assets/images/feature/swatch.mp4";

import "./style.scss";

const FeatureSection = ({ heroRef }) => {
  const handleLoad = (e) => {
    const target = e.target;
    setTimeout(() => {
      target.play();
    }, 2000);
    e.target.controls = false;
  };
  return (
    <div className="feature" ref={heroRef}>
      <div className="feature__home">
        <video
          muted
          loop
          playsInline
          className="hero__video"
          poster={HeroPoster}
          preload="auto"
          onLoadedData={handleLoad}
        >
          <source
            src={video}
            type="video/mp4"
            alt=" Your browser doesn't support video"
          />
          Your browser does not support HTML5 video.
        </video>
        <div className="info">
          <div className="heading">Source out of the box</div>
          <div className="para">
            A one of a kind platform to source fabrics, swatches and get
            inspirations along the way.
          </div>
          <a
            href="https://swatchbox.locofast.com/"
            target="_blank"
            rel="noreferrer"
          >
            <button type="submit" className="btn" value="button">
              Order now
            </button>
          </a>
        </div>
      </div>
      <div className="feature__features">
        {Object.values(Features).map(({ img, text }, id) => (
          <div className="feature-box" key={id}>
            <img src={img} alt="feature" loading="lazy" />
            <div className="name">{text}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeatureSection;