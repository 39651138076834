import Cotton from "../../assets/images/category/Cotton.png";
import Rayon from "../../assets/images/category/Rayon.png";
import Linen from "../../assets/images/category/Linen.png";

export const CATEGORY = [
  {
    source: "https://swatchbox.locofast.com/collections/cotton-fabric",
    img: Cotton,
    text: "Cotton",
  },
  {
    source: "https://swatchbox.locofast.com/collections/rayon-fabrics",
    img: Rayon,
    text: "Rayon",
  },
  {
    source: "https://swatchbox.locofast.com/collections/linen-fabric",
    img: Linen,
    text: "Linen",
  },
];
