import React from "react";
import C1 from "../../assets/images/certification/1.svg";
import C2 from "../../assets/images/certification/2.svg";
import C3 from "../../assets/images/certification/3.svg";
import C4 from "../../assets/images/certification/4.svg";
import C5 from "../../assets/images/certification/5.svg";
import "./style.scss";

function Certifications() {
  return (
    <div className="certifications">
      <div className="certifications__left">
        <h2 className="heading">Investors</h2>
        <div className="certifications__left__first">
          <div className="two">
            <img src={C1} alt="certifications" loading="lazy" />
            <img src={C2} alt="certifications" loading="lazy" />
          </div>
          <img src={C3} alt="certifications" />
        </div>
      </div>

      <div className="certifications__right">
        <h2 className="heading">Debt Partners</h2>
        <div className="certifications__right__second">
          <img src={C4} alt="certifications" />
          <img src={C5} alt="certifications" />
        </div>
      </div>
    </div>
  );
}

export default Certifications;
