import React from "react";

import ImageSlider from "./ImageSlider";
import img1 from "../../assets/images/slider/1.webp";
import img2 from "../../assets/images/slider/2.webp";
import img3 from "../../assets/images/slider/3.webp";
import img4 from "../../assets/images/slider/4.webp";
import img5 from "../../assets/images/slider/5.webp";
import arrowleft from "../../assets/images/slider/chevron left.svg";
import arrowright from "../../assets/images/slider/chevron right.svg";
const Slider2 = () => {
  const slides = [
    {
      url: img1,
      title: "beach",
    },
    {
      url: img2,
      title: "boat",
    },
    {
      url: img3,
      title: "forest",
    },
    {
      url: img4,
      title: "city",
    },
    {
      url: img5,
      title: "italy",
    },
  ];
  const containerStyles = {
    height: "280px",
    margin: "60px auto",
  };
  return (
    <div className="slidecar">
      <div style={containerStyles}>
        <ImageSlider
          slides={slides}
          arrowleft={arrowleft}
          arrowright={arrowright}
        />
      </div>
    </div>
  );
};

export default Slider2;
