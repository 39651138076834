import photo from "../../assets/images/Item.png";
export const productCategory = [
  "Greige",
  "Yarn dyed",
  "RFD",
  "Dyed",
  "Printed",
];
export const Cotton = (productType) => {
  const baseUrl =
    "https://miscellaneous-lf.s3.ap-south-1.amazonaws.com/static/sub-category/Cotton/Cotton+";
  return [
    {
      img: `${baseUrl}${productType.toUpperCase()}/Cambric.jpg`,
      name: "Cambric",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Voile.jpg`,
      name: "Voile",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Poplin.jpg`,
      name: "Poplin",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Double+cloth.jpg`,
      name: "Double Cloth",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Sheeting.jpg`,
      name: "Sheeting",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Drill.jpg`,
      name: "Drill",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Duck.jpg`,
      name: "Duck",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Dobbies.jpg`,
      name: "Dobbies",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Twill.jpg`,
      name: "Twill",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Slub.jpg`,
      name: "Slub",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Crepe.jpg`,
      name: "Crepe",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Jacquard.jpg`,
      name: "Jacquard",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Gauze.jpg`,
      name: "Gauze",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Lurex.jpg`,
      name: "Lurex",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Velvet.jpg`,
      name: "Velvet",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Flannel.jpg`,
      name: "Flannel",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Satin.jpg`,
      name: "Satin",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Canvas.jpg`,
      name: "Canvas",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Plain.jpg`,
      name: "Plain",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Single+Jersey.jpg`,
      name: "Single Jersey",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Pique.jpg`,
      name: "Pique",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Terry.jpg`,
      name: "Terry",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Fleece.jpg`,
      name: "Fleece",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Interlock.jpg`,
      name: "Interlock",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Flex.jpg`,
      name: "Flex",
    },
    {
      img: photo,
      name: "",
    },
    {
      img: photo,
      name: "",
    },
  ];
};
export const Rayon = (productType) => {
  const baseUrl =
    "https://miscellaneous-lf.s3.ap-south-1.amazonaws.com/static/sub-category/Rayon/Rayon+";
  return [
    {
      img: `${baseUrl}${productType.toUpperCase()}/Plain.jpg`,
      name: "Plain",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Crepe.jpg`,
      name: "Crepe",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Slub.jpg`,
      name: "Slub",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Dobbies.jpg`,
      name: "Dobbies",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Twill.jpg`,
      name: "Twill",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Modal.jpg`,
      name: "Modal",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Lurex.jpg`,
      name: "Lurex",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Ecovera.jpg`,
      name: "Ecovera",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Viscose+Georgette.jpg`,
      name: "Viscose Georgette",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Viscose+Satin.jpg`,
      name: "Viscose Satin",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Viscose+Chiffon.jpg`,
      name: "Viscose Chiffon",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Santoon.jpg`,
      name: "Santoon",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Moss.jpg`,
      name: "Moss",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Drill.jpg`,
      name: "Drill",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Jacquard.jpg`,
      name: "Jacquard",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Ecoliva.jpg`,
      name: "Ecoliva",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Viscose+Moss.jpg`,
      name: "Viscose Moss",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Viscose+Crepe.jpg`,
      name: "Viscose Crepe",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Viscose+Dull+Crepe.jpg`,
      name: "Viscose Dull Crepe",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Viscose+Moroccan+Crepe.jpg`,
      name: "Viscose Moroccan Crepe",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Pique.jpg`,
      name: "Pique",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Terry.jpg`,
      name: "Terry",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Fleece.jpg`,
      name: "Fleece",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Interlock.jpg`,
      name: "Interlock",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Single+Jersey.jpg`,
      name: "Single Jersey",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Flex.jpg`,
      name: "Flex",
    },
    {
      img: photo,
      name: "",
    },
    {
      img: photo,
      name: "",
    },
  ];
};
export const Denim = (productType) => {
  return [
    {
      img: "https://miscellaneous-lf.s3.ap-south-1.amazonaws.com/static/sub-category/Denim/Denim+INDIGO.jpg",
      name: "Indigo",
    },
    {
      img: "https://miscellaneous-lf.s3.ap-south-1.amazonaws.com/static/sub-category/Denim/Denim+BLUE.jpg",
      name: "Blue",
    },
    {
      img: "https://miscellaneous-lf.s3.ap-south-1.amazonaws.com/static/sub-category/Denim/Denim+BLACK.jpg",
      name: "Black",
    },
    {
      img: "https://miscellaneous-lf.s3.ap-south-1.amazonaws.com/static/sub-category/Denim/Denim+IBST.jpg",
      name: "IBST",
    },
    {
      img: "https://miscellaneous-lf.s3.ap-south-1.amazonaws.com/static/sub-category/Denim/Denim+ECRU.jpg",
      name: "Ecru",
    },
    {
      img: photo,
      name: "",
    },
    {
      img: photo,
      name: "",
    },
    {
      img: photo,
      name: "",
    },
  ];
};
export const Polyester = (productType) => {
  const baseUrl =
    "https://miscellaneous-lf.s3.ap-south-1.amazonaws.com/static/sub-category/Polyester/Polyester+";
  return [
    {
      img: `${baseUrl}${productType.toUpperCase()}/Crepe.jpg`,
      name: "Crepe",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Georgette.jpg`,
      name: "Georgette",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Chiffon.jpg`,
      name: "Chiffon",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Satin.jpg`,
      name: "Satin",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Twill.jpg`,
      name: "Twill",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Tafetta.jpg`,
      name: "Tafetta",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Jacquard.jpg`,
      name: "Jacquard",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Velvet.jpg`,
      name: "Velvet",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Organza.jpg`,
      name: "Organza",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Fleece.jpg`,
      name: "Fleece",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Interlock.jpg`,
      name: "Interlock",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Single+Jersey.jpg`,
      name: "Single Jersey",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Dyed.jpg`,
      name: "Dyed",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Melange.jpg`,
      name: "Melange",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Print.jpg`,
      name: "Print",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Rib.jpg`,
      name: "Rib",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Sherpa.jpg`,
      name: "Sherpa",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Pique.jpg`,
      name: "Pique",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Mesh.jpg`,
      name: "Mesh",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Honeycomb.jpg`,
      name: "Honeycomb",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Waffle.jpg`,
      name: "Waffle",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Raschel+Knit.jpg`,
      name: "Raschel Knit",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Net.jpg`,
      name: "Net",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Plain.jpg`,
      name: "Plain",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Corduroy.jpg`,
      name: "Corduroy",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Shantoon.jpg`,
      name: "Shantoon",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Voil.jpg`,
      name: "Voil",
    },
    {
      img: photo,
      name: "",
    },
    {
      img: photo,
      name: "",
    },
  ];
};
export const Nylon = (productType) => {
  const baseUrl =
    "https://miscellaneous-lf.s3.ap-south-1.amazonaws.com/static/sub-category/Nylon/Nylon+";
  return [
    {
      img: `${baseUrl}${productType.toUpperCase()}/Satin.jpg`,
      name: "Satin",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Dupion.jpg`,
      name: "Dupion",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Crepe.jpg`,
      name: "Crepe",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Tissue.jpg`,
      name: "Tissue",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Tuelle.jpg`,
      name: "Tuelle",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Chiffon.jpg`,
      name: "Chiffon",
    },
    {
      img: photo,
      name: "",
    },
    {
      img: photo,
      name: "",
    },
  ];
};
export const Linen = (productType) => {
  const baseUrl =
    "https://miscellaneous-lf.s3.ap-south-1.amazonaws.com/static/sub-category/Linen+/Linen+";
  return [
    {
      img: `${baseUrl}${productType.toUpperCase()}/100%25+Linen.jpg`,
      name: "100% Linen",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Cotton+Linen.jpg`,
      name: "Cotton Linen",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Viscose+Linen.jpg`,
      name: "Viscose Linen",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Cotton+Flax.jpg`,
      name: "Cotton Flax",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Viscose+Flax.jpg`,
      name: "Viscose Flax",
    },
    {
      img: photo,
      name: "",
    },
    {
      img: photo,
      name: "",
    },
  ];
};
export const Sustainable = (productType) => {
  const baseUrl =
    "https://miscellaneous-lf.s3.ap-south-1.amazonaws.com/static/sub-category/Sustainable/Sustainable+";
  return [
    {
      img: `${baseUrl}${productType.toUpperCase()}/Micro+Modal.jpg`,
      name: "Micro Modal",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Lyocell.jpg`,
      name: "Lyocell",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Tencel.jpg`,
      name: "Tencel",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Bamboo.jpg`,
      name: "Bamboo",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Bamboo+Rayon.jpg`,
      name: "Bamboo Rayon",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Soyabean.jpg`,
      name: "Soyabean",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Hemp.jpg`,
      name: "Hemp",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Ramie.jpg`,
      name: "Ramie",
    },
    {
      img: `${baseUrl}${productType.toUpperCase()}/Bemberg+Cupro.jpg`,
      name: "Bemberg Cupro",
    },
    {
      img: photo,
      name: "",
    },
    {
      img: photo,
      name: "",
    },
  ];
};
export const PRODUCT = {
  Cotton: Cotton,
  Rayon: Rayon,
  Denim: Denim,
  Polyester: Polyester,
  Nylon: Nylon,
  Linen: Linen,
  Sustainable: Sustainable,
};
