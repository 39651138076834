import React from "react";
import "./style.scss";
import Bullet from "../../assets/images/partner/Bullet.svg";
const InfoContainer = ({ title, subHeading, btnTxt, onClick, pointerData }) => {
  return (
    <div className="infoContainer">
      <p className="outlookHeading marginBottom">{title}</p>
      <p className="facilityTitle marginBottom">{subHeading}</p>
      <div className="marginBottom">
        {pointerData?.map((item) => {
          return (
            <div className="bulletContainer">
              <img src={Bullet} style={{ marginRight: 16, marginTop: 8 }} />
              <p className="facilityTitle ">{item}</p>
            </div>
          );
        })}
      </div>
      <button id="infoBtn" onClick={onClick}>
        {btnTxt}
      </button>
    </div>
  );
};
export default InfoContainer;
