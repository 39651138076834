import img1 from '../../assets/images/partner/knowMore1.png';
import img2 from '../../assets/images/partner/knowMore2.png';
import img3 from '../../assets/images/partner/knowMore3.png';
export const knowMoreSectionArray=[
    {
        img:img1,
        title:'Printing services by Locofast',
        subText:'Textile Printing The practice of adding colour to fabric in specific patterns or motifs is known as textile printing.…',
        date:'January 14, 2023',
        link:'https://locofast.com/blog/?p=369',
    },
    {
        img:img2,
        title:'Understanding weaving and fabric loom types',
        subText:'In textile production, weaving is the process of interlacing warp and weft materials to make a fabric, usually accomplished with…',
        date:'January 13, 2023',
        link:'https://locofast.com/blog/?p=365',
    },
    {
        img:img3,
        title:'A deep dive into Yarn Manufacturing Process',
        subText:'A series of processes known as yarn manufacturing transforms unprocessed cotton fibres into yarn that may be used in…',
        date:'September 10, 2022',
        link:'https://locofast.com/blog/?p=581',
    }
]