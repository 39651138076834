import React from "react";

import Certificate1 from "../../assets/images/certificates/certificate1.svg";
import Certificate2 from "../../assets/images/certificates/certificate2.svg";
import Certificate3 from "../../assets/images/certificates/certificate5.svg";
import Certificate4 from "../../assets/images/certificates/certificate3.svg";
import Certificate5 from "../../assets/images/certificates/certificate4.svg";
import './style.scss';
import { CERTIFICATES } from "./config";

const Arraycert = [
  {
    certificate: Certificate1,
  },
  {
    certificate: Certificate2,
  },
  {
    certificate: Certificate4,
  },
  {
    certificate: Certificate3,
  },
  {
    certificate: Certificate5,
  },
];
const Certificates = () => {
  return (
    <div className="certificates">
      <div className="certificates__heading">Certifications</div>
      <div className="certificates__list desktop">
        {Object.values(Arraycert).map((certifications,id) => (
          <div className="certif" key={id}>
            <img src={certifications.certificate} alt="icon" />
          </div>
        ))}
      </div>
      <div className="certificates__list mobile">
        {Object.values(CERTIFICATES).map((certifications,id) => (
          <div className="certif" key={id}>
            <img src={certifications.certificate} alt="icon" />
          </div>
        ))}
      </div>
    </div>
  );
};
export default Certificates;
