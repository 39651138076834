import Icon1 from "../../assets/images/pride/icon1.svg";
import Icon2 from "../../assets/images/pride/icon2.svg";
import Icon3 from "../../assets/images/pride/icon3.svg";
import Icon4 from "../../assets/images/pride/icon4.png";
import Icon5 from "../../assets/images/pride/icon5.svg";
import Icon6 from "../../assets/images/pride/icon6.svg";

export const Pridearray = [
  {
    img: Icon3,
    title: "Integrity",
    description:
      "When there's a choice between what's convenient and what's right, we always choose what's right.",
  },
  {
    img: Icon5,
    title: "Open disagreements",
    description:
      "We practice open communication. We encourage you to share disagreements as it fosters trust and better relationships.",
  },
  {
    img: Icon2,
    title: "Empathy",
    description:
      "We listen, we understand, and we care. We have compassion for others, and it shows.",
  },
  {
    img: Icon1,
    title: "Responsibility",
    description:
      "We believe in building trust through our responsible actions and honest relationships. We are bigger than our roles, and are not defined by our titles.",
  },

  {
    img: Icon6,
    title: "Underdog Mindset",
    description:
      "We are young, dynamic and undeterred to take on companies much larger than ours.",
  },
  {
    img: Icon4,
    title: "Speed",
    description:
      "We work at the speed of light. We are strategically patient, and tactically swift.",
  },
];
