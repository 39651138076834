import React from "react";
import "./style.scss";
import right from "../../assets/images/variety/right.svg";
import { VARIETYDATA } from "./config";

const VarietySection = () => {
  return (
      <div className="variety-section">
        <div className="center-text">
          <div className="heading">Do more with Locofast</div>
          <div className="para">
            Find design and fabric inspirations, all in one place to help you
            build the story behind your collection.
          </div>
        </div>
        {VARIETYDATA.map(({ name, img, Row, link }, index) => (
          <div className="mood-image-section" key={index} onClick={() => window.open(link)}>
            <div className="name">
              {name}
              <img src={right} alt="right-arrow" className="arrow" />
            </div>
              <div className="image-box">
                <img
                  src={img}
                  alt="mood-section"
                  className="special"
                  loading="lazy"
                />
                <div className="image-box__column">
                  {Row.map((column, id) => (
                    <div className="row" key={id}>
                      {column.map((img, id) => (
                        <img src={img} alt=" Gingham" loading="lazy" key={id} />
                      ))}
                    </div>
                  ))}
                </div>
              </div>
          </div>
        ))}
      </div>
  );
};

export default VarietySection;
