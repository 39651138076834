import React, { useRef } from "react";
import { Helmet } from "react-helmet";

import HeroSection from "../../components/Hero/Sustainability";
import Footer from "../../components/Footer";
import ContactUs from "../../components/RequestModal/Form";
import PillarsSection from "../../components/PillarsSection";
import Compliance from "../../components/Compliance";
import Collaboration from "../../components/Collaboration";
import ConsciousSection from "../../components/Conscious";
import CommunitySection from "../../components/CommunitySection";

const Sustainability = ({ heroRef }) => {
  const featureSectionRef = useRef(null);
  const handleScroll = () => {
    featureSectionRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <>
      <Helmet>
        <title>Locofast: Cloud Textile Factory | Sustainability</title>
        <meta
          name="description"
          content="Locofast is the leading marketplace for fabrics. We provide a wide range of cotton, rayon, and sustainable, eco-friendly fabrics at the best price."
        />
      </Helmet>
      <HeroSection heroRef={heroRef} handleScroll={handleScroll} />
      <PillarsSection />
      <CommunitySection/>
      <Compliance />
      <Collaboration/>
      <ConsciousSection/>
      <ContactUs isButtonShow={true} isDirection={true} isMember={false} sustain={true}/>
      <Footer />
    </>
  );
};

export default Sustainability;
