import React, { useState } from "react";
import CategorySubmissionForm from "../CategorySubmissionForm";
import "./styles.scss";
import close from "../../assets/images/modal/close.svg";
const PlatformSectionModaL = ({ data, _handleClose,formRef}) => {
  const [isSubmitSuccessful, setSubmitSuccess] = useState(false);
  return (
    <div className="platformModal">
      {/* {!isSubmitSuccessful ? (
        <div className="platformImgSection">
          <img src={data?.img} alt="icon"/>
        </div>
      ) : null} */}
      <div className="platformFormSection">
        <div style={{ display: "flex", justifyContent: "space-between"}}>
          <p className="modalHeading">
            {!isSubmitSuccessful ? data?.heading : ""}
          </p>
          <img
            src={close}
            className="modalClose"
            alt="Close"
            onClick={() => {
              _handleClose();
              setSubmitSuccess(false);
            }}
          />
        </div>
        <div style={{ marginTop: 24}}>
          <CategorySubmissionForm
            fillDetails={true}
            closedText={"Close"}
            submitText={"Request a call back"}
            isSubmitSuccessful={isSubmitSuccessful}
            setSubmitSuccess={setSubmitSuccess}
            requirementFormClass={'platformrequirementFormClass'}
            _handleClose={() => {
              _handleClose();
              setSubmitSuccess(false);
            }}
            footerClass={'footerClass'}
            formRef={formRef}
            formType={data?.formType}
          />
        </div>
      </div>
    </div>
  );
};
export default PlatformSectionModaL;
