import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import BannerSection from "../../components/BannerSection/About";
import Footer from "../../components/Footer";
import LifeSection from "../../components/LifeSection";
import Slider from "../../components/Sliders";

const WorkWithUs = ({ heroRef }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Locofast: Cloud Textile Factory | WorkWithUs</title>
        <meta
          name="description"
          content=" Locofast is the leading marketplace for fabrics. We provide a wide range of cotton, rayon, and sustainable, eco-friendly fabrics at the best price.

"
        />
      </Helmet>
      <Slider />
      <BannerSection
        text="Explore job opportunities at Locofast"
        link="https://www.linkedin.com/company/locofast/jobs/"
        buttonText="View jobs"
      />
      <LifeSection />
      <Footer />
    </>
  );
};
export default WorkWithUs;
