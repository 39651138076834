import React from "react";
import "./style.scss";
import AppImage from "../../assets/images/banner/AppImage.webp";
import { customerFacilities } from "./_baseArray";
import PlayStoreButtton from '../../assets/images/banner/google-play-badge 2.png'
const AppOutlookSlide = () => {
  return (
    <div className="appOutlookContainer">
      <div>
        <p className="outlookHeading">
          Procuring fabrics is now just a few clicks away
        </p>

        <div style={{ marginBottom: 32 }}>
          {customerFacilities.map((item, index) => {
            return (
              <div className="facilities">
                <img key={index} src={item.img} alt={`${item.heading}`} style={{marginRight:16}} />
                <div className="facilityTitle"> {item?.title}</div>
              </div>
            );
          })}
        </div>
        <div className="playstoreContainer">
        <a
          href="https://play.google.com/store/apps/details?id=com.locofastcustomerapp"
          target="_blank"
          rel="noopener noreferrer"
         className="playBtn" 
        >
       <img src={PlayStoreButtton} alt="" width='100%' height='100%'/>
        </a>
        </div>
        
      </div>
      <div className="appImageContainer">
        <img  src={AppImage} width='100%' height='100%'  />
      </div>
    </div>
  );
};
export default AppOutlookSlide;
