import React, { useRef, useState } from "react";
import "./styling.scss";

import arrowForward from "../../assets/images/banner/arrow_forward.svg";
import Modal from "../Modal";
import CategorySubmissionForm from "../CategorySubmissionForm";
function AdvantageSection({ navigateToForm }) {
  const [fillDetails, setFillDetails] = useState(false);
  const [isSubmitSuccessful, setSubmitSuccess] = useState(false);
  const [formModal, setFormModal] = useState(false);
  const formRef = useRef();
  const [formType, setFormType] = useState("");
  return (
    <div className="mainbox">
      <div className="inner1" >
        <p className="heading">
          Seamless Fabric Sourcing: Join and Start Procuring!
        </p>
        <p className="description">
          We prioritize quality and satisfaction, ensuring our fabrics meet the
          highest standards while maintaining competitive prices.
        </p>
        <button className="section_button" style={{background:'#BB6BD9'}} onClick={()=>{
           setFormType("customer");
           setFormModal(true);
           setSubmitSuccess(false);
           formRef?.current?.resetForm();
        }}>
        <p className="sectionButtontxt">Register now</p>
        <img
        src={arrowForward}
        alt="icon"
        loading="lazy"
        className="arrow"
        />
        </button>
      </div>
      <div className="inner2" >
        <p className="heading">
          Revolutionize Fabric Sales: Join Our Platform!
        </p>
        <p className="description">
          Connect with a diverse network of buyers and showcase your quality
          textiles to a global audience. Start selling today!
        </p>
        <button className="section_button" style={{background:'#008B9D'}}  onClick={()=>{
           setFormType("supplier");
           setFormModal(true);
           setFillDetails(true);
           setSubmitSuccess(false);
           formRef?.current?.resetForm();
        }}>
        <p className="sectionButtontxt">Explore now</p>
        <img
        src={arrowForward}
        alt="icon"
        loading="lazy"
        className="arrow"
        />
        </button>
      </div>
      <Modal
        title={
          !isSubmitSuccessful
            ? fillDetails
              ? "Fill details"
              : "Share your requirement"
            : ""
        }
        show={formModal}
        _handleClose={() => {
          setFormModal(false);
          setFillDetails(false);
        }}
      >
        <CategorySubmissionForm
          closedText={"Close"}
          submitText={"Request a call back"}
          _handleClose={() => {
            setFormModal(false);
            setFillDetails(false);
          }}
          isSubmitSuccessful={isSubmitSuccessful}
          setSubmitSuccess={setSubmitSuccess}
          fillDetails={fillDetails}
          requirementFormClass={"herorequirementFormClass"}
          formType={formType}
          formRef={formRef}
        />
      </Modal>
    </div>
  );
}

export default AdvantageSection;
