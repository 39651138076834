import React from "react";
import "./style.scss";
import Contact from "../../assets/components/SupplierProfile/User profile.svg";

const TeamMember = ({ userDetails }) => {
  return (
    <div className="supplierUserNameContainer ">
      <p className="teamMember">Team member</p>
      {userDetails?.map((supplierUser,index) => {
        return (
          <div className="supplierTeamMember" key={index}>
            <img src={Contact} alt="contant icon" />
            <p className="supplierUserName">{supplierUser?.name}</p>
          </div>
        );
      })}
    </div>
  );
};
export default TeamMember;
