import React from "react";

import Form from "./../../RequestModal/Adwords";

import { ReactComponent as QualityIcon } from "../../../assets/images/adwords/usps/ic-star.svg";
import { ReactComponent as VisibilityIcon } from "../../../assets/images/adwords/usps/ic-visibility.svg";
import { ReactComponent as TurnaroundsIcon } from "../../../assets/images/adwords/usps/ic-turnarounds.svg";
import { ReactComponent as ManagerIcon } from "../../../assets/images/adwords/usps/ic-manager.svg";
import Greige from "../../../assets/images/adwords/adwordsBanner.webp";
import Denim from "../../../assets/images/adwords/Denim.webp";
import Hoisery from "../../../assets/images/adwords/Hoisery.webp";

import "../heroStyles.scss";
import "./styles.scss";
import { useLocation } from "react-router-dom";
import { capitalizeFirstLetter } from "../../../generic";

const USPs = [
  {
    title: "Best prices",
    icon: <QualityIcon />,
  },
  {
    title: "Fastest delivery",
    icon: <TurnaroundsIcon />,
  },
  {
    title: "End-to-end visibility",
    icon: <VisibilityIcon />,
  },
  {
    title: "Dedicated Account Manager",
    icon: <ManagerIcon />,
  },
];

const Hero = ({ heroRef, handleScroll }) => {
  const { search } = useLocation();
  const requiredCategory = new URLSearchParams(search).get("category");
  const categoryReq = new URLSearchParams(search)
    .get("requirement")
    ?.toLowerCase();
  const requiremtCheck = ["greige", "denim", "hosiery"].includes(categoryReq);
  const requirement = requiremtCheck ? categoryReq : "greige";
  const requirements = {
    greige: Greige,
    denim: Denim,
    hosiery: Hoisery,
  };

  return (
    <section
      className="hero-wrapper hero-adwords"
      ref={heroRef}
      style={{
        backgroundImage: `url(${requirements[requirement]})`,
      }}
    >
      <div className="hero__content">
        <div className="hero__content_main">
          <div>
            <h1
              className={`hero__heading heading-style ${
                requiredCategory && "header"
              }`}
            >
              {`Buy ${capitalizeFirstLetter(requirement)}${
                requirement != "hosiery" ? " fabric" : " Yarn"
              } at best prices`}
            </h1>
            <div className="heroadwords__summary text-center">
              <p className="text-center text-white">
                At Locofast, we provide the largest collection of Fabrics at the
                best rates by leveraging technology and an established supplier
                network spread across the nation.
              </p>
            </div>
          </div>
          <div className="hero__usps">
            {USPs.map(({ title, icon }, index) => (
              <div className="hero__usps-item" key={index}>
                {icon}
                <span>{title}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="adwordsContainer__form">
        <Form />
      </div>
    </section>
  );
};

export default Hero;
