import React, { useEffect, useState } from "react";
import "./style.scss";
import {
  LOOM_TYPE_REVERSE,
  Navigate,
  PRINTED_REQUIREMENT_INDEX,
  cottonArray,
  rayonArray,
  requestLinkUrl,
} from "./base";
import arrow from "../../assets/images/chevron_bottom.svg";
import Slider from "react-slick";

const OrderCard = ({
  threadcount,
  construction,
  width,
  loomType,
  productName,
  productCategoryId,
  id,
  requirementType,
  totalQuotePrice,
  productHash,
  quantity,
  tat,
  minimumOrderQuantity,
  unitType,
  isLiveRateAvailable,
  weight,
  productSubCategoryIds,
}) => {
  const { x, y } = threadcount;
  const { epi, ppi } = construction;
  const arrayOptions = productCategoryId == 1 ? cottonArray : rayonArray;
  const index = Math.ceil(Math.random() * 4);
  const { shortName, unit } = unitType;
  const orderlinkData = {
    liveQualityOrder: true,
    liveRateQuoteId: id,
    productionSubType: requirementType,
    fabricCost: totalQuotePrice,
    deliveryType: 1,
    productName,
    productHash,
    quantity,
    unit,
    shortName,
    minQuantity: minimumOrderQuantity,
    maxQuantity: 1000000,
    tat,
  };
  const getQuotesData = {
    productionType: 1,
    epi: epi || "",
    ppi: ppi || "",
    x: x || "",
    y: y || "",
    weight: weight || "",
    loomType,
    width,
    productionSubType: productSubCategoryIds,
    queryCategoryId: productCategoryId,

    category: 2,
    quantity,
    name: productName,
    compositionType: "115",
    compositions: null,
    requirementType:
      requirementType && requirementType >= PRINTED_REQUIREMENT_INDEX
        ? PRINTED_REQUIREMENT_INDEX
        : requirementType,
    finishedFabricType:
      requirementType && requirementType >= PRINTED_REQUIREMENT_INDEX
        ? requirementType
        : "",
    liveRateQuoteId: id,
    isLiveRateQuality: true,
  };
  const orderlinkUrl = requestLinkUrl(
    `${Navigate.liveQualityCreateOrderConfirmDetails}`,
    orderlinkData
  );
  const getQuotesUrl = requestLinkUrl(
    `${Navigate.editspecifications}`,
    getQuotesData
  );
  return (
    <div className="order_card">
      <div className="orderCardImg">
        <img className="test" src={arrayOptions[index - 1]} alt="" />
      </div>
      <div className="specs-container">
        <div className="card_specifications">
          <div className="threadcountcontainer">
            <p className="threadcount">{x === y ? `${x}'s` : `${x} x ${y}`}</p>
          </div>
          <p className="specifications">{productName}</p>
          <p className="bar">|</p>
          <p className="specifications">{`${epi} x ${ppi}`}</p>
          <p className="bar">|</p>
          <p className="specifications">{`${width}"`}</p>
          <p className="bar">|</p>
          <p className="specifications">{LOOM_TYPE_REVERSE[loomType]}</p>
        </div>
      <div
        className="card_specifications"
        style={{ justifyContent: "space-between" }}
      >
        <a
          className="card_specifications"
          style={{ marginTop: 0, cursor: "pointer" }}
          href={`${process.env.REACT_APP_CUSTOMER_APP}${
            isLiveRateAvailable ? orderlinkUrl : getQuotesUrl
          }`}
          target="_blank"
          rel="noreferrer"
        >
          <p className="orderNow">
            {" "}
            {isLiveRateAvailable ? "Order now" : "Get quotes"}
          </p>
          <img src={arrow} className="arrowOrder" alt="arrow" />
        </a>
      </div>
      </div>
    </div>
  );
};
const Orders = ({ liveRates }) => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleChange = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleChange);

    return () => window.removeEventListener("resize", handleChange);
  });

  const settings = {
    dots: true,
    infinite: false,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    rows: 2,
    nextArrow: <></>,
    prevArrow: <></>,
  };

  return (
    <div className="productContainer-wrapper">
      <p className="title">5000+ options to choose from</p>
      <p className="subTitle">
        Explore fabrics like never before. Collections are curated with utmost
        care with special attention on quality, finish, colours and prints.{" "}
      </p>
      {width > 600 ? (
        <div className="card_wrapper">
          {liveRates?.records?.map((item, index) => (
            <OrderCard {...item} key={index} />
          ))}
        </div>
      ) : (
        <Slider {...settings} className="orderslider">
          {liveRates?.records.map((product, id) => (
            <OrderCard {...product} key={id} />
          ))}
        </Slider>
      )}
    </div>
  );
};
export default Orders;
