import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer";

import Awards from "../../components/Awards";

const MediaPage = ({ heroRef }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Locofast: Cloud Textile Factory | Media and Awards</title>
        <meta
          name="description"
          content="Locofast is the leading marketplace for fabrics. We provide a wide range of cotton, rayon, and sustainable, eco-friendly fabrics at the best price.

"
        />
      </Helmet>
      <Awards heroRef={heroRef} />
      <Footer />
    </>
  );
};

export default MediaPage;
