import { Field, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import { requestDemo } from "../../services/ApiService";
import LabelWithTick from "../RequestModal/Adwords/helper";
import FormError from "../RequestModal/FormError";
import greenTick from "../../assets/images/modal/tick.svg";
import "./styles.scss";
import { Select } from "antd";
import { materialData } from "./_base";
import "react-phone-number-input/style.css";
import Phone, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-input-2/lib/style.css";
const CategorySubmissionForm = ({
  closedText,
  submitText,
  _handleClose,
  setSubmitSuccess,
  isSubmitSuccessful,
  fillDetails,
  fabricCategory,
  fabricType,
  showDropdown = false,
  requirementFormClass = {},
  footerClass = {},
  formRef,
  formType = "others",
}) => {
  const [fabricCategoryId, setFabricCategoryId] = useState(null);
  const findFabricCategoryId = (fabricCategoryx) => {
    materialData?.fabricCategory?.map((item) => {
      if (item?.label == fabricCategoryx) {
        setFabricCategoryId(item?.value);
      }
    });
  };
  const INTERNATIONAL = "Bangladesh";
  const REQUIRED_QUANTITY_FOR_FORM = [
    "less than 3,000 m",
    "3,000 m to 5,000 m",
    "Above 5,000 m",
  ];
  const FORM_INITIAL_STATE = {
    name: "",
    email: "",
    contact: "",
    fabricCategory: fabricCategory || "",
    fabricType: fabricType || "",
    category: "",
    subCategory: "",
    message: "",
    quantity: "",
    companyName:""
  };

  const ValidationSchema = ({ isValidPhoneNumber }) =>
    Yup.object().shape({
      name: Yup.string()
        .matches(
          /^[a-zA-Z](?!.*\.\.)[a-zA-Z .]*[a-zA-Z]$/,
          "Enter a valid name"
        )
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("This field is required"),
        email: Yup.string()
        .email("Invalid email address")
        .test(
          "work-email",
          "Please enter your work email ID. Personal email ID(e.g Gmail, Yahoo, Outlook) are not allowed.",
          (value) => {
            if (!value) return true; // Skip validation if no email is provided
            const domain = value.split("@")[1];
            return domain !== "gmail.com" && domain !== "yahoo.com" && domain!=="outlook.com";
          }
        )
        .required("Email is required"),
      contact: Yup.string()
        .required("This field is required")
        .test((contactVal, context) => {
          let message = null;
          if (!isValidPhoneNumber(`${contactVal}`)) {
            message = "Enter a valid phone number";
          }
          return message
            ? context.createError({
                message: message,
              })
            : true;
        }),
      fabricCategory: Yup.string(),
      fabricType: Yup.string(),
      quantity: Yup.string(),
      companyName:Yup.string()
    });

  const { search } = useLocation();
  const location = new URLSearchParams(search).get("location");
  const formContentRef = useRef();
  const checkboxContainerRef = useRef();
  const FABRIC_CATEGORY = [
    {
      value: 1,
      label: "Cotton",
    },
    {
      value: 2,
      label: "Rayon",
    },
    {
      value: 3,
      label: "Polyester",
    },
    {
      value: 4,
      label: "Blends",
    },
    {
      value: 55,
      label: "Denim",
    },
    {
      value: 56,
      label: "Nylon",
    },
    {
      value: 57,
      label: "Sustainable",
    },
    {
      value: 58,
      label: "Linen",
    },
    {
      value: 59,
      label: "Others",
    },
  ];
  const FABRIC_CATEGORY_INTERNATIONAL = [
    "Cotton",
    "Linen",
    "Dobbies",
    "Denim",
    "Others",
  ];
  const FABRIC_TYPES = ["Greige", "Yarn dyed", "RFD", "Dyed", "Printed"];
  const handleSubmit = ({
    name,
    email,
    contact,
    fabricCategory,
    fabricType,
    category,
    subCategory,
    message,
    companyName,
    quantity
  }) => {
    let payload = { name, formType: formType };
    payload["contact"] = `${contact}`;
    if (email) {
      payload["email"] = email;
    }
    if (fabricCategory) {
      if (fabricCategory === "Others") {
        payload["fabricCategory"] = category;
      } else {
        payload["fabricCategory"] = fabricCategory;
      }
    }
    if(companyName){
      payload["companyName"] = companyName;
    }
    if(quantity){
      payload["requiredQuantity"] = quantity;
    }
    if (fabricType && fabricCategory != "Denim") {
      payload["fabricType"] = fabricType;
    }
    if (subCategory) {
      payload["message"] = `SubCategory - ${subCategory}`;
    }
    if (message.length) {
      payload["message"] = message;
    }
    requestDemo({
      ...payload,
    })
      .then(() => {
        setSubmitSuccess(true);
      })
      .catch((err) => {
        let msg =
          "Error occurred while submitting the request. Please try again.";
        if (typeof err === "string") {
          msg = `Following e${msg.substr(1)}\n${err}`;
        }

        alert(msg);
      });
  };
  useEffect(() => {
    findFabricCategoryId(fabricCategory);
  }, [fabricCategory]);
  return (
    <Formik
      innerRef={formRef}
      initialValues={FORM_INITIAL_STATE}
      validationSchema={ValidationSchema({ isValidPhoneNumber })}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        errors,
        touched,
        setFieldValue,
        values,
        setValues,
        handleChange,
      }) => {
        var fillDetailsValidation =
          values?.name != "" && values?.contact != "" && values?.email != "" && (formType=="supplier"? values?.companyName!="": true);
        var otherFormValidation =
          fillDetailsValidation &&
          values?.fabricType &&
          (values?.fabricCategory != ""
            ? values?.fabricCategory == "Others"
              ? values?.category != ""
              : true
            : false) &&
          (showDropdown && values?.fabricCategory != "Others"
            ? values.subCategory != ""
            : true) &&
          values?.quantity;
        var formValidation = fillDetails
          ? fillDetailsValidation
          : otherFormValidation || false;
        return (
          <div className={requirementFormClass + " requirementContainer "}>
            <div className={"requirementForm"}>
              {!isSubmitSuccessful ? (
                <div className="formContent" ref={formContentRef}>
                  <div className="field">
                    <label htmlFor="name" className="labelWidth">
                      <LabelWithTick
                        label="Name"
                        withValidation={true}
                        style={{ color: " #676773" }}
                        isValid={values?.name && !errors.name}
                        containerClass={"flex"}
                        labelClass={"label"}
                      />
                      <Field
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Enter name"
                      />
                    </label>
                    {touched.name && errors.name ? (
                      <FormError error={errors.name} errorClass="field-error" />
                    ) : null}
                  </div>
                  <div className="field-contact field">
                    <label htmlFor="contact" className="labelWidth">
                      <LabelWithTick
                        label="Mobile number"
                        withValidation={true}
                        style={{ color: " #676773" }}
                        isValid={
                          values?.contact && isValidPhoneNumber(values?.contact)
                        }
                        labelClass={"label"}
                      />

                      <div className="field-contact">
                        <Phone
                          onChange={(value) => {
                            setFieldValue("contact", value);
                          }}
                          value={values?.contact}
                          placeholder="Mobile number"
                          id="contact"
                          name="contact"
                          defaultCountry="IN"
                          type="tel"
                          international
                        />
                      </div>
                    </label>
                    {errors.contact ? (
                      <FormError
                        error={errors.contact}
                        errorClass="field-error"
                      />
                    ) : null}
                  </div>
                  {formType == "supplier" ? (
                    <div className="field">
                      <label htmlFor="name" className="labelWidth">
                        <LabelWithTick
                          label="Company name"
                          withValidation={true}
                          style={{ color: " #676773" }}
                          isValid={values?.companyName && !errors.companyName}
                          containerClass={"flex"}
                          labelClass={"label"}
                        />
                        <Field
                          type="text"
                          id="companyName"
                          name="companyName"
                          placeholder="Enter company name"
                        />
                      </label>
                      {touched.name && errors.name ? (
                        <FormError
                          error={errors.name}
                          errorClass="field-error"
                        />
                      ) : null}
                    </div>
                  ) : null}
                  <div className="field">
                    <label htmlFor="email" className="labelWidth">
                      <LabelWithTick
                        label="Work email ID"
                        withValidation={true}
                        style={{ color: " #676773" }}
                        isValid={values?.email && !errors.email}
                        labelClass={"label"}
                      />
                      <Field
                        type="email"
                        id="email"
                        name="email"
                        placeholder="name@company.com"
                      />
                    </label>

                    {touched.email && errors.email ? (
                      <FormError
                        error={errors.email}
                        errorClass="field-error"
                      />
                    ) : null}
                  </div>

                  {formType == "buyingHouse" && (
                    <div className="field">
                      <LabelWithTick
                        label="Message"
                        withValidation={false}
                        style={{ color: " #676773" }}
                        labelClass={"label"}
                      />

                      <textarea
                        type="text"
                        id="message"
                        name="message"
                        placeholder="Leave a message"
                        rows={4}
                        maxLength={501}
                        onChange={handleChange}
                      />
                    </div>
                  )}
                  {!fillDetails ? (
                    <div className="field">
                      <LabelWithTick
                        label="Fabric category"
                        withValidation={true}
                        style={{ color: " #676773" }}
                        isValid={values?.fabricCategory != ""}
                        labelClass={"label"}
                      />
                      <div
                        role="group"
                        className="checkbox-group"
                        aria-labelledby="checkbox-group"
                        ref={checkboxContainerRef}
                      >
                        {(location === INTERNATIONAL
                          ? FABRIC_CATEGORY_INTERNATIONAL
                          : FABRIC_CATEGORY
                        ).map((type) => (
                          <label key={type.value} className="radioButton">
                            <Field
                              type="radio"
                              name="fabricCategory"
                              value={type.label}
                              onChange={(event) => {
                                findFabricCategoryId(event.target.value);
                                setValues({
                                  ...values,
                                  fabricCategory: event.target.value,
                                  subCategory: "",
                                });
                              }}
                              checked={values?.fabricCategory === type.label}
                            />
                            <span>{type.label}</span>
                          </label>
                        ))}
                      </div>
                      {values?.fabricCategory === "Others" && (
                        <Field
                          type="text"
                          id="category"
                          name="category"
                          placeholder="Enter category"
                        />
                      )}
                    </div>
                  ) : null}
                  {!fillDetails && fabricCategory != "Denim" ? (
                    <div className="field">
                      <LabelWithTick
                        label="Requirement"
                        withValidation={true}
                        style={{ color: " #676773" }}
                        isValid={values?.fabricType != ""}
                        labelClass={"label"}
                      />
                      <div
                        role="group"
                        className="checkbox-group"
                        aria-labelledby="checkbox-group"
                      >
                        {FABRIC_TYPES.map((type) => (
                          <label key={type} className="radioButton">
                            <Field
                              type="radio"
                              name="fabricType"
                              value={type}
                              onChange={(event) => {
                                setFieldValue("fabricType", event.target.value);
                              }}
                              checked={values?.fabricType === type}
                            />
                            <span>{type}</span>
                          </label>
                        ))}
                      </div>
                    </div>
                  ) : null}
                     {showDropdown ? (
                    <div className="field" style={{marginBottom:24}}>
                      <LabelWithTick
                        label="Subcategory"
                        withValidation={true}
                        style={{ color: " #676773" }}
                        isValid={values?.subCategory != ""}
                        labelClass={"label"}
                      />
                      <div className="dropdownField">
                        <Select
                          onChange={(x, r) => {
                            setFieldValue("subCategory", r.label);
                          }}
                          style={{ width: "100%", height: 42 }}
                          key={"label"}
                          options={
                            materialData?.fabricSubCategory[fabricCategoryId]
                          }
                          placeholder="Select a sub category"
                          value={values?.subCategory || undefined}
                          defaultValue={values?.subCategory}
                        />
                      </div>
                    </div>
                  ) : null}
                  {!fillDetails ? (
                    <div className="field">
                      <LabelWithTick
                        label="Quantity required"
                        withValidation={true}
                        style={{ color: " #676773" }}
                        isValid={values?.quantity != ""}
                        labelClass={"label"}
                      />
                      <div
                        role="group"
                        className="checkbox-group"
                        aria-labelledby="checkbox-group"
                        // ref={quantityRef}
                      >
                        {REQUIRED_QUANTITY_FOR_FORM.map((type) => (
                          <label key={type} className="radioButton">
                            <Field type="radio" name="quantity" value={type} />
                            <span>{type}</span>
                          </label>
                        ))}
                      </div>
                    </div>
                  ) : null}
               
                </div>
              ) : (
                <div className="successful_container">
                  <div>
                    <div className="tickImg">
                      <img src={greenTick} alt="icon" />
                    </div>

                    <p className="successTitle">Thank you for your interest!</p>
                    <p className="successSubTxt" style={{ marginBottom: 40 }}>
                      We have received your request. One of our experts will
                      reach out to you in 24 hours.
                    </p>
                    <p className="successSubTxt">
                      For urgent requests, please call us on{" "}
                    </p>
                    <p
                      className="successTitle"
                      style={{ marginTop: 8, fontSize: 16, marginBottom: 52 }}
                    >
                      +91 89203 92418
                    </p>
                  </div>
                </div>
              )}
              {!isSubmitSuccessful ? (
                <div className={"flex-center submit__container " + footerClass}>
                  <button
                    className="cross"
                    type="button"
                    onClick={() => _handleClose()}
                  >
                    {closedText}
                  </button>

                  <button
                    type="submit"
                    className="btn btn-primary buttonWidth"
                    value="submit"
                    onClick={() => handleSubmit(values)}
                    disabled={
                      !(formValidation && !Boolean(Object.keys(errors).length))
                    }
                  >
                    {submitText}
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        );
      }}
    </Formik>
  );
};
export default CategorySubmissionForm;
