import React from "react";
import "react-perfect-scrollbar/dist/css/styles.css";
import Scrollbar from "react-perfect-scrollbar";
import "./style.scss";

const PrivacyPolicy = () => {
  return (
    <div className="privacy__policy">
      <Scrollbar className="div">
        <p>
          The Company is concerned and committed to protecting and maintaining
          the privacy of all its Users and has published this privacy policy
          statement (the “<b>Privacy Policy</b>” or “<b>PP</b>”) to familiarize
          You with the manner in which the Company collects, stores, uses,
          discloses, alters, and/or deletes Your personally identifiable
          information (“<b>PII</b>”) collected during your usage of the Locofast
          Services. Any capitalised terms used but not defined in this PP shall
          have the meaning ascribed to such term in the Terms and Conditions
          given above.
        </p>
        <ol type="i">
          <li>
            <p>
              <b>Purpose</b>
            </p>
            <ol>
              <li>
                <p>The purpose of this PP is to ensure that:</p>
                <ol type="a">
                  <li>
                    <p>
                      You are aware of and have complete control over what
                      Customer Data you provide to the Company, and how the same
                      is and will be used by the Company;
                    </p>
                  </li>
                  <li>
                    <p>
                      You are at all times aware of the rights available to you
                      with respect to your Customer Data;
                    </p>
                  </li>
                  <li>
                    <p>
                      The information provided by you is processed, stored, and
                      used in accordance with the applicable laws, including
                      with respect to maintaining appropriate data protection
                      and privacy infrastructure.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  We insist upon the highest standards for securing Customer
                  Data and maintaining customer information privacy.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              <b>Information We Collect</b>
            </p>
            <ol>
              <li>
                <p>
                  We have already specified (under points 4 and 5 of the ToS)
                  the kind of Customer Data (PII and otherwise) that we require,
                  collect, access, and store during the period of account
                  creation and thereafter during your usage of the Locofast
                  Services. Both You and your End-Users (through you) have the
                  right, at any time, to choose not to provide us with any of
                  this information and to ask us to delete the same, if
                  required. However, doing so may result in the subsequent
                  suspension/hampering of all or a part of the Locofast
                  Services.
                </p>
              </li>
              <li>
                <p>
                  <u>Cookies</u>: Cookies are pieces of information that are
                  stored on Your computer when the You accesses the Platform.
                  The Platform may use temporary cookies to help you access some
                  of the special functions within the database driven areas of
                  the Platform. Once you leave our Platform, these cookies
                  expire. Cookies do not collect personal identifying
                  information about the user.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              <b>Information Storage and Security</b>
            </p>
            <ol>
              <li>
                <p>
                  All the information that we collect from You during your usage
                  of Locofast is stored on third-party servers, located in
                  United States of America and India. We access the information
                  on the cloud. Our third-party service provider uses globally
                  accepted standards for ensuring and maintaining the
                  protection, security, confidentiality, and privacy of all data
                  on its servers.
                </p>
              </li>
              <li>
                <p>
                  We work hard to protect You from unauthorized access or
                  unauthorized alteration, disclosure or destruction of any
                  Customer Data we hold. Pursuant to the same, we have
                  implemented globally accepted standards and procedures to
                  maintain ongoing data protection and privacy by ensuring the
                  following:
                </p>
                <ol type="a">
                  <li>
                    <p>
                      The encryption of all data (both Customer Data and
                      Locofast Content) using secure server software, which is
                      among the best software available today for secure
                      transactions.
                    </p>
                  </li>
                  <li>
                    <p>
                      The pseudonymization of all data collected by us, by
                      ensuring that all PII is kept/stored separately from all
                      the remaining information, to ensure that the remaining
                      data cannot be attributed to an identified or identifiable
                      natural person, unless actively merged or joined with the
                      PII;
                    </p>
                  </li>
                  <li>
                    <p>
                      The constant review of our information collection, storage
                      and processing practices, including this ToS, PP, and the
                      physical security measures to guard against unauthorized
                      access to systems;
                    </p>
                  </li>
                  <li>
                    <p>
                      The constant upgradation and/or alteration of our policies
                      as may be necessary to ensure the ongoing confidentiality,
                      protection, privacy, security, and accessibility of all
                      data and systems;
                    </p>
                  </li>
                  <li>
                    <p>
                      Limiting the disclosure of PII to Our employees,
                      independent contractors including vendors, affiliates,
                      consultants, business associates, service providers and
                      distributors of Our services, only on a&nbsp;
                      <i>“need-to-know”</i>&nbsp;basis, and only if the
                      disclosure will enable that person/entity to provide Us
                      with business, professional, or technical support or
                      fulfill Your request and requirements under the services.
                    </p>
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <p>
              <b>Information We Share</b>
            </p>
            <ol>
              <li>
                <p>
                  In addition to any third-party service providers who, subject
                  to your prior consent, we may share some or all parts of the
                  Customer Data with (as is captured under point 6 of the ToS),
                  we may disclose any information provided by You on the
                  Platform as may be deemed to be necessary or appropriate: (a)
                  under applicable law, including laws outside Your country of
                  residence; (b) to comply with legal process; (c) to respond to
                  requests from public and government authorities including
                  public and government authorities outside Your country of
                  residence; (d) to enforce Our Terms of Use; or (e) to allow Us
                  to pursue available remedies or limit the damages that We may
                  sustain.
                </p>
              </li>
              <li>
                <p>
                  By agreeing to this PP, you also explicitly understand and
                  consent to the fact that we may share Customer Data with other
                  corporate entities and affiliates only for the purposes of:
                </p>
                <ol type="a">
                  <li>
                    <p>
                      Using their assistance to detect and prevent identity
                      theft, fraud and other potentially illegal acts; or
                    </p>
                  </li>
                  <li>
                    <p>
                      Correlating related or multiple accounts to prevent abuse
                      of the Locofast services
                    </p>
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <p>
              <b>Rights Available to You</b>
            </p>
            <ol>
              <li>
                <p>
                  Once you start using the Locofast Services, both you and your
                  End-Users (together the “Data Subjects”) shall have the below
                  mentioned rights. If any of your End-Users wish to exercise
                  the below rights, it will be your responsibility to receive
                  the relevant written request from them, and then
                  communicate/transfer the same to us so that we may take the
                  appropriate action:
                </p>
                <ol type="a">
                  <li>
                    <p>
                      Delete/erase all PII (or all data) pertaining to a Data
                      Subject that we may have on our systems. This provision
                      will not apply to any data or PII that we may need to
                      retain pursuant to any applicable law or any
                      request/requirement of a public/government body, whether
                      in your country of residence or not;
                    </p>
                  </li>
                  <li>
                    <p>
                      Rectify, modify, or alter any of the PII or other data
                      pertaining to a Data Subject, if you have identified any
                      mistake, error or inaccuracy in the same.
                    </p>
                  </li>
                  <li>
                    <p>
                      Restrict or limit the manner and ways in which a
                      particular Data Subjects information/data is processed or
                      used by us;
                    </p>
                  </li>
                  <li>
                    <p>
                      Export and provide a Data Subject with a copy of all
                      his/her data, including the PII, that we may have on our
                      servers. This includes all data and server logs pertaining
                      to activities and behaviour on the Platform as well.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  We will make all commercially reasonable best efforts to
                  comply with all of the above requests (when received) as soon
                  as possible. If as a Customer, you wish to exercise any of
                  your above rights, or if you wish to convey a request on
                  behalf of an End-User, kindly send an email to
                  mail@locofast.com.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              <b>Change in Privacy Policy</b>
            </p>
          </li>
        </ol>
        <p>
          You acknowledge that becoming a user of the Locofast Services
          signifies your assent to this PP. In the event there are any changes
          to this PP or in the way we treat any Customer Data, we shall intimate
          you of the same via email at least 1 week prior to the enforcement of
          such change. We will also display a notice on the Platform regarding
          the above. If after your receipt of a notice of change to the ToS or
          PP you do not object to the same and/or continue to use the Locofast
          Services, you will be assumed to have provided your consent to the
          changes.
        </p>
        <ol type="i" start="7">
          <li>
            <p>
              <b>Choice/Opt-Out</b>
            </p>
          </li>
        </ol>
        <p>
          As stated previously, we provide all Customers with the option and
          choice of opting-out of receiving certain third-party services and/or
          the Locofast Services in general, at any time.
        </p>
        <ol type="i" start="8">
          <li>
            <p>
              <b>Questions</b>
            </p>
          </li>
        </ol>
        <p>
          Questions regarding this Privacy Policy should be directed to the
          following email ID: mail@Locofast.com
        </p>
        <ol type="i" start="9">
          <li>
            <p>
              <b>Disclaimer</b>
            </p>
            <ol>
              <li>
                <p>
                  We shall not be liable for any loss or damage sustained by
                  reason of any disclosure (inadvertent or otherwise) of any
                  Customer Data, if the same is either (a) required under point
                  4.1 above; or (b) was effected through no fault, act, or
                  omission of the Company.
                </p>
              </li>
              <li>
                <p>
                  We reserve our right to offer our services to any other
                  client/prospective client without restriction.
                </p>
              </li>
              <li>
                <p>
                  By registering or by using the Platform, you explicitly
                  accept, without limitation or qualification, the collection,
                  use and transfer of the Customer Data in the manner described
                  herein.
                </p>
              </li>
              <li>
                <p>
                  Please read this Privacy Policy carefully as it affects your
                  rights and liabilities under law.
                </p>
              </li>
            </ol>
          </li>
        </ol>
        <p>
          Any grievances related to any kind of personal information or data,
          sensitive personal information or data or this privacy policy may be
          addressed to our Grievance Officer at the following coordinates:
        </p>
        <p>Name: Mohit Piplani</p>
        <p>
          Email: <a href="mailto:mohit@locofast.com">mohit@locofast.com</a>
        </p>
        <p>
          <br />
        </p>
      </Scrollbar>
    </div>
  );
};

export default PrivacyPolicy;
