import React, { useEffect, useCallback } from "react";
import close from "../../assets/images/modal/close.svg";
import "./style.scss";

const Modal = ({ _handleClose, show, children, title, modalClass,showCross=true,modalBody }) => {
  let modalClasses = "modal display-none";
  if (show) {
    modalClasses = "modal";
  }

  const escFunction = useCallback(
    (event) => {
      if (show && event.keyCode === 27) {
        _handleClose(event);
      }
    },
    [show, _handleClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  return (
    <div className={modalClasses}>
      <div className={"modal__content " + modalClass}>
        <div className="modal__header">
          <h1>{title}</h1>
     {showCross ?  <img
            src={close}
            className="modal__close"
            alt="Close"
            onClick={_handleClose}
          /> :null}
        </div>
        <div className={"modal__body" + modalBody}>{children}</div>
        <div>
          
        </div>
      </div>
    </div>
  );
};

export default Modal;
