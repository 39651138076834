export const APP_ROUTE = "/";
export const SUPPLIERS_ROUTE = "/suppliers";
export const SUPPLIERS_LOGIN_ROUTE = "/";
export const ABOUT_US_ROUTE = "/about-us";
export const CAREERS_ROUTE = "/customers";
export const WORKWITHUS_ROUTE = "/workwithus";
export const FAQ_ROUTE = "/faq";
export const MEDIA_ROUTE = "/media";
export const SWATCH_ROUTE = "/swatchbox";
export const ADWORDS_ROUTE = "/demand/fabric-request";
export const SUSTAINABILITY_ROUTE = "/sustainability";
export const BLOG_ROUTE = "/blog/";
export const PRIVACY_POLICY_ROUTE = "/policies/privacy";
export const SUPPLIER_PROFILE = "/suppliers/:suppType/:suppState/:suppName/id=:id";
export const LINK_PAGE = "/linkPage";
