import React from "react";

import DyeingImg from "../../assets/images/platform/dyeing-min.png";
import PrintingImg from "../../assets/images/platform/printing.png";
import TestingImg from "../../assets/images/infrastructure/testing.jpg";
import WeavingImg from "../../assets/images/platform/weaving.png";

import wDyeingImg from "../../assets/images/platform/webp/dyeing-min.webp";
import wPrintingImg from "../../assets/images/platform/webp/printing.webp";
import wTestingImg from "../../assets/images/infrastructure/webp/testing.webp";
import wWeavingImg from "../../assets/images/platform/webp/weaving.webp";

import WebpImage from "../Common/WebpImage";

const SERVICES = [
  {
    image: WeavingImg,
    webpImage: wWeavingImg,
    title: "Weaving",
    description:
      "Finest weaving techniques for the perfect outcome such as plain, basket, twill, satin, jacquard, leno, pile, etc.",
  },
  {
    image: PrintingImg,
    webpImage: wPrintingImg,
    title: "Printing",
    description:
      "High-quality direct, discharge, resist or transfer printing with techniques like block, blotch, burn-out, roller, and screen printing to make your designs come out alive.",
  },
  {
    image: DyeingImg,
    webpImage: wDyeingImg,
    title: "Dyeing",
    description:
      "Latest dyeing techniques and methods used to dye fibre, yarn or fabric, such as solution, stock, beam, etc. - the most-advanced in the industry.",
  },
  {
    image: TestingImg,
    webpImage: wTestingImg,
    title: "Testing",
    description:
      "Our elaborate testing capabilities are computerized and deliver accurate monitoring, defect classification, and prevention, and ensure 100% quality assurance to our customers.",
  },
];

const Services = () => (
  <section className="servicesSection">
    <h3 className="section__heading text">Comprehensive array of services</h3>
    <p className="section__subheading subtext">
      360-degree solutions to cater to any requirement of basic greige or
      finished fabrics. We do it all. Our infrastructure enables us to deliver
      consistent quality and production outcomes, across a wide range of product
      types.
    </p>
    <div className="services content">
      {SERVICES.map(({ image, webpImage, title, description }, index) => (
        <div className="services__item" key={index}>
          <WebpImage imgSrc={image} webpSrc={webpImage} />
          <div className="box">
            <div className="section__item_heading">{title}</div>
            <div className="section__item_subheading">{description}</div>
          </div>
        </div>
      ))}
    </div>
  </section>
);

export default Services;
