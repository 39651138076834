import React from "react";
import "./style.scss";
import iconarrow from "../../assets/images/hero/Vector-blue.svg";
import { APP_ROUTE } from "../../constants";
import { Link } from "react-router-dom";
function ErrorUI() {
  return (
    <div className="main-screen">
      <div className="container404">
        <h1 className="heading">404</h1>
        <p className="description">
          The page you are looking at does not exist
        </p>
        <Link className="btn404" to={APP_ROUTE}>
          Back to home
          <img className="arrow" src={iconarrow} alt="icon" />
        </Link>
      </div>
    </div>
  );
}

export default ErrorUI;
