import React from "react";
import "./style.scss";
import right from "../../assets/images/category/chevron_right.svg";
import { CATEGORY } from "./config";

const Category = () => {
  return (
    <div className="category">
      <div className="row">
        {CATEGORY.map(({ source, img, text }, id) => (
          <div className="column" key={id}>
            <a href={source} target="_blank" rel="noreferrer">
              <img src={img} alt="cotton" loading="lazy" className="image"/>
            </a>
            <div className={`text ${text}`}>
              {text}
            </div>
              <div className="arrow">
                <img src={right} alt="right" />
              </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Category;
