import React, { useState } from "react";
import "./style.scss";
import collab from "../../assets/images/feature/collab.svg";
import { COLLABORATION_INFO } from "../Compliance/config";
import LogoBlue from "../../assets/images/logo-blue.svg";
import Logo from "../../assets/images/logoo-blue.svg";
import close from "../../assets/images/feature/close.svg";
import clothesLogo from "../../assets/images/feature/clothesLogo.svg";

const Collaboration = () => {
  const [hover, setHover] = useState({ hover: null });
  return (
    <div className="collaboration">
      <div className="collaboration__first">
        <div className="header">
          <img src={collab} alt="collab-img" className="image" />
          <div className="heading">Collaboration</div>
        </div>
        <div className="header-info">
          We engage with entities that are conscious about their impact on the
          society and the environment at large, the motivation behind these
          collaborations is to together achieve our communal goal of betterment
          of society and working towards creating a better place for our
          progeny. In our humble attempt to give back, we organised a donation
          drive of pre-loved clothes at the office and also donated fabric to
          <b> Clothes Box Foundation</b>, a platform involved in the growth of
          the underprivileged. We are overjoyed by the smiles we were able to
          spread and look forward to create more impact.
        </div>
      </div>
      <div className="collaboration__second">
        <div className="box">
          <img className="logoo" src={Logo} alt="Logo" />
          <img className="logo-blue" src={LogoBlue} alt="Logo" />
          <img className="close" src={close} alt="close" />
          <img className="clothes-logo" src={clothesLogo} alt="clothes-logo" />
        </div>
        <div className="images">
          {COLLABORATION_INFO.map(({ img, hoverImg }, id) => {
            return (
              <img
                src={hover.hover === id ? hoverImg : img}
                alt="certificate"
                className="image"
                key={id}
                onMouseOver={() => setHover({ hover: id })}
                onMouseLeave={() => setHover({ hover: null })}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Collaboration;
