import React from "react";
import Slider from "react-slick";
import "./style.scss";
import community from "../../assets/images/feature/community.svg";
import { ROW, settings } from "./config";
import triangle from "../../assets/images/feature/triangle.svg";

const CommunitySection = () => {
  return (
    <div className="community">
      <div className="community__first">
        <div className="header">
          <img src={community} alt="community-img" className="image" />
          <div className="heading">Community</div>
        </div>
        <div className="header-info">
          Understanding the impact of textiles on our community, our practices
          of ethical sourcing are aimed to create a better world
        </div>
      </div>
      <div className="community__second">
        {ROW.map(({ text, subtext, images }, id) => (
          <div className={id === 0 ? "row" : "row row2"} key={id}>
            <div className="left">
              <div className="text">{text}</div>
              <div className="subtext">{subtext}</div>
            </div>
            <div className="right">
              <Slider
                {...settings}
                className="slider"
                customPaging={(i) => (
                  <div className="custom-dots-box">
                    <img src={triangle} alt="triangle" className="triangle" />
                    <img src={images[i]} alt="icon" className="dots" />
                  </div>
                )}
              >
                {images.map((image, id) => (
                  <img
                    src={image}
                    alt="icon"
                    key={id}
                    className="image"
                    loading="lazy"
                  />
                ))}
              </Slider>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CommunitySection;
