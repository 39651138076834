import React, { useEffect, useState } from "react";
import arrowopen from "../../assets/images/testimonial/QuoteOpen.svg";
import arrowclose from "../../assets/images/testimonial/QuoteClose.svg";
import "./styles.scss";
const BenefitCard = ({
  icon,
  title,
  subtitle,
  description,
  isAdwords = false,
  starImg,
}) => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleChange = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleChange);

    return () => window.removeEventListener("resize", handleChange);
  });
  return (
    <div>
      <div className="imageBox">
        <img src={icon} alt="icon" loading="lazy" className="main_Img" />
      </div>
      <div className={`carrd  ${isAdwords && "adwordcard"}`}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "85%",
            left: 100,
            position: "relative",
            flexDirection: width > 768 ? "row" : "column-reverse",
          }}
        >
          <div className={`details ${isAdwords && "adworddetails"}`}>
            <p className="name">{title}</p>
            <p className="sname">{subtitle}</p>
          </div>
          <div className="starContainer">
            <img src={starImg}  alt="icon" />
          </div>
        </div>
          <div style={{display:'flex',flexDirection:'row',marginTop:24}}>
          <div
              className="testinmonialArrowContainer"
              style={{  marginRight: 16,alignItems:'start'}}
            >
              <img src={arrowopen} alt="icon" />
            </div>
            <div className="testimonialDescription">
                 <p>   {description}</p>
              </div>
         
              <div
                className="testinmonialArrowContainer"
                style={{ marginLeft: 16,alignItems:'end' }}
              >
                <img src={arrowclose} alt="icon" />
              </div>
          </div>
      </div>
    </div>
  );
};
export default BenefitCard;
