import React, { useEffect, useState } from "react";
import Griege from "../../../assets/components/SupplierProfile/Griege.webp";
import YarnDyed from "../../../assets/components/SupplierProfile/YarnDyed.webp";
import RFD from "../../../assets/components/SupplierProfile/RFD.webp";
import Denim from "../../../assets/components/SupplierProfile/Denim.webp";
import GriegeMV from "../../../assets/components/SupplierProfile/GriegeMv.webp";
import YarnMV from "../../../assets/components/SupplierProfile/YarnMv.webp";
import RFDMV from "../../../assets/components/SupplierProfile/RFDMv.webp";
import DenimMv from "../../../assets/components/SupplierProfile/DeminMv.webp";
import "./styles.scss";
const Hero = ({ productionTypes = {} }) => {
  const [showMobileView, setShowMobileView] = useState(false);
  useEffect(() => {
    const handleChange = () => {
      setShowMobileView(window.innerWidth < 576);
    };
    window.addEventListener("resize", handleChange);
    handleChange();
    return () => window.removeEventListener("resize", handleChange);
  }, []);
  const getIcon = (status) => {
    const imagePaths = {
      1: showMobileView ? GriegeMV : Griege,
      2: showMobileView ? YarnMV : YarnDyed,
      3: showMobileView ? RFDMV : RFD,
      4: showMobileView ? RFDMV : RFD,
      5: showMobileView ? RFDMV : RFD,
      6: showMobileView ? DenimMv : Denim,
    };
    return imagePaths[status] || "";
  };
  const status = productionTypes[0]?.id;
  return (
    <div className="supplierProfileHero">
      <img src={getIcon(status)} alt="icon" width={"100%"} height={"100%"} />
    </div>
  );
};
export default Hero;
