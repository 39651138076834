import React, { useState } from "react";
import "./style.scss";
import { Cotton, PRODUCT, productCategory } from "./_base";
import arrowLeft from "../../assets/images/arrowLeft.svg";
import arrowRight from "../../assets/images/chevron_bottom.svg";
import DesignSection from "../DesignSection";
import ProductSelectionSlider from "./ProductSelectionSlider";
import tick from "../../assets/images/aboutus/Check.svg";
const ProductSelection = () => {
  const [selectedFabric, setSelectedFabric] = useState({
    key: "Cotton",
    value: PRODUCT?.Cotton,
  });
  const [selectedProductTpye, setSelectedProductType] = useState("Greige");
  const sliderId = `id-${Math.ceil(Math.random() * 1000)}`;
  return (
    <div className="productSelection_container">
      <h1 className="productTitle">Which products are you looking to buy?</h1>
      <div>
        <div className="productBox">
          <div className="subContainer">
            {Object.keys(PRODUCT).map((item, index) => {
              return (
                <div
                  className={`${
                    item == selectedFabric?.key
                      ? "selectedState selectedProductItem "
                      : "productItem"
                  }`}
                  onClick={() => {
                    setSelectedFabric({
                      key: item,
                      value: PRODUCT[item],
                    });
                    setSelectedProductType("Greige")
                  }}
                  key={index}
                >
                  {item}
                </div>
              );
            })}
          </div>
        </div>
        <div className="categoryBox">
          <div className="subCategoryBox">
            {Object.values(productCategory).map((item, index) => {
              return selectedFabric?.key != "Denim" ? (
                <div
                  className={` ${
                    selectedProductTpye == item
                      ? "selectedProductCategoryBox selectedProductCategoryBoxTxt "
                      : "productCategoryBox"
                  }`}
                  key={index}
                  onClick={() => setSelectedProductType(item)}
                >
                  {selectedProductTpye == item && (
                    <div style={{ marginRight: 8, marginTop: 2 }}>
                      <img src={tick} alt="icon"/>
                    </div>
                  )}
                  {item}
                </div>
              ) : null;
            })}
          </div>
        </div>
        <div key={sliderId}>
          <ProductSelectionSlider
            selectedFabric={selectedFabric}
            selectedProductType={selectedProductTpye}
          />
        </div>
      </div>
    </div>
  );
};
export default ProductSelection;
