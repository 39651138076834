import img1 from "../../assets/images/box/1.jpg";
import img2 from "../../assets/images/box/2.jpg";
import img3 from "../../assets/images/box/3.jpg";
import img4 from "../../assets/images/box/4.jpg";
import img5 from "../../assets/images/box/5.jpg";
import img6 from "../../assets/images/box/6.jpg";
import img7 from "../../assets/images/box/7.jpg";
import img8 from "../../assets/images/box/8.jpg";
import img10 from "../../assets/images/box/10.jpg";
import img11 from "../../assets/images/box/11.jpg";
import img12 from "../../assets/images/box/12.jpg";
import img13 from "../../assets/images/box/13.jpg";
import img14 from "../../assets/images/box/14.jpg";
import img15 from "../../assets/images/box/15.jpg";
import img16 from "../../assets/images/box/16.jpg";
import img17 from "../../assets/images/box/17.jpg";

export const SwatchImages = [
  [img1, img5],
  [img2, img6],
  [img3, img7],
  [img4, img8],
];
export const SwatchImagesMobile = [
  {
    image: img1,
  },
  {
    image: img2,
  },
  {
    image: img3,
  },
  {
    image: img4,
  },
  {
    image: img5,
  },
  {
    image: img6,
  },
];
export const SwatchImagesRight = [
  [img10, img14],
  [img11, img15],
  [img12, img16],
  [img13, img17],
];
export const SwatchImagesMobileRight = [
  {
    image: img12,
  },
  {
    image: img13,
  },
  {
    image: img14,
  },
  {
    image: img15,
  },
  {
    image: img16,
  },
  {
    image: img17,
  },
];
