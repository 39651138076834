import React from "react";
import { useLocation } from "react-router-dom";
import Bubble from "../../assets/images/testimonial/quote.svg";
import { INTERNATIONAL } from "../RequestModal/Adwords/Form";

const CustomerNote = ({ isBottom = false }) => {
  const { search } = useLocation();
  const location = new URLSearchParams(search).get("location");
  return (
    <div className={`customerNote ${isBottom && "customerNoteBottomSheet"}`}>
      <img src={Bubble} alt="" />
      <div>
        The pace at which Locofast works is really {location === INTERNATIONAL ? "exceptional" : "commendable"} –{" "}
        <b>{`${
          location === INTERNATIONAL ? "Mahbub Alam" : "Sukhvir Singh,"
        }`}</b>{" "}
        <span className="text-light">{`${
          location === INTERNATIONAL ? "Galpex limited" : "HBR apparels"
        }`}</span>
      </div>
    </div>
  );
};

export default CustomerNote;
