import React from "react";

import Mission from "../../assets/images/aboutus/Mission.svg";

import "./styles.scss";

const TextileFutureSection = () => {
  return (
    <>
      <div className="textile">
        <div className="textile__mapWrapper">
          <img src={Mission} alt="map" />
        </div>

        <div className="textile__contentWrapper">
          <div className="content__heading">Our Mission</div>
          <div className="content__description">
            To build an ecosystem for textile manufacturers to conduct
            hassle-free business from anywhere across the world.
          </div>
          <div className="content__heading">Our Vision</div>
          <div className="content__description">
            To reshape the textile industry by leveraging technology and
            enabling textile tech to our partners to scale their business
            globally.
          </div>
        </div>
      </div>
    </>
  );
};

export default TextileFutureSection;
