import React, { useState } from "react";
import { useEffect } from "react";
import { MINIMUM_DESKTOP_WIDTH } from "./config";
import ProductCarousel from "./ProductCarousel";
import "./style.scss";

const ProductCard = ({ img, fabric, price, colors, link, images }) => {
  const [hover, setHover] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const isDesktopView = width > MINIMUM_DESKTOP_WIDTH;
  useEffect(() => {
    const handleChange = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleChange);

    return () => window.removeEventListener("resize", handleChange);
  });
  return (
    <div
      className="productCard"
      onClick={() => window.open(link)}
      {...(isDesktopView && {
        onMouseOver: () => setHover(true),
        onMouseLeave: () => setHover(false),
      })}
    >
      {hover ? (
        <ProductCarousel images={images} />
      ) : (
        <img src={img} alt="product" loading="lazy" className="product-image" />
      )}
      <div className="fabric">{fabric}</div>
      <div className="price">{price}</div>
      <div className="row">
        {colors.map(({ color }, id) => (
          <div
            className="color"
            style={{ backgroundColor: color }}
            key={id}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default ProductCard;
