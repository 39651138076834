import config from "../config";

const REQUEST_DEMO_URL = `${config.apiUrl}/api/requestDemo`;
const LIVE_RATES=`${config.liveRatesUrl}/live-rate/fabric-quotes`;
const MATERIAL_DATA=`${config.materialApiUrl}/api/material/options?types[]=fabricCategory&types[]=fabricSubCategory&types[]=certification&types[]=currency&types[]=unitByType`;
const SUPPLIER_DATA=`${config.supplierDataUrl}/apifabric/suppliers/details/`;
const SUPPLIER_LISTING=`${config.supplierDataUrl}/apifabric/suppliers/organisation/list/`;

export const requestDemo = (formData) => {
  return fetch(REQUEST_DEMO_URL, {
    method: "POST",
    body: JSON.stringify(formData),
  }).then((res) => {
    if (res.status !== 200) {
      return Promise.reject("Request failed");
    }

    return res.json();
  });
};
export const liveRatesApi=(formData)=>{
  return fetch(LIVE_RATES,{
    method:"GET",
    headers : {
      'recommendation-engine-access-key':process.env.REACT_APP_RECOMMENDATION_ENGINE_ACCESS_KEY,
    },
   params: JSON.stringify(formData)
  })
  .then((res) => {
    if (res.status !== 200) {
      return Promise.reject("Request failed");
    }

    return res.json();
  });
}
export const materialApi=(optionType)=>{
  return fetch(MATERIAL_DATA,{
    method:'GET' ,
    params:{
      // types: optionType,
      "rr": "Rr"
    }
  })
  .then((res) => {
    if (res.status !== 200) {
      return Promise.reject("Request failed");
    }

    return res.json();
  });
}

export const supplierDataApi=(formData)=>{
  return fetch(`${SUPPLIER_DATA}${formData}`,{
    headers : {
      'fabric-access-key':process.env.REACT_APP_FABRIC_ACCESS_KEY,
    },
  })
  .then((res) => {
    if (res.status !== 200) {
      return Promise.reject("Request failed");
    }
    return res.json();
  });
}
export const supplierListingApi=()=>{
  return fetch(`${SUPPLIER_LISTING}`,{
    headers : {
      'fabric-access-key':process.env.REACT_APP_FABRIC_ACCESS_KEY,
    },
  })
  .then((res) => {
    if (res.status !== 200) {
      return Promise.reject("Request failed");
    }
    return res.json();
  });
}
