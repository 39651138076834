import React, { useState } from "react";
import "react-phone-number-input/style.css";

import Form from "./Form";

import { ReactComponent as TickIcon } from "../../../assets/images/modal/tick.svg";

import "./style.scss";

const QueryForm = () => {
  const [isSubmitSuccessful, setSubmitSuccess] = useState(false);

  return (
    <div className="adwordsFormContainer">
      <div className="adwordsFormContainer__content">
        {isSubmitSuccessful ? (
          <div className="adwordsFormContainer__form_submitted">
            <TickIcon />
            <div className="heading">Thank you for your interest!</div>
            <div className="subheading">
              We have received your request. One of our fabric experts will
              reach out to you in 24 hours.
            </div>
            <br />
            {/* <div className="subheading">
              For urgent requests, please call us on
              <br/>
              <span className="bold">+91 89203 92418</span>
            </div> */}
          </div>
        ) : (
          <Form setSubmitSuccess={setSubmitSuccess} />
        )}
      </div>
    </div>
  );
};
export default QueryForm;
