export const ADDRESS = [
  {
    title: "New Delhi",
    description:
      "Desk Connect - First Floor, Plot No-2, Kh.No. 384/2, 100 feet Rd, Mehrauli-Gurgaon Rd, Opp. Corporation Bank, Ghitorni, New Delhi 110030",
  },
  {
    title: "Noida",
    description:
      "1st Floor, Plot No.D-107,Vyapar Marg, D Block,Noida Sector-2 Uttar Pradesh 201301",
  },
  {
    title: "Gurugram",
    description:
      "  NH8-Udyog Vihar, 90B, Delhi - Jaipur Expy, Udyog Vihar, Sector 18,Gurugram, Haryana 122008",
  },
  {
    title: "Jaipur",
    description:
      "34/6, Kiran Path,Mansarovar Sector 3, Mansarovar, Jaipur,Rajasthan 302020",
  },
  {
    title: "Ahmedabad",
    description:
      " SSPACIA 4th floor, Agrawal Complex, Chimanlal Girdharlal Rd, Ahmedabad, Gujarat,380009",
  },
  {
    title: "Bangladesh",
    description:
      " Workstation 101, Uttara  Tower, Level 4. 1 Jashimuddin Avenue, Sector 3, Uttara C/A  Dhaka 1230, Bangladesh",
  },
];
