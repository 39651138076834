import React from "react";
import "./styles.scss";
import iconarrow from "../../assets/images/hero/Vector.svg";

function Partnersection({
  featureSectionRef,
  navigateToForm,
  imgDetails,
  page,
}) {
  return (
    <div className={page=="supplier"? "part paddingSupplier ":"part"} ref={featureSectionRef}>
      <div className="temp">
        <h1 className="heading">Why Partner with us</h1>
        <button id="b1" onClick={navigateToForm}>
          Get Started
          <img className="arrow" src={iconarrow} alt="icon" />
        </button>
      </div>
      {page == "supplier" ? (
        <div className="partner">
          {Object.values(imgDetails).map(({ icon }) => (
            <div className="pimage" key={icon}>
              <img src={icon} alt="icon" />
            </div>
          ))}
        </div>
      ) : (
        <div className="partner">
          {imgDetails?.map((item) => {
            return (
              <div className="imgDetailsContainer" key={item.txt}>
                <div className="imgDetailsBox">
                  <img src={item.icon} width="100%" height="100%" />
                </div>

                <p
                  className={`imgDetailsTxt ${
                    item?.text.length > 40 ? "widthMore" : "widthLess"
                  }`}
                >
                  {item.text}
                </p>
              </div>
            );
          })}
        </div>
      )}
      <div className="bds">
        <button id="b2" onClick={navigateToForm}>
          Get Started
          <img className="arrow" src={iconarrow} alt="icon" />
        </button>
      </div>
    </div>
  );
}

export default Partnersection;
