import React from "react";
import "./style.scss";

const FormError = ({ error, errorClass }) => {
  return (
    <div className={"error " + errorClass}>
      <p>{error}</p>
    </div>
  );
};

export default FormError;
