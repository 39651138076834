import RegionalDiversity from "../../assets/images/banner/Orders.svg";
import LocalisedExpertise from "../../assets/images/banner/In transit.svg";
import NationWide from "../../assets/images/banner/Localised Expertise.svg";
import Cultural from "../../assets/images/banner/Factory.svg";
export const customerSpecificArray=[
    {
        img:RegionalDiversity,
        title:"Reliant supply chain",
    },
    {
        img:LocalisedExpertise,
        title:"Nationwide logistics network",
    },
    {
        img:NationWide,
        title:"Proven technology",
    },
    {
        img:Cultural,
        title:"Wide manufacturing capability",
    },
  
]