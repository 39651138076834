export const FABRIC_TYPE_HASH = {
    1: 'Knits',
    2: 'Woven',
    3: 'Knits, Woven',
  };
  export const INTERNATIONAL_COUNTRIES = {
    bangladesh: {
      label: 'Bangladesh',
      value: 'bangladesh'
    },
    srilanka: {
      label: 'Sri Lanka',
      value: 'srilanka'
    },
  };