export const BlogsData = [
  {
    title: "Printing services by Locofast",
    link: "https://locofast.com/blog/?p=369",
  },
  {
    title: "A deep dive into Yarn Manufacturing Process",
    link: "https://locofast.com/blog/?p=581",
  },
  {
    title: "Staple yarn- A Detailed Guide",
    link: "https://locofast.com/blog/?p=543",
  },
  {
    title: "Humans of Locofast: Not Just Another Brick in the Wall",
    link: "https://locofast.com/blog/?p=458",
  },
  {
    title: "The first step to fabric- Yarns and it’s broad categories",
    link: "https://locofast.com/blog/?p=555",
  },
  {
    title:
      "Exploring the World of Fabric Fibers: A Guide to Understanding Different Types of Fibers",
    link: "https://locofast.com/blog/?p=533",
  },
  {
    title: "A guide to fabric types and their production",
    link: "https://locofast.com/blog/?p=487",
  },
  {
    title: "How to scale up textile business in India",
    link: "https://locofast.com/blog/?p=478",
  },
  {
    title: "Technology in Textiles",
    link: "https://locofast.com/blog/?p=474",
  },
  {
    title: "A guide to sustainable cloth materials",
    link: "https://locofast.com/blog/?p=473",
  },
  {
    title: "A Guide to Fabric Sourcing",
    link: "https://locofast.com/blog/?p=470",
  },
  {
    title: "Why India needs a marketplace for Textiles",
    link: "https://locofast.com/blog/?p=466",
  },

  {
    title: "The Ultimate Guide to Clothing Material",
    link: "https://locofast.com/blog/?p=462",
  },
  {
    title: "Understanding weaving and fabric loom types",
    link: "https://locofast.com/blog/?p=365",
  },
  {
    title: "A Deep Dive Into Cotton Production in India",
    link: "https://locofast.com/blog/?p=453",
  },
  {
    title: "Trending Fabrics for Winter 2022",
    link: "https://locofast.com/blog/?p=447",
  },
  {
    title: "Key Hubs of Global Textile Sourcing",
    link: "https://locofast.com/blog/?p=443",
  },
  {
    title: "Global Trends in the Textile Industry: An Overlook",
    link: "https://locofast.com/blog/?p=439",
  },
  {
    title: "Weaving Threads of Gold: Zari in India",
    link: "https://locofast.com/blog/?p=430",
  },
  {
    title: "The Art of Tying and Dyeing: Bandhani",
    link: "https://locofast.com/blog/?p=416",
  },
  {
    title: "A Closer Look at the Pantone Color Matching System",
    link: "https://locofast.com/blog/?p=424",
  },
  {
    title: "A Brief History of Women’s Day and Its Relationship to the Textile Industry",
    link: "https://locofast.com/blog/?p=413",
  },
  {
    title: "Exploring the Untapped Potential of Technical Textiles in India",
    link: "https://locofast.com/blog/?p=427",
  },
  {
    title: "Locofast raises $15 million in Series A with an aim to be the largest textile marketplace in Southeast Asia",
    link: "https://locofast.com/blog/?p=405",
  },
  {
    title: "Choosing the Right Vendor: A Quick Guide",
    link: "https://locofast.com/blog/?p=362",
  },
  {
    title: "Setting a New Standard for Quality Textiles in India",
    link: "https://locofast.com/blog/?p=11",
  },
  {
    title: "A Quick Guide to Different Types of Looms",
    link: "https://locofast.com/blog/?p=356",
  },
  {
    title: "Locofast x Mrida: Virtual Crocheting Workshop",
    link: "https://locofast.com/blog/?p=14",
  },
  {
    title: "Integrating Suppliers in the Locofast Ecosystem: Our Process",
    link: "https://locofast.com/blog/?p=359",
  },
  {
    title: "The Locofast Ecosystem: Reshaping the Textile Industry",
    link: "https://locofast.com/blog/?p=9",
  },
  
];
