import React from "react";
import "./style.scss";
import iconarrow from "../../assets/images/hero/Vector.svg";
const BannerSection = ({ image, isImage, text1, text2, link }) => {
  return (
    <div className="banner">
      <div className="banner__left">
        {isImage && <img src={image} alt="banner" loading="lazy" />}
        <p className="para">
          {text1}
          <br />
          {text2}
        </p>
      </div>
      <div className="banner__right">
        <a href={link} target="_blank" rel="noopener noreferrer">
          <button type="button" className="btn">
            Download app
            <img className="arrow" src={iconarrow} alt="icon" loading="lazy" />
          </button>
        </a>
      </div>
    </div>
  );
};

export default BannerSection;
