import React, { useRef, useState } from "react";
import "./styles.scss";
import { modalData, platformArray } from "./platformArray";
import chevronRight from "../../assets/images/chevron_bottom.svg";
import Modal from "../Modal";
import PlatformSectionModaL from "./PlatformSectionModal";
function PlatformSection({ featureSectionRef, navigateToForm }) {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const formRef=useRef();
  return (
    <>
      <div className="platform" ref={featureSectionRef}>
        <h1 className="platform_heading">
          Tailored solutions for your business to accelerate growth
        </h1>
        <div className="cardSection_wrapper">
          {platformArray.map((section, index) => {
            return (
              <div className="cardInfo" key={index}  onClick={() => {
                setSelectedIndex(index);
                setOpenModal(true);
                formRef.current.resetForm();
              }}>
       
                <img
                  src={section?.img}
                   width={"100%"}
                   alt="icon"
                  className="cardInfo_img"
                />
                <div className="cardInfo_heading">{section?.heading}</div>
                <div className="cardInfo_subHeading">
                  {section?.subHeading}
                </div>
                <div
                  className="cardInfo_tag"
                 
                >
                  <p className="cardInfo_knowMore">Know more</p>
                  <div style={{marginBottom:12}}>
                  <img src={chevronRight} alt="icon" />
                    </div>
            
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Modal
        show={openModal}
        showCross={false}
        modalClass="platformModalPadding"
        modalBody="platformModalPadding"
      >
        <PlatformSectionModaL data={modalData[selectedIndex]} _handleClose={()=>setOpenModal(false)} formRef={formRef} />
      </Modal>
    </>
  );
}

export default PlatformSection;
