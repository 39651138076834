import React from "react";
import moment from "moment";
import "./style.scss";
import { FABRIC_TYPE_HASH} from "./base";
const SupplierProfileData = ({ supplierData = {} }) => {
  const {
    gstNumber,
    createdOn,
    fabricType,
    loomTypes,
    productionTypes,
    fabricCategory,
    addresses,
    supplierUsers,
  } = supplierData || {};

  const getUniqueWidth = () => {
    let uniqueWidths = new Set();
    fabricCategory.forEach((category) => {
      category.widths.forEach((width) => {
        uniqueWidths.add(width.name);
      });
    });
    const uniqueWidthsArray = Array.from(uniqueWidths);
    const widthsString = uniqueWidthsArray
      .map((width) => width + " in")
      .join(", ");

    return widthsString;
  };
  const getUniqueSpecialization = () => {
    let uniqueSubcategoryNames = new Set();
    fabricCategory.forEach((category) => {
      if (category.hasOwnProperty("subCategories")) {
        category.subCategories.forEach((subcategory) => {
          uniqueSubcategoryNames.add(subcategory.name);
        });
      }
    });
    const uniqueSubcategoryNamesArray = Array.from(uniqueSubcategoryNames);
    const subcategoryNamesString = uniqueSubcategoryNamesArray.join(", ");
    return subcategoryNamesString;
  };

  const getAddress = () => {
    const { addressLine1, addressLine2, city, state, pinCode, country } =
      addresses?.[0] || {};
    const addressString = [
      addressLine1,
      addressLine2,
      city,
      state,
      pinCode,
      country,
    ]
      .filter(Boolean)
      .join(", ");
    return addressString;
  };

  const gstPattern = (gstNumber) => {
    return `${gstNumber.slice(0, 2)} ${gstNumber.slice(
      2,
      12
    )} ${gstNumber.slice(12, 15)}`;
  };
  return (
    <div className="grid-container">
      <div className="grid-item">
        <p className="dataHeading">GST number</p>
        <p className="dataValue">
          {gstNumber && gstNumber != " " ? gstPattern(gstNumber) : "--"}
        </p>
      </div>
      <div className="grid-item">
        <p className="dataHeading">Registration date</p>
        <p className="dataValue">{moment(createdOn).format("Do MMMM, YYYY")}</p>
      </div>
      <div className="grid-item">
        <p className="dataHeading">Fabrics</p>
        <p className="dataValue">{FABRIC_TYPE_HASH[fabricType]}</p>
      </div>
      <div className="grid-item">
        <p className="dataHeading">Loom type</p>
        <p className="dataValue">
          {loomTypes && loomTypes.length
            ? loomTypes?.map((_loomType, index) => (
                <span key={_loomType.id}>{`${_loomType.name}${
                  loomTypes?.length - 1 != index ? ", " : ""
                }`}</span>
              ))
            : "--"}
        </p>
      </div>
      <div className="grid-item">
        <p className="dataHeading">Services</p>
        <p className="dataValue">
          {" "}
          {productionTypes?.map((service) => service.name).join(", ")}
        </p>
      </div>
      <div className="grid-item">
        <p className="dataHeading">Category</p>
        <p className="dataValue">
          {fabricCategory?.map((service) => service.categoryName).join(", ") ||
            "--"}
        </p>
      </div>
      <div className="grid-item">
        <p className="dataHeading">Width supported</p>
        <p className="dataValue" style={{ display: "flex" }}>
          {fabricCategory && fabricCategory?.length ? getUniqueWidth() : "--"}
        </p>
      </div>
      <div className="grid-item">
        <p className="dataHeading">Specialization</p>
        <p className="dataValue">
          {fabricCategory && fabricCategory?.length
            ? getUniqueSpecialization()
            : "--"}
        </p>
      </div>

      <div
        className="addressField"
        style={{ marginBottom: !supplierUsers?.length ? 100 : 0 }}
      >
        <p className="dataHeading">Address</p>
        <p className="dataValue addressWidth">{getAddress()}</p>
      </div>
    </div>
  );
};
export default SupplierProfileData;
