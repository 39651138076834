import React, { useState, useRef } from "react";
import "./style.scss";
import InfoContainer from "../InfoContainer";
import ImageAlignmentSection from "../ImageAlignmentSection";
import img1 from "../../assets/images/advantage/manufacture1.webp";
import img2 from "../../assets/images/advantage/manufacture2.webp";
import img3 from "../../assets/images/advantage/manufacture3.webp";
import Modal from "../Modal";
import CategorySubmissionForm from "../CategorySubmissionForm";
const ManufactureSection = () => {
  const [showModal, setShowModal] = useState(false);
  const [isSubmitSuccessful, setSubmitSuccess] = useState(false);
  const formRef = useRef();
  const pointerData = [
    "Providing industry best prices",
    "Faster and reliable delivery",
    "Complete end-to-end visibility on fabric order",
  ];
  return (
    <div className="manufactureSection">
      <InfoContainer
        title="Manufacturers"
        subHeading="We serve the manufacturers partnered with us by"
        pointerData={pointerData}
        btnTxt="Get in touch"
        onClick={() => {
          setShowModal(true);
          formRef?.current?.resetForm();
        }}
      />
      <ImageAlignmentSection
        img1={img1}
        img2={img2}
        img3={img3}
        widthAdjustment={"manufactureSection_widthAdjustment"}
      />
      <Modal
        show={showModal}
        _handleClose={() => {
          setShowModal(false);
          setSubmitSuccess(false);
        }}
        title={!isSubmitSuccessful ? "Fill details" : ""}
      >
        <CategorySubmissionForm
          closedText={"Close"}
          submitText={"Request a call back"}
          _handleClose={() => {
            setShowModal(false);
            setSubmitSuccess(false);
          }}
          isSubmitSuccessful={isSubmitSuccessful}
          setSubmitSuccess={setSubmitSuccess}
          fillDetails={true}
          formType="manufacturer"
          formRef={formRef}
        />
      </Modal>
    </div>
  );
};
export default ManufactureSection;
