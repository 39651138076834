import React from "react";
import "./style.scss";
import { useState, useEffect } from "react";
import { Pridearray } from "./Pridearray";
import PrideCarousel from "../PrideCarousel";
import PrideCard from "../PrideCard";

function PrideSection() {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleChange = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleChange);

    return () => window.removeEventListener("resize", handleChange);
  });
  return (
    <>
      <div className="pride">
        {width > 768 ? (
          <>
            <div className="heading">Our Values,we take pride in</div>
            <div className="pride__wrapper">
              {Object.values(Pridearray).map(
                ({ img, title, description, index }) => (
                  <PrideCard
                    key={index}
                    img={img}
                    heading={title}
                    description={description}
                  />
                )
              )}
            </div>
          </>
        ) : (
          <>
            <div className="heading">
              Our Values
              <br />
              <span>We take pride in</span>
            </div>
            <PrideCarousel />
          </>
        )}
      </div>
    </>
  );
}

export default PrideSection;
