import React from "react";
import img1 from "../../assets/images/box/9.svg";
import "./style.scss";
import { useState } from "react";
import { useEffect } from "react";
import {
  SwatchImages,
  SwatchImagesMobile,
  SwatchImagesMobileRight,
  SwatchImagesRight,
} from "./config";

const BoxSection = () => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleChange = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleChange);

    return () => window.removeEventListener("resize", handleChange);
  });
  return (
    <div className="box-section">
      <div className="box-section__left">
        <h1>Swatch boxes</h1>
        <p>
          A thoughtfully curated collection of fabric samples to give you the
          ease of choice and quality at your doorstep.
        </p>
        <a
          href="https://swatchbox.locofast.com/collections/butta-kit"
          target="_blank"
          rel="noreferrer"
        >
          <button type="submit" className="btn" value="button">
            Order now
          </button>
        </a>
      </div>
      {width > 768 ? (
        <div className="box-section__right">
          <div className="section">
            {SwatchImages.map((column, id) => (
              <div className="row" key={id}>
                {column.map((img, id) => (
                  <img src={img} alt=" box" loading="lazy" key={id} />
                ))}
              </div>
            ))}
          </div>
          <div className="second">
            <img src={img1} alt="box" loading="lazy" />
          </div>
          <div className="section">
            {SwatchImagesRight.map((column, id) => (
              <div className="row" key={id}>
                {column.map((img, id) => (
                  <img src={img} alt=" box" loading="lazy" key={id} />
                ))}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="box-section__right-mobile">
          <img
            src={img1}
            alt="box"
            className="special"
            width="312px"
            height="256px"
            loading="lazy"
          />
          <div className="row">
            {SwatchImagesMobile.map(({ image }, id) => (
              <img src={image} alt="box" loading="lazy" key={id} />
            ))}
          </div>
          <div className="row">
            {SwatchImagesMobileRight.map(({ image }, id) => (
              <img src={image} alt="box" loading="lazy" key={id} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default BoxSection;
