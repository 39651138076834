import React from "react";

const WebpImage = ({ imgSrc, imgType = "image/png", webpSrc }) => (
  <picture>
    <source type="image/webp" srcSet={webpSrc} />
    <source type={imgType} srcSet={imgSrc} />
    <img src={imgSrc} alt="" />
  </picture>
);

export default WebpImage;
