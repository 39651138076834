import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";

import LogoBlue from "../../assets/images/logo-blue.svg";
import Logoo from "../../assets/images/logoo-blue.svg";

import { ReactComponent as CloseIcon } from "../../assets/images/navigation/close.svg";
import { ReactComponent as MenuIcon } from "../../assets/images/navigation/menu.svg";
import { ReactComponent as HomeIcon } from "../../assets/images/navigation/home.svg";

import {
  ABOUT_US_ROUTE,
  APP_ROUTE,
  CAREERS_ROUTE,
  SUPPLIERS_ROUTE,
  SUSTAINABILITY_ROUTE,
  // SWATCH_ROUTE
} from "../../constants";

import "./style.scss";
import Modal from "../Modal";
import CategorySubmissionForm from "../CategorySubmissionForm";

const getLinks = (pathname, view = "desktop", onClick, onModalClick) => {
  return (
    <>
      {view === "desktop" ? (
        <Link
          to={APP_ROUTE}
          className={`link ${
            pathname === APP_ROUTE && "link--active"
          } link--${view}`}
          onClick={onClick}
        >
          <HomeIcon />
        </Link>
      ) : null}
      <Link
        to={ABOUT_US_ROUTE}
        className={`link ${
          pathname === ABOUT_US_ROUTE && "link--active"
        } link--${view}`}
        onClick={onClick}
      >
        About Us
      </Link>
      <Link
        to={CAREERS_ROUTE}
        className={`link ${
          pathname === CAREERS_ROUTE && "link--active"
        } link--${view}`}
        onClick={onClick}
      >
        Customers
      </Link>
      <Link
        to={SUPPLIERS_ROUTE}
        className={`link ${
          pathname === SUPPLIERS_ROUTE && "link--active"
        } link--${view}`}
        onClick={onClick}
      >
        Suppliers
      </Link>
      {/* <Link
        to={SWATCH_ROUTE}
        className={`link ${
          pathname === SWATCH_ROUTE && "link--active"
        } link--${view}`}
        onClick={onClick}
      >
        Inspiration
      </Link> */}
      <Link
        to={SUSTAINABILITY_ROUTE}
        className={`link ${
          pathname === SUSTAINABILITY_ROUTE && "link--active"
        } link--${view}`}
        onClick={onClick}
      >
        Sustainability
      </Link>
      <a
        href={process.env.REACT_APP_FABRIC_APP_URL}
        className={`link-btn link-btn--${view}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Login
      </a>
      <button className="requestBtn" onClick={onModalClick}>
        Request a call back
      </button>
    </>
  );
};

const NavBar2 = ({
  location: { pathname },
  heroRef,
  withLinks = true,
  setscroll,
  swatchPage,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isSubmitSuccessful, setSubmitSuccess] = useState(false);

  return (
    <>
      <header
        className={`${
          !swatchPage ? "page-header scrolled" : "page-header scrolledSwatch"
        }`}
      >
        <div className="page-header__container">
          <Link to={APP_ROUTE}>
            <img className="symbol " src={Logoo} alt="Logoo" />
            <img className="logo" src={LogoBlue} alt="Logo" />
          </Link>
          {withLinks && (
            <>
              <div className="page-header__navItems">
                {getLinks(
                  pathname,
                  "desktop",
                  () => setShowMenu(false),
                  () => setShowModal(true)
                )}
              </div>
              <MenuIcon
                className="menuIcon"
                onClick={() => {
                  setShowMenu(true);
                  document.body.style.overflow = "hidden";
                }}
              />
              {showMenu && (
                <div className="menuwrapp">
                  <div className="menuDropdown">
                    <div
                      className="menuDropdown__iconWrapper"
                      onClick={() => {
                        setShowMenu(false);
                        document.body.style.overflow = "auto";
                      }}
                    >
                      <Link to={APP_ROUTE}>
                        <img className="logoo" src={Logoo} alt="Logo" />
                        <img className="logo-blue" src={LogoBlue} alt="Logo" />
                      </Link>
                      <CloseIcon />
                    </div>
                    <div className="menuDropdown__items">
                      {getLinks(
                        pathname,
                        "mobile",
                        () => {
                          setShowMenu(false);

                          document.body.style.overflow = "auto";
                        },
                        () => setShowModal(true)
                      )}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </header>
      <Modal
        show={showModal}
        _handleClose={() => setShowModal(false)}
        title={"Fill details"}
      >
        <CategorySubmissionForm
          closedText={"Close"}
          submitText={"Request a call back"}
          _handleClose={() => setShowModal(false)}
          isSubmitSuccessful={isSubmitSuccessful}
          setSubmitSuccess={setSubmitSuccess}
          fillDetails={true}
        />
      </Modal>
    </>
  );
};

export default withRouter(NavBar2);
