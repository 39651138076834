import img1 from "../../../assets/images/feature/1.svg";
import img2 from "../../../assets/images/feature/2.svg";
import img3 from "../../../assets/images/feature/3.svg";
import img4 from "../../../assets/images/feature/4.svg";

export const Features = [
  {
    img: img1,
    text: "Order Yardages or in bulk",
  },
  {
    img: img2,
    text: "Myriad of options",
  },
  {
    img: img3,
    text: "Easy to order",
  },
  {
    img: img4,
    text: "Doorstep delivery",
  },
];
