import React, { useRef, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Hero from "../../components/Hero";
// import swatch from "../../assets/images/swatch.svg";
import AdvantageSection from "../../components/AdvantageSection";
import NetworkSection from "../../components/NetworkSection";
import PlatformSection from "../../components/PlatformSection";
import ContactUs from "../../components/RequestModal/Form";
import Footer from "../../components/Footer";
import "./style.scss";
import AllBlogs from "../../components/AllBlogs";
import Carousel from "../../components/Carousel";
import KnowMoreUpdatesSection from "../../components/KnowMoreUpdatesSection";
import HeroSectionFAQ from "../../components/HeroSectionFAQ";
import Orders from "../../components/Orders";
import ProductSelection from "../../components/ProductSelection";
import { liveRatesApi, materialApi } from "../../services/ApiService";
import { questionsAnswers } from "../../components/HeroSectionFAQ/base";
import { knowMoreSectionArray } from "../../components/KnowMoreUpdatesSection/_base";

// import SwatchModal from "../../components/SwatchModal";

const HomePage = ({ heroRef }) => {
  const formRef = useRef(null);
  const featureSectionRef = useRef(null);
  const navigateToForm = () => {
    formRef.current.scrollIntoView(false);
  };

  const handleScroll = () => {
    featureSectionRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const [liveRates, setLiveRates] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    liveRatesApi({
      limit: 8,
      pageNumber: 1,
    })
      .then((resp) => {
        setLiveRates(resp?.data);
      })
      .catch((err) => {
        let msg =
          "Error occurred while submitting the request. Please try again.";
        if (typeof err === "string") {
          msg = `Following e${msg.substr(1)}\n${err}`;
        }

        alert(msg);
      });
    // materialApi(['fabricCategory', 'fabricSubCategory', 'fabricWidth', 'certification', 'weightUnit'])
  }, []);
  // const [show, setShow] = useState(false);
  // const [visible, setVisible] = useState(false);

  // const close = () => {
  //   setVisible(false);
  // };
  // const open = () => {
  //   setVisible(true);
  // };
  // const hideModal = () => {
  //   setShow(false);
  //   document.body.style.overflow = "auto";
  // };
  // const showModal = () => {
  //   setShow(true);
  //   document.body.style.overflow = "hidden";
  // };
  return (
    <>
      <Helmet>
        <title>Locofast: Cloud Textile Factory</title>
        <meta
          name="description"
          content="Locofast is the leading marketplace for fabrics. We provide a wide range of cotton, rayon, and sustainable, eco-friendly fabrics at the best price.

"
        />
      </Helmet>
      {/* <div className="swatch" onMouseOver={open} id="t1">
        <img src={swatch} alt="swatch" />
      </div>
      <div className="swatch" onClick={showModal} id="t2">
        <img src={swatch} alt="swatch" width="50px" height="56px" />
      </div> */}
      <Hero
        navigateToForm={navigateToForm}
        heroRef={heroRef}
        handleScroll={handleScroll}
      />
      {/* {visible && (
        <SwatchModal show={visible} _handleClose={close} screen={false} />
      )}

      {show && (
        <SwatchModal show={show} _handleClose={hideModal} screen={true} />
      )} */}

      {/* <NetworkSection /> */}
      <PlatformSection
        featureSectionRef={featureSectionRef}
        navigateToForm={navigateToForm}
      />
      <AdvantageSection navigateToForm={navigateToForm} />
      <ProductSelection />
      <Orders liveRates={liveRates} />
      <Carousel heading="Best service begins here"  isHomePage={true}/>
      <HeroSectionFAQ faqData={questionsAnswers} />
      <ContactUs
        formRef={formRef}
        isButtonShow={true}
        isDirection={true}
        isMember={false}
        isHomePage={true} 
      />
      <KnowMoreUpdatesSection  knowMoreData={knowMoreSectionArray} />
      <AllBlogs />
      <Footer isUpper={true} isHomePage={true} />
    </>
  );
};

export default HomePage;
