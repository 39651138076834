import React, { useState } from "react";

import "./styles.scss";

const WorkWithUs = () => {
  const [showText, setShowText] = useState(false);

  return (
    <>
      <div className="ourstory">
        <h1 className="heading">Our Story</h1>
        <div className="ourstory__write">
          <div className="description">
            <p>
              Founded in 2019, Locofast is the leading B2B platform for the
              textile industry in India, that aims to empower small and medium
              enterprises (SMEs) by integrating technology in their everyday
              business operations so that they are able to compete successfully
              in an increasingly tech-driven world.
              <br />
              <br />
              Despite being the second largest industry in the country-valued at
              $103.4 billion, and contributing 5% to its total
              <span className={showText ? "" : "d1"}>
                GDP- the textile industry is plagued by many challenges that are
                indigenous to the Indian context. Textiles is an extremely low
                manufacturing efficiency industry which often results in lags in
                the supply chain that not only inflate procurement costs but
                also results in inconsistent deliveries. This primarily
                endangers SMEs.
                <br />
                <br />
                As an online one-stop-shop, Locofast solves the dual challenges
                faced by SMEs. The first is the problem of achieving consistent
                orders, which it solves by maintaining a running stream of
                consistent orders that enterprise owners can choose as at their
                ease.
              </span>
            </p>
          </div>
          <br />
          <br />
          <div className="description">
            <div className={showText ? "" : "d2"}>
              It also solves the other major problem that suppliers are faced
              with, which is getting a reasonable price for their products. By
              promising consistent order requests, the Locofast model allows
              them the flexibility to undertake orders that they believe are
              reasonably priced.
              <br />
              <br />
              In acting as a catalyst between suppliers and manufacturers,
              Locofast's proprietary cloud technology ensures that every step of
              the procurement process is easily accessible, transparent, and
              affordable. Resultantly, Locofast has garnered a loyal customer
              base of over 700+ brands as well as an assortment of export
              houses, who have collectively procured over 50 million meters of
              textiles from over 500+ textile suppliers from all over the
              country.
              <br />
              <br />
              Locofast is headquartered in New Delhi and has regional offices
              pan-India and internationally.
            </div>
          </div>
          <div className="b">
            <button className="bt" onClick={() => setShowText(!showText)}>
              {showText ? "Read Less" : "Read More"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkWithUs;
