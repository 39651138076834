import image1 from "../../assets/images/life/1.webp";
import image2 from "../../assets/images/life/2.webp";
import image3 from "../../assets/images/life/3.webp";
import image4 from "../../assets/images/life/4.webp";
import image5 from "../../assets/images/life/5.webp";
import image6 from "../../assets/images/life/6.webp";
import image7 from "../../assets/images/life/7.webp";
import image8 from "../../assets/images/life/8.webp";

export const COMMUNITY_INFO = [image1, image2, image3, image4];
export const COMMUNITY_INFO2 = [image5, image6, image7, image8];
export const settings = {
  dots: true,
  dotsClass: "slick-dots slick-thumb",
  arrows: false,
  fade: true,
  infinite: true,
  speed: 1750,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 500,
  pauseOnHover: false,
};
export const ROW = [
  {
    text: "Towards Employees",
    subtext:
      "Our responsibility towards our employees include practices like training and continuous development that foster growth as well as prioritising healthcare and safe work culture.",
    images: [image1, image2, image3, image4],
  },
  {
    text: "Local Communities",
    subtext:
      "Engaging with communities at a grass-root level is vital to ensure our social influence. Backing this value, we partnered with Mrida, a social entrepreneurship initiative, and organised a two-day virtual crocheting workshop for women tailors in Bareilly, Uttar Pradesh. This upskilling workshop not only gave the women an opportunity to enhance the value of their work but also taught them how they could capitalise on these skills further.",
    images: [image5, image6, image7, image8],
  },
];
