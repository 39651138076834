import React, { useRef } from "react";

import About from "../../components/Adwords/About";
import AdvantageSection from "../../components/LocofastAd Section";
import Certificates from "../../components/LocofastAd Section/Certifications";
import Customers from "../../components/Adwords/Customers";
import CustomerNote from '../../components/Adwords/CustomerNote';
import FabricsRange from "../../components/Adwords/FabricsRange";
import Hero from "../../components/Hero/Adwords";
import Services from "../../components/Adwords/Services";
import Testimonials from "../../components/Adwords/Testimonials";
import RequestBottomSheet from "../../components/Adwords/RequestBottomSheet";

import "./styles.scss";
import "./styles.media.scss";

const ADVANTAGE_SECTION = {
  title: "The Locofast Advantage",
  description: `Our team comprises professionals with rich experience in the textile manufacturing industry. Locofast takes complete responsibility from order placement to strike-off approvals to quality checks and final delivery.`,
};

const HomePage = ({ heroRef, contactUsRef }) => {
  const featureSectionRef = useRef(null);

  const handleScroll = () => {
    featureSectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="adwordsContainer">
      <Hero heroRef={heroRef} handleScroll={handleScroll} />
      <div className="adwordsContainer__customernote">
        <CustomerNote />
      </div>
      <RequestBottomSheet />
      <div className="adwordsContainer__content">
        <Customers featureSectionRef={featureSectionRef} />
        <FabricsRange />
        <Services />
        <Certificates />
        <AdvantageSection cardInfo={ADVANTAGE_SECTION} />
        <Testimonials />
        <About />
      </div>
    </div>
  );
};

export default HomePage;
