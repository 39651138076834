import image1 from "../../assets/images/feature/5.svg";
import image2 from "../../assets/images/feature/6.svg";
import image3 from "../../assets/images/feature/7.svg";
import image4 from "../../assets/images/feature/8.svg";

export const PILLARS_INFO = [
  {
    img: image1,
    heading: "Community",
    para: "To fulfil our duties towards our employees and local communities.",
  },
  {
    img: image2,
    heading: "Compliance",
    para: "Monitoring sustainability compliance of our suppliers and customers.",
  },
  {
    img: image3,
    heading: "Collaboration",
    para: "Share in on the conscious activities undertaken by different entities.",
  },
  {
    img: image4,
    heading: "Conscious Sourcing",
    para: "Offering a diverse Green Product Portfolio with premium organic textiles.",
  },
];
