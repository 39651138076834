import img1 from '../../assets/images/partner/knowMoreCustomer1.png';
import img2 from '../../assets/images/partner/knowMoreCustomer2.png';
import img3 from '../../assets/images/partner/knowMoreCustomer3.png'

export const knowMoreCustomerSectionArray=[
    {
        img:img1,
        title:'Transforming Textile Business Finance: The Locofast Way',
        subText:'In the ever-evolving and competitive world of the textile industry, financial agility and adaptability are not just advantageous but...',
        date:'November 9, 2023',
        link:'https://locofast.com/blog/?p=719',
    },
    {
        img:img2,
        title:'The Role of Technology in Textile Sourcing: A Look into Locofast’s Tools',
        subText:'In the dynamic textile industry, technology plays a pivotal role, and Locofast is at the forefront of integrating innovative tech too...',
        date:'November 18, 2023',
        link:'https://locofast.com/blog/?p=729',
    },
    {
        img:img3,
        title:'End-to-End Visibility: How Locofast’s Customer App Revolutionizes Fabric...',
        subText:'In the dynamic world of textile and apparel manufacturing, the ability to maintain a transparent and comprehensive overview...',
        date:'November 22, 2023',
        link:'https://locofast.com/blog/?p=799',
    }
]