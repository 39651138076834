export const FABRICS = [
    {
        number: '1000 +',
        name: 'Fabric options',
    },
    {
        number: '500 +',
        name: 'Suppliers',
    },
    {
        number: '50 +',
        name: 'Categories',
    }
]