import React from "react";
import "./style.scss";
import AppImage from "../../assets/images/banner/CustomerIndiaMap.png";
import { customerSpecificArray } from "./_base";
const CustomerBase = () => {
  return (
    <div className="customerBaseContainer">
      <div className="customerBaseSection1">
        <p className="customerBaseoutlookHeading">Our customer base</p>
        <p className="customerBaseSubHeading">
          1200+ loyal customers serviced by our Suppliers each month.
        </p>
        <p className="customerBaseSubHeading" style={{ marginTop: 24 }}>
          Locofast plays a crucial role in knitting together the varied tapestry
          of the Indian textile market.
        </p>
        <div style={{ marginTop: 64 }}>
          {customerSpecificArray.map((item, index) => {
            return (
              <div className="facilities">
                <img
                  key={index}
                  src={item.img}
                  alt={`${item.heading}`}
                  style={{ marginRight: 16 }}
                />
                <div className="facilityTitle"> {item?.title}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="customerMapImageContainer">
        <img src={AppImage} width="100%" height="100%" />
      </div>
    </div>
  );
};

export default CustomerBase;
