export const questionsAnswers = [
  {
    question: "Why should I procure fabrics from Locofast?",
    answer:
      "At Locofast, we offer the largest choice of fabrics at the best prices. We source fabrics directly from a vast network of textile mills, ensuring competitive pricing, faster delivery, and a wide variety of options on a single online platform.",
  },
  {
    question: "How can Locofast accelerate my business growth?",
    answer:
      "Locofast provides tailored solutions to boost your business. Whether you need greige fabric, finished fabric, logistics services, or credit options, we're here to support your growth with quality, affordability, and reliability.",
  },
  {
    question:
      "What is greige fabric, and how does Locofast ensure its quality?",
    answer: `Greige fabric refers to unfinished, undyed fabric. Locofast ensures the highest quality by sourcing high-quality yarn spun with state-of-the-art machinery, meeting various material and count requirements to satisfy our customers' diverse needs.`,
  },
  {
    question:
      "How does Locofast handle finished fabrics, and what processing services are available?",
    answer: `Locofast offers access to advanced processing services such as dyeing, printing, RFD (Ready for Dyeing), and embroidery. We use cutting-edge equipment to ensure the highest quality finished fabrics for our customers.`,
  },
  {
    question: "What can I expect from Locofast's logistics services?",
    answer: `Locofast provides a seamless, reliable supply chain solution tailored to your specific needs. Our commitment to affordability ensures exceptional value, making your fabric procurement process smooth and cost-effective.`,
  },
  {
    question: "What is Locofast Credit Program?",
    answer: `The Locofast Credit Program is an exclusive opportunity designed to enhance your fabric sourcing experience. It offers credit solutions to support your business's fabric procurement needs.`,
  },
];
export const customerQuestionAnswers = [
  {
    question: "What types of fabrics can I procure through Locofast?",
    answer: `Locofast offers a wide range of high-quality fabrics sourced from across India. Our platform features a diverse selection, ensuring you can find the perfect material for any project, whether it's for fashion, home decor, or industrial use.`,
  },
  {
    question: "How does Locofast ensure timely and reliable delivery?",
    answer: `With a 95% On-Time In-Full (OTIF) delivery rate, we pride ourselves on our robust supply chain and nationwide logistics network. This ensures that your orders are delivered reliably and efficiently, minimising delays and disruptions`,
  },
  {
    question:
      "Can I get assistance with design and product development on Locofast?",
    answer: `We offer comprehensive support for brands, including assistance with design and product development. Our platform regularly updates with new and unique collections inspired by the latest trends, helping you stay ahead of the competition.`,
  },
  {
    question: "How does Locofast help manufacturers and buying houses?",
    answer: `For manufacturers, we provide industry-best prices, fast and reliable delivery, and complete visibility on fabric orders. Buying houses can benefit by nominating manufacturers to source high-quality fabrics and by accessing Locofast’s trending collections to attract more brands`,
  },
  {
    question: "What are the financial benefits of partnering with Locofast?",
    answer: `Partnering with us leads to overall savings of up to 6%. Additionally, we offer credit at affordable rates from NBFCs/Banks, making it easier for businesses of all sizes to manage their finances effectively.`,
  },
  {
    question: "How can I track my orders and manage my purchases",
    answer: ` Our customer app simplifies the ordering process. You can place orders instantly, track live orders, request specific fabrics, and receive timely updates. Dedicated account managers are also available to ensure you get the best quotes and support from our robust supplier network.
    `,
  },
];
