import React from "react";
import "./style.scss";

import Image1 from "../../assets/images/life/L1.webp";
import Image2 from "../../assets/images/life/L2.webp";
import Image3 from "../../assets/images/life/L3.webp";
import Image4 from "../../assets/images/life/L4.webp";
import Image5 from "../../assets/images/life/L5.webp";
import Image6 from "../../assets/images/life/L6.webp";

const LifeSection = () => {
  return (
    <>
      <div className="life">
        <div className="life__left">
          <h1 className="heading">Life @ Locofast</h1>
          <p className="para">
            At Locofast, our biggest asset is the people behind it. Our
            dedicated people on-board customers, suppliers as well as build our
            technology and we truly believe that our core strength is our
            people. We believe work is such a large part of our lives hence, we
            prioritize individual development. We support our employee’s
            passions by providing them with the right environment so they are
            motivated to achieve goals at work and in life.
            <br />
            <br />
            We are a team of diverse talent who ensures all members are
            comfortable collaborating at all levels. We look for individuals
            with a growth mindset who are open to all learnings and are hungry
            for knowledge. We strongly believe in fostering a positive culture
            of leadership and mentoring. Together, we work towards a common
            purpose of enabling our customers with a seamless experience.
          </p>
        </div>
        <div className="life__right-images">
          <div className="images">
            <img className="highlight" src={Image1} alt="office" />
            <img className="plain" src={Image2} alt="office" />
          </div>
          <div className="images">
            <img className="plain" src={Image3} alt="office" />
            <img className="highlight" src={Image4} alt="office" />
          </div>
          <div className="images">
            <img className="highlight" src={Image5} alt="office" />
            <img className="plain" src={Image6} alt="office" />
          </div>
        </div>
      </div>
    </>
  );
};

export default LifeSection;
