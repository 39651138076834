import img1 from "../../assets/images/variety/1.png";
import img2 from "../../assets/images/variety/2.jpg";
import img3 from "../../assets/images/variety/3.jpg";
import img4 from "../../assets/images/variety/4.jpg";
import img5 from "../../assets/images/variety/5.jpg";
import img6 from "../../assets/images/variety/6.jpg";
import img7 from "../../assets/images/variety/7.jpg";
import img8 from "../../assets/images/variety/8.jpg";
import img9 from "../../assets/images/variety/9.jpg";
import img10 from "../../assets/images/variety/10.jpg";
import img11 from "../../assets/images/variety/11.jpg";
import img12 from "../../assets/images/variety/12.png";
import img13 from "../../assets/images/variety/13.jpg";
import img14 from "../../assets/images/variety/14.jpg";
import img15 from "../../assets/images/variety/15.jpg";
import img16 from "../../assets/images/variety/16.jpg";
import img17 from "../../assets/images/variety/17.jpg";
import img18 from "../../assets/images/variety/18.jpg";
import img19 from "../../assets/images/variety/19.jpg";
import img20 from "../../assets/images/variety/20.jpg";
import img21 from "../../assets/images/variety/21.jpg";
import img22 from "../../assets/images/variety/22.jpg";
import img23 from "../../assets/images/variety/23.png";
import img24 from "../../assets/images/variety/24.jpg";
import img25 from "../../assets/images/variety/25.jpg";
import img26 from "../../assets/images/variety/26.jpg";
import img27 from "../../assets/images/variety/27.jpg";
import img28 from "../../assets/images/variety/28.jpg";
import img29 from "../../assets/images/variety/29.jpg";
import img30 from "../../assets/images/variety/30.jpg";
import img31 from "../../assets/images/variety/31.jpg";
import img32 from "../../assets/images/variety/32.jpg";
import img33 from "../../assets/images/variety/33.jpg";

export const Row1 = [
  [img2, img3, img4, img5, img6],
  [img7, img8, img9, img10, img11],
];
export const Row2 = [
  [img13, img14, img15, img16, img17],
  [img18, img19, img20, img21, img22],
];
export const Row3 = [
  [img24, img25, img26, img27, img28],
  [img29, img30, img31, img32, img33],
];
export const VARIETYDATA = [
  {
    name: `Bring 'em Gingham`,
    img: img1,
    Row: [
      [img2, img3, img4, img5],
      [img7, img8, img9, img10],
    ],
    link: "https://swatchbox.locofast.com/blogs/do-more-with-swatchbox/bring-em-gingham",
  },
  {
    name: `The Kalamkari Concoction`,
    img: img12,
    Row: [
      [img13, img14, img15, img16],
      [img18, img19, img20, img21],
    ],
    link: "https://swatchbox.locofast.com/blogs/do-more-with-swatchbox/the-kalamkari-concoction",
  },
  {
    name: `Setting new standards of work wear`,
    img: img23,
    Row: [
      [img24, img25, img26, img27],
      [img29, img30, img31, img32],
    ],
    link: "https://swatchbox.locofast.com/blogs/do-more-with-swatchbox/setting-new-standards-of-work-wear",
  },
];
