import React from "react";
import "./style.scss";
import { useState, useEffect } from "react";
import award1 from "../../assets/images/awards/award1.svg";
import award2 from "../../assets/images/awards/award2.svg";
import award3 from "../../assets/images/awards/award3.svg";
import award4 from "../../assets/images/awards/award4.svg";
import award5 from "../../assets/images/awards/award5.svg";
import news from "../../assets/images/awards/news.webp";
import newss from "../../assets/images/awards/news2.webp";

const DEFAULT_INDEX = 0;
const AWARDS = {
  COTTON: {
    image: award1,
    link: "https://www.businessinsider.in/business/startups/news/bharatpe-ceo-payus-cofounder-and-others-invest-15-million-in-locofast/articleshow/89918898.cms",
  },
  RAYON: {
    image: award2,
    link: "https://www.techinasia.com/textile-supplychain-firm-weaves-15m-series-money",
  },
  POLYSTER: {
    image: award3,
    link: "https://yourstory.com/2022/02/funding-alert-locofast-chiratae-stellaris-ventures-fashion-supply/amp",
  },
  KNITS: {
    image: award4,
    link: "https://inc42.com/buzz/supplytech-startup-locofast-bags-15-mn-to-invest-in-technology/",
  },
  DOME: {
    image: award5,
    link: "https://www.vccircle.com/bharatpe-s-suhail-sameer-stellaris-others-back-supply-chain-firm-locofast",
  },
};
const CarouselStep = ({ index, activeIndex, image, link, onMouseEnter }) => {
  return (
    <div
      className={`carouselStep ${
        index === activeIndex && "carouselStep--active"
      }`}
      onMouseEnter={() => onMouseEnter(index)}
    >
      <a href={link} target="_blank" rel="noopener noreferrer">
        <img
          src={image}
          alt="award"
          width="256px"
          height="104px"
          loading="lazy"
        />
      </a>
    </div>
  );
};
const AwardsTypesWrapper = () => {
  const [activeIndex, setActiveIndex] = useState(DEFAULT_INDEX);

  const onMouseEnter = (index) => {
    setActiveIndex(index);
  };

  return (
    <div className="awardWrapper__awardTypesWrapper carouselView">
      {Object.values(AWARDS).map((step, index) => (
        <CarouselStep
          index={index}
          key={index}
          {...step}
          activeIndex={activeIndex}
          onMouseEnter={onMouseEnter}
        />
      ))}
    </div>
  );
};
const Awards = ({ heroRef }) => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleChange = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleChange);

    return () => window.removeEventListener("resize", handleChange);
  });
  return (
    <>
      <div className="main" ref={heroRef}>
        <h1 className="har">Media</h1>
        <div className="main-1">
          {width > 992 ? (
            <div className="box-1">
              <a
                href="https://www.businessinsider.in/business/startups/news/bharatpe-ceo-payus-cofounder-and-others-invest-15-million-in-locofast/articleshow/89918898.cms"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={award1}
                  width="200px"
                  height="104px"
                  alt="award"
                  loading="lazy"
                />
              </a>
              <a
                href="https://www.techinasia.com/textile-supplychain-firm-weaves-15m-series-money"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={award2}
                  width="200px"
                  height="104px"
                  alt="award"
                  loading="lazy"
                />
              </a>
              <a
                href="https://yourstory.com/2022/02/funding-alert-locofast-chiratae-stellaris-ventures-fashion-supply/amp"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={award3}
                  width="200px"
                  height="104px"
                  alt="award"
                  loading="lazy"
                />
              </a>
              <a
                href="https://inc42.com/buzz/supplytech-startup-locofast-bags-15-mn-to-invest-in-technology/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={award4}
                  width="200px"
                  height="104px"
                  alt="award"
                  loading="lazy"
                />
              </a>
              <a
                href="https://www.vccircle.com/bharatpe-s-suhail-sameer-stellaris-others-back-supply-chain-firm-locofast"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={award5}
                  width="200px"
                  height="104px"
                  alt="award"
                  loading="lazy"
                />
              </a>
            </div>
          ) : (
            <AwardsTypesWrapper />
          )}

          <div className="box-2">
            <img src={news} alt="news" loading="lazy" />
          </div>
        </div>
        <h1 className="harr">Awards</h1>
        <div className="main-2">
          <div className="text1">
            <h3>
              Chiratae Ventures “Pride of India Awards for Successful
              Fundraising 2021”
            </h3>
            <br />
            <p>
              Since the two years inception, Locofast has witnessed robust
              growth. The idea of building a platform which could enable textile
              SMEs with consistent business opportunities, was such a novel
              idea. However, the need to step up and adapt to evolving dynamics
              of the textile industry and to streamline the currently broken
              textile supply chain was indispensable. We are elated to have won
              the Pride of India Awards for Successful Fundraising 2021. For
              Locofast, to become part of a long-standing achievement and to be
              recognized along with other portfolio companies at Chiratae
              Ventures (Formerly IDG Ventures India) Ventures, is such a proud
              moment.
            </p>
          </div>
          <div className="text2">
            <img src={newss} alt="award" loading="lazy" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Awards;
