import React from "react";
import "./style.scss";

const ImageAlignmentSection = ({ img1, img2, img3 ,widthAdjustmentt={}}) => {
  return (
    <div className="imageAlignmentContainer widthAdjustment">
      <div className="imgAlignmentsection1">
        <div className="brandImg1">
          <img src={img1} alt="img1" width="100%" height="100%" />
        </div>
        <div className="brandImg2">
          <img src={img2} alt="img2" width="100%" height="100%" />
        </div>
      </div>
      <div className="imgAlignmentsection2">
      <div className="brandImg3">
        <img src={img3} alt="img3" width="100%" height="100%" />
      </div>
      </div>
    </div>
  );
};
export default ImageAlignmentSection;
