import React, { useEffect, useRef, useState } from "react";
import Factory from "../../assets/components/SupplierProfile/Factory.svg";
import chevronRight from "../../assets/components/SupplierProfile/arrow_forward.svg";
import arrow from "../../assets/images/chevron_bottom.svg";
import "./styles.scss";
import CategorySubmissionForm from "../CategorySubmissionForm";
import Modal from "../Modal";

const SupplierName = ({ companyName }) => {
  const [isSubmitSuccessful, setSubmitSuccess] = useState(false);
  const [fillDetails, setFillDetails] = useState(false);
  const [formModal, setFormModal] = useState(false);
  const formType = "supplier";
  const formRef = useRef();
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleChange = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleChange);

    return () => window.removeEventListener("resize", handleChange);
  });
  return (
    <div className="supplierNameContainer">
      <div className="supplierBox">
        <div className="factoryIcon">
          <img src={Factory} alt="factory" />
        </div>
        <p className="supplierName">{companyName}</p>
      </div>
      {width > 768 ? (
        <button
          className="supplierButton"
          onClick={() => {
            setFormModal(true);
            setFillDetails(true);
            setSubmitSuccess(false);
            formRef?.current?.resetForm();
          }}
        >
          <p className="supplierConnectTxt">Connect with Supplier</p>
          <img src={arrow} alt="icon" />
        </button>
      ) : (
        <div className="footerButton">
          <button
            style={{ width: "100%" }}
            onClick={() => {
              setFormModal(true);
              setFillDetails(true);
              setSubmitSuccess(false);
              formRef?.current?.resetForm();
            }}
          >
            <p>Connect with Supplier</p>
            <img src={chevronRight} alt="icon" style={{ marginTop: 5 }} />
          </button>
        </div>
      )}
      <Modal
        title={
          !isSubmitSuccessful
            ? fillDetails
              ? "Fill details"
              : "Share your requirement"
            : ""
        }
        show={formModal}
        _handleClose={() => {
          setFormModal(false);
          setFillDetails(false);
        }}
      >
        <CategorySubmissionForm
          closedText={"Close"}
          submitText={"Request a call back"}
          _handleClose={() => {
            setFormModal(false);
            setFillDetails(false);
          }}
          isSubmitSuccessful={isSubmitSuccessful}
          setSubmitSuccess={setSubmitSuccess}
          fillDetails={fillDetails}
          requirementFormClass={"herorequirementFormClass"}
          formType={formType}
          formRef={formRef}
        />
      </Modal>
    </div>
  );
};
export default SupplierName;
