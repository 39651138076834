import React, { useState, useEffect } from "react";
import "./style.scss";
import Slider2 from "../../components/Slider2";
import img1 from "../../assets/images/slider/1.webp";
import img2 from "../../assets/images/slider/2.webp";
import img3 from "../../assets/images/slider/3.webp";
import img4 from "../../assets/images/slider/4.webp";
import img5 from "../../assets/images/slider/5.webp";
import arrowleft from "../../assets/images/slider/chevron left.svg";
import arrowright from "../../assets/images/slider/chevron right.svg";
const Sliders = ({ heroRef }) => {
  const [defaultChecked, setDefaultChecked] = useState(0);
  const slideArray = ["s0", "s1", "s2", "s3", "s4"];

  const handlePrev = () => {
    if (defaultChecked === 0) {
      window.document.getElementById(`slide${slideArray.length - 1}`).click();
      setDefaultChecked(slideArray.length - 1);
    } else {
      window.document.getElementById(`slide${defaultChecked - 1}`).click();
      setDefaultChecked(defaultChecked - 1);
    }
  };

  const handleNext = () => {
    if (defaultChecked === slideArray.length - 1) {
      window.document.getElementById(`slide0`).click();
      setDefaultChecked(0);
    } else {
      window.document.getElementById(`slide${defaultChecked + 1}`).click();
      setDefaultChecked(defaultChecked + 1);
    }
  };
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleChange = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleChange);

    return () => window.removeEventListener("resize", handleChange);
  });
  return (
    <>
      {width > 768 ? (
        <div className="bdy" ref={heroRef}>
          <section id="slider">
            {slideArray.map((sliderId, sliderIndex) => {
              return (
                <input
                  className="dots"
                  type="radio"
                  name="slider"
                  id={sliderId}
                  key={sliderIndex}
                  onClick={() => setDefaultChecked(sliderIndex)}
                  defaultChecked={sliderIndex === defaultChecked}
                />
              );
            })}

            <label htmlFor="s0" id="slide0">
              <img
                src={img1}
                height="100%"
                width="100%"
                alt="slides"
                loading="lazy"
              />
            </label>
            <label htmlFor="s1" id="slide1">
              <img
                src={img2}
                height="100%"
                width="100%"
                alt="slides"
                loading="lazy"
              />
            </label>
            <label htmlFor="s2" id="slide2">
              <img
                src={img3}
                height="100%"
                width="100%"
                alt="slides"
                loading="lazy"
              />
            </label>
            <label htmlFor="s3" id="slide3">
              <img
                src={img4}
                height="100%"
                width="100%"
                alt="slides"
                loading="lazy"
              />
            </label>
            <label htmlFor="s4" id="slide4">
              <img
                src={img5}
                height="100%"
                width="100%"
                alt="slides"
                loading="lazy"
              />
            </label>
            <div className="car_arr ">
              <button id="arr1" className="slidearrow" onClick={handlePrev}>
                <img src={arrowleft} alt="icon" loading="lazy" />
              </button>
              <button id="arr2" className="slidearrow" onClick={handleNext}>
                <img src={arrowright} alt="icon" loading="lazy" />
              </button>
            </div>
          </section>
        </div>
      ) : (
        <Slider2 />
      )}
    </>
  );
};

export default Sliders;
