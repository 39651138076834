export const Colors1 = [
  {
    color: "#5F1B16",
  },
  {
    color: " #161712",
  },
  {
    color: "#A9946D",
  },
  {
    color: "#704322",
  },
  {
    color: "#243432",
  },
  {
    color: " #4C392B",
  },
];
export const MINIMUM_DESKTOP_WIDTH = 768;
