import React from "react";

import WebpImage from "../Common/WebpImage";
import { INTERNATIONAL } from "../RequestModal/Adwords/Form";
import leftarw from "../../assets/images/testimonial/leftarrow.svg";
import rightarw from "../../assets/images/testimonial/rightarrow.svg";
import seperator from "../../assets/images/testimonial/separator.svg";
import disable from "../../assets/images/testimonial/disable.svg";
import disabler from "../../assets/images/testimonial/disabler.svg";
import { useState } from "react";
import { useRef } from "react";
import { FABRIC_TYPES, FABRIC_TYPES_INTERNATIONAL } from "./config";
import { useLocation } from "react-router-dom";
import { SlideButton } from "./Testimonials";

const FabricsRange = () => {
  const { search } = useLocation();
  const location = new URLSearchParams(search).get("location");
  const DEFAULT_INDEX = 0;
  const [activeIndex, setActiveIndex] = useState(DEFAULT_INDEX);
  const ref = useRef(null);

  const scrollRight = (scrollOffset) => {
    if (activeIndex >= 2) {
      setActiveIndex(2);
    } else {
      ref.current.scrollBy({
        left: scrollOffset,
        behavior: "smooth",
      });
      setActiveIndex(activeIndex + 1);
    }
  };
  const scrollLeft = (scrollOffset) => {
    if (activeIndex <= 0) {
      setActiveIndex(0);
    } else {
      ref.current.scrollBy({
        left: scrollOffset,
        behavior: "smooth",
      });
      setActiveIndex(activeIndex - 1);
    }
  };
  return (
    <section className="fabricsRangeSection">
      <div className="fabricsRangeSection__part">
        <div className="mainContent">
          <div className="top">
            <h3 className="section__heading heading">Exhaustive range of fabrics</h3>
            {location === INTERNATIONAL && (
              <div className="arrw">
                <SlideButton float="right">
                  <img
                    src={activeIndex >= 1 ? disabler : rightarw}
                    alt="icon"
                    loading="lazy"
                    onClick={() => scrollRight(270)}
                  />
                </SlideButton>

                <SlideButton float="left">
                  <img
                    src={activeIndex <= 0 ? disable : leftarw}
                    alt="icon"
                    loading="lazy"
                    onClick={() => scrollLeft(-270)}
                  />
                  <img
                    src={seperator}
                    alt="separator"
                    style={{ paddingLeft: "20px" }}
                  />
                </SlideButton>
              </div>
            )}
          </div>
          <p className="section__subheading" style={{ marginTop: "-2px" }}>
            Choose from the standard fabrics, or get it customised to suit your
            requirement.
          </p>
        </div>
        <div className="fabricsRange content" ref={ref}>
          {(location === INTERNATIONAL
            ? FABRIC_TYPES_INTERNATIONAL
            : FABRIC_TYPES
          ).map(({ image, webpImage, title, description }, index) => (
            <div className="fabricsRange__item" key={index}>
              <WebpImage imgSrc={image} webpSrc={webpImage} />
              <div className="section__item_heading">{title}</div>
              <div className="section__item_subheading para">{description}</div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FabricsRange;
