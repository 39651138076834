import Img1 from "../../assets/images/design/1.png";
import Img2 from "../../assets/images/design/2.png";
import Img3 from "../../assets/images/design/3.png";
import Img4 from "../../assets/images/design/4.png";
import Img5 from "../../assets/images/design/5.png";
import Img6 from "../../assets/images/design/6.png";
import Img7 from "../../assets/images/design/7.png";
import Img8 from "../../assets/images/design/8.png";
import Img9 from "../../assets/images/design/9.png";
import Img10 from "../../assets/images/design/10.png";
import Img11 from "../../assets/images/design/11.png";
import Img12 from "../../assets/images/design/12.png";
import Img13 from "../../assets/images/design/13.png";
import Img14 from "../../assets/images/design/14.png";
import Img15 from "../../assets/images/design/15.png";
import Img16 from "../../assets/images/design/16.png";
import Img17 from "../../assets/images/design/17.png";
import Img18 from "../../assets/images/design/18.png";
import Img19 from "../../assets/images/design/19.png";
import Img20 from "../../assets/images/design/20.png";
import Img21 from "../../assets/images/design/21.png";
import Img22 from "../../assets/images/design/22.png";
import Img23 from "../../assets/images/design/23.png";
import Img24 from "../../assets/images/design/24.png";
import Img25 from "../../assets/images/design/25.png";
import Img26 from "../../assets/images/design/26.png";
import Img27 from "../../assets/images/design/27.png";
import Img28 from "../../assets/images/design/28.png";
import Img29 from "../../assets/images/design/29.png";
import Img30 from "../../assets/images/design/30.png";
import Img31 from "../../assets/images/design/31.png";
import Img32 from "../../assets/images/design/32.png";
import Img33 from "../../assets/images/design/33.png";
import Img34 from "../../assets/images/design/34.png";
import Img35 from "../../assets/images/design/35.png";
import Img36 from "../../assets/images/design/36.png";
import Img37 from "../../assets/images/design/37.png";
import Img38 from "../../assets/images/design/38.png";
import Img39 from "../../assets/images/design/39.png";
import Img40 from "../../assets/images/design/40.png";

export const DESIGN = [
  {
    img: Img1,
    link: "https://swatchbox.locofast.com/products/cotton-floral-fabric-18",
  },
  {
    img: Img2,
    link: "https://swatchbox.locofast.com/products/rayon-floral-fabric-18",
  },
  {
    img: Img3,
    link: "https://swatchbox.locofast.com/products/cotton-lehariya-fabric-01",
  },
  {
    img: Img4,
    link: "https://swatchbox.locofast.com/products/rayon-floral-fabric-06",
  },
  {
    img: Img5,
    link: "https://swatchbox.locofast.com/products/cotton-bandhani-fabric-04",
  },
  {
    img: Img6,
    link: "https://swatchbox.locofast.com/products/cotton-floral-fabric-01",
  },
  {
    img: Img7,
    link: "https://swatchbox.locofast.com/products/cotton-ikat-fabric-04",
  },
  {
    img: Img8,
    link: "https://swatchbox.locofast.com/products/cotton-ikat-fabric-11",
  },
  {
    img: Img9,
    link: "https://swatchbox.locofast.com/products/linen-floral-fabric-17",
  },
  {
    img: Img10,
    link: "https://swatchbox.locofast.com/products/rayon-tie-and-dye-fabric-03",
  },
  {
    img: Img11,
    link: "https://swatchbox.locofast.com/products/cotton-lehariya-fabric-08",
  },
  {
    img: Img12,
    link: "https://swatchbox.locofast.com/products/cotton-bandhani-fabric-11",
  },
  {
    img: Img13,
    link: "https://swatchbox.locofast.com/products/cotton-floral-fabric-02",
  },
  {
    img: Img14,
    link: "https://swatchbox.locofast.com/products/rayon-tie-and-dye-fabric-06",
  },
  {
    img: Img15,
    link: "https://swatchbox.locofast.com/products/cotton-polka-dot-fabric-18",
  },
  {
    img: Img16,
    link: "https://swatchbox.locofast.com/products/cotton-lehariya-fabric-09",
  },
  {
    img: Img17,
    link: "https://swatchbox.locofast.com/products/cotton-polka-dot-fabric-20",
  },
  {
    img: Img18,
    link: "https://swatchbox.locofast.com/products/linen-floral-fabric-14",
  },

  {
    img: Img19,
    link: "https://swatchbox.locofast.com/products/rayon-tie-and-dye-fabric-20",
  },
  {
    img: Img20,
    link: "https://swatchbox.locofast.com/products/linen-floral-fabric-12",
  },
  {
    img: Img21,
    link: "https://swatchbox.locofast.com/products/cotton-bandhani-fabric-08",
  },
  {
    img: Img22,
    link: "https://swatchbox.locofast.com/products/cotton-lehariya-fabric-02",
  },
  {
    img: Img23,
    link: "https://swatchbox.locofast.com/products/cotton-bandhani-fabric-01",
  },
  {
    img: Img24,
    link: "https://swatchbox.locofast.com/products/cotton-polka-dot-fabric-12",
  },
  {
    img: Img25,
    link: "https://swatchbox.locofast.com/products/linen-floral-fabric-10",
  },
  {
    img: Img26,
    link: "https://swatchbox.locofast.com/products/linen-floral-fabric-13",
  },
  {
    img: Img27,
    link: "https://swatchbox.locofast.com/products/linen-floral-fabric-16",
  },
  {
    img: Img28,
    link: "https://swatchbox.locofast.com/products/rayon-floral-fabric-13",
  },
  {
    img: Img29,
    link: "https://swatchbox.locofast.com/products/cotton-lehariya-fabric-04",
  },
  {
    img: Img30,
    link: "https://swatchbox.locofast.com/products/rayon-floral-fabric-01",
  },
  {
    img: Img31,
    link: "https://swatchbox.locofast.com/products/cotton-polka-dot-fabric-04",
  },
  {
    img: Img32,
    link: "https://swatchbox.locofast.com/products/cotton-bandhani-fabric-17",
  },
  {
    img: Img33,
    link: "https://swatchbox.locofast.com/products/cotton-bandhani-fabric-09",
  },
  {
    img: Img34,
    link: "https://swatchbox.locofast.com/products/cotton-lehariya-fabric-15",
  },
  {
    img: Img35,
    link: "https://swatchbox.locofast.com/products/rayon-floral-fabric-04",
  },
  {
    img: Img36,
    link: "https://swatchbox.locofast.com/products/rayon-floral-fabric-14",
  },
  {
    img: Img37,
    link: "https://swatchbox.locofast.com/products/rayon-tie-and-dye-fabric-16",
  },
  {
    img: Img38,
    link: "https://swatchbox.locofast.com/products/cotton-bandhani-fabric-12",
  },
  {
    img: Img39,
    link: "https://swatchbox.locofast.com/products/cotton-bandhani-fabric-20",
  },
  {
    img: Img40,
    link: "https://swatchbox.locofast.com/products/cotton-ikat-fabric-01",
  },
];
