import React from "react";
import "./style.scss";
import HeroPoster from "../../../assets/images/hero/hero-sustain.webp";
import HeroImage from "../../../assets/images/hero/sustain.webp";

const HeroSection = ({ heroRef }) => {
  return (
    <div className="sustain-hero" ref={heroRef}>
      <div className="sustain-hero__home">
        <img src={HeroPoster} alt="sustainability-img" className="hero-desktop"/>
        <img src={HeroImage} alt="sustainability-img" className="hero-mobile"/>
        <div className="info">
          <div className="heading">Sustainability</div>
          <div className="para">
            Being a part of the textile industry, we discern the social and
            environmental implications, hence it becomes our responsibility to
            adopt practices which focus on holistic development and create a
            sustainable future.
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
