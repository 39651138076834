import React from "react";
import img1 from "../../assets/images/box/18.svg";
import { FABRICS } from "./config";
import "./style.scss";

const SourceSection = () => {
  return (
    <div className="source">
      <div className="source__left">
        <img src={img1} alt="fabrics" loading="lazy" />
      </div>
      <div className="source__right">
        <div className="heading">
          Source fabrics which bring your ideas to life
        </div>
        <div className="para">
          Order fabrics for sampling or place a bulk order depending on your
          needs.
        </div>
        <div className="count">
          {FABRICS.map(({ number, name }, id) => (
            <div className="col" key={id}>
              <div className="number">{number}</div>
              <div className="name">{name}</div>
            </div>
          ))}
        </div>
        <a
          href="https://swatchbox.locofast.com/collections/cotton-fabric"
          target="_blank"
          rel="noreferrer"
        >
          <button type="submit" className="btn" value="button">
            Order now
          </button>
        </a>
      </div>
    </div>
  );
};

export default SourceSection;
