import React from "react";
import MapImage from "../../assets/images/network/suppliermap.svg";

import "./styles.scss";

const GettingStarted = () => {
  return (
    <>
      <div className="pannetwork">
        <div className="pannetwork__left">
          <h1 className="heading">Pan-India Network</h1>
          <p className="para">
            We take pride in our fast growing vast network of suppliers spanning
            across the country. With their robust infrastructure and heavy
            involvement in R&D, our suppliers specialize in delivering superior
            quality outcomes in the shortest turnaround time.
          </p>
        </div>
        <div className="pannetwork__right-image">
          <img src={MapImage} alt="map" />
        </div>
      </div>
    </>
  );
};

export default GettingStarted;
