import React from "react";
import "./style.scss";
import arrowRight from '../../assets/images/chevron_bottom.svg'
import { BLOG_ROUTE } from "../../constants";

const KnowMoreUpdatesSection = ({knowMoreData}) => {
  return (
    <div className="knowMoreSection">
      <h1 className="heading">Know latest updates from Locofast</h1>
      <div className="content_wrapper">
        {knowMoreData?.map((item, index) => {
          return (
            <div className="content_container"  key={index} onClick={()=> window.open(
           item?.link
            )}>
              <div style={{height:164}}>
              <img src={item?.img}  width='100%' height='100%' alt="icon"/>
              </div>
              <p className="content_heading">{item?.title}</p>
              <p className="content_subTxt">{item?.subText}</p>
              <p className="content_dates">{item?.date}</p>
            </div>
          );
        })}
      </div>
      <div className="viewButtonContainer">
      <div className="viewAllButton"  onClick={()=>{
         window.open(
          BLOG_ROUTE,
          "_blank" // <- This is what makes it open in a new window.
        );
      }}>
        <a className="viewAllText" href={BLOG_ROUTE}>View all</a>
        <img 
        src={arrowRight}
        alt="icon"
        />
      </div>
      </div>
    </div>
  );
};
export default KnowMoreUpdatesSection;
