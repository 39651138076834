import React, { useRef, useState } from "react";
import Slider from "react-slick";
import left from "../../../assets/images/aboutus/Left arrow (3).svg";
import right from "../../../assets/images/aboutus/Right arrow.svg";
import "./style.scss";
import Modal from "../../Modal";
import CategorySubmissionForm from "../../CategorySubmissionForm";

const ProductSelectionSlider = ({ selectedFabric, selectedProductType }) => {
  const formRef = useRef();
  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <img src={right} alt="right" onClick={onClick} className={className} />
    );
  }

  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <img src={left} alt="left" onClick={onClick} className={className} />
    );
  }
  const [openCateforyForm, setCategoryForm] = useState(false);
  const[selectedSubCategory,setSelectedSubCategory]=useState(null)
  const settings = {
    dots: false,
    rows: 1,
    infinite: false,
    slidesToShow: 6,
    slidesToScroll: 3,
    initialSlide: 0,
    arrows: true,
    autoplay: false,
    swipeToSlide: true,
    pauseOnHover: false,
    nextArrow: <SampleNextArrow className="rightArrow" />,
    prevArrow: <SamplePrevArrow className="leftArrow" />,

    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
          infinite: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          useTransform: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          useTransform: false,
        },
      },
    ],
  };

  const handleFormClose = () => {
    setCategoryForm(false);
    formRef?.current?.resetForm();
    setSubmitSuccess(false)
  };
  const[isSubmitSuccessful,setSubmitSuccess]=useState(false)
  const valueFabric=selectedFabric?.value
  return (
    <>
      <div className="productDesign">
        <div className="left-box"></div>
        <div className="right-box"></div>
        <Slider {...settings} className="slider">
          {valueFabric(selectedProductType)?.map(({ img, name, index }) => (
            <div key={index}  onClick={() =>{
              formRef?.current?.setFieldValue("subCategory",name)
               setCategoryForm(true)
            }}>
              <img
                src={img}
                alt=""
                key={img}
                className="image"
                loading="eager"
              />

              <div className={name != "" ? "text_blockSelection" : ""}>
                {name}
              </div>
            </div>
          ))}
        </Slider>
        <div className="design__images">
          {valueFabric(selectedProductType)?.map(({ img, name, link }) => (
            <div   onClick={() =>{
              formRef?.current?.setFieldValue("subCategory",name)
               setCategoryForm(true)
            }} >
              <img
                src={img}
                alt=""
                key={img}
                className="image"
                loading="eager"
              />
              <div
                className={name != "" ? "categoryNaming" : ""}
              >
                {name}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Modal
        show={openCateforyForm}
        title={ !isSubmitSuccessful ?"Share your requirement":""}
        _handleClose={handleFormClose}
      >
        <CategorySubmissionForm
          formRef={formRef}
          fillDetails={false}
          closedText={"Close"}
          submitText={"Submit"}
          fabricCategory={selectedFabric?.key}
          fabricType={selectedProductType}
          selectedSubCategory={selectedSubCategory}
          _handleClose={handleFormClose}
          showDropdown
          formType="customer"
          isSubmitSuccessful={isSubmitSuccessful}
          setSubmitSuccess={setSubmitSuccess}

        />
      </Modal>
    </>
  );
};

export default ProductSelectionSlider;
