import React from "react";
import Slider from "react-slick";
import left from "../../assets/images/product/left.svg";
import right from "../../assets/images/product/right.svg";
import ProductCard from "../ProductCard";
import { ProductArray } from "./config";
import "./style.scss";

const ProductSection = () => {
  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <img src={right} alt="right" onClick={onClick} className={className} />
    );
  }

  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <img src={left} alt="left" onClick={onClick} className={className} />
    );
  }
  const settings = {
    dots: true,
    infinite: false,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    nextArrow: <SampleNextArrow className="rightArrow" />,
    prevArrow: <SamplePrevArrow className="leftArrow" />,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 1030,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 640,
        settings: {
          speed: 500,
          rows: 2,
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false,
          infinite: true
        },
      },
    ],
  };

  return (
    <div className="product">
      <div className="main-text">
        <div className="heading">5000+ options to choose from</div>
        <div className="para">
          Explore fabrics like never before. Collections are curated with utmost
          care with special attention on quality, finish, colours and prints.{" "}
        </div>
      </div>
      <Slider {...settings} className="slider">
        {ProductArray.map((product, id) => (
          <ProductCard {...product} key={id} />
        ))}
      </Slider>
    </div>
  );
};
export default ProductSection;
