import React from "react";
import Slider from "react-slick";
import "./style.scss";

const ProductCarousel = ({ images }) => {
  const settings = {
    dots: true,
    infinite: true,
    fade: true,
    speed: 1750,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 500,
    pauseOnFocus: false,
    pauseOnHover: false,
  };
  return (
    <Slider {...settings} className="product-carousel">
      {images.map((image, id) => (
        <img src={image} alt="fabric" key={id} className="carousel-image" />
      ))}
    </Slider>
  );
};

export default ProductCarousel;
