import React from "react";
import Team from "../../../assets/images/aboutus/team.webp";

import "./styles.scss";

const Hero = ({ heroRef, handleScroll }) => {
  return (
    <div className="aboutus">
      <img
        className="tima"
        rel="preload"
        src={Team}
        alt="team"
        loading="lazy"
      />

      <h1 className="heading">Who we are?</h1>
      <div className="writ">
        <div className="description">
          <p>
            Locofast is a leading tech-enabled platform for textile procurement
            services in India. Connecting textile suppliers and vendors from all
            over the world through our platform, we aim to streamline the
            currently broken supply chain by solving all textile procurement
            problems.
          </p>
        </div>
        <div className="description">
          Embedded in our values, we dedicate ourselves in building an ecosystem
          that enables our partners with operational excellence and unlimited
          growth opportunities.
          <br />
          <br />
          We stand behind our unwavering commitment to provide our customers
          with consistent quality, best price and industry best turnaround time.
        </div>
      </div>
    </div>
  );
};

export default Hero;
