import image2 from "../../assets/images/certificates/2.webp";
import image3 from "../../assets/images/certificates/3.webp";
import image4 from "../../assets/images/certificates/4.webp";
import image5 from "../../assets/images/certificates/5.webp";
import image6 from "../../assets/images/certificates/6.svg";
import image7 from "../../assets/images/certificates/7.svg";
import image8 from "../../assets/images/certificates/8.webp";
import image9 from "../../assets/images/certificates/9.webp";
import image10 from "../../assets/images/certificates/10.webp";

export const CONSCIOUS_INFO = [
  {
    image: image2,
    text: "Organic Content Standard",
    subtext:
      "The Organic Content Standard (OCS) is a voluntary global standard that sets the criteria for third-party certification of organic materials and chain of custody.",
  },
  {
    image: image3,
    text: "Confidence in textiles",
    subtext:
      "Confidence in textiles - this has been the motto of Oeko-Tex. It's label indicates additional benefits of tested safety for skin-friendly clothing and other textiles.",
  },
  {
    image: image4,
    text: "Organic Blended Content Standard",
    subtext:
      "OCS Blended verifies the presence and amount of organic material in a final product. It tracks the flow of a raw material from its source to the final product.",
  },
  {
    image: image5,
    text: "Recycled Claim Standard 100",
    subtext:
      "The RCS verifies the presence and amount of recycled material in a final product. It is used as a chain of custody standard to track recycled raw materials through the supply chain.",
  },
  {
    image: image8,
    text: "Global Organic Textile Standard",
    subtext:
      "GOTS is the worldwide leading textile processing standard for organic fibres, including ecological and social criteria, backed up by independent certification of the entire textile supply chain.",
  },
  {
    image: image9,
    text: "Global Recycled Standard",
    subtext:
      "The GRS is an international, voluntary that sets requirements for third-party certification of recycled content, chain of custody, social and environmental practices and chemical restrictions.",
  },
];
export const CONSCIOUS_INFO2 = [
  {
    image: image6,
    text: "Higg Index",
    subtext:
      "Higg Index is a suite of tools for the standardized measurement of value chain sustainability, and it is central to the SAC's mission to transform businesses for exponential impact.",
  },
  {
    image: image7,
    text: "Better Cotton Initiative",
    subtext:
      "Better Cotton Initiative is the world's leading sustainability initiative for cotton. It promotes better standards in cotton farming.",
  },
];
export const CONSCIOUS_INITIAL_STATE = {
  text: "Green Product Portfolio",
  subtext:
    "We create a benchmark in the textile industry by incorporating practices which bring more transparency and are ethical and sustainable practices.",
};
export const CONSCIOUS_INFO_MOBILE = [
  {
    image: image10,
    text: "Birla Cellulose",
    subtext:
      "Birla Cellulose is a World leader in Viscose Staple Fibre (VSF). It's fibres are of natural origin, moisture absorbent, have soft feel, and are completely biodegradable.",
  },
  {
    image: image2,
    text: "Organic Content Standard",
    subtext:
      "The Organic Content Standard (OCS) is a voluntary global standard that sets the criteria for third-party certification of organic materials and chain of custody.",
  },
  {
    image: image3,
    text: "Confidence in textiles",
    subtext:
      "Confidence in textiles - this has been the motto of Oeko-Tex. It's label indicates additional benefits of tested safety for skin-friendly clothing and other textiles.",
  },
  {
    image: image4,
    text: "Organic Blended Content Standard",
    subtext:
      "OCS Blended verifies the presence and amount of organic material in a final product. It tracks the flow of a raw material from its source to the final product.",
  },
  {
    image: image5,
    text: "Recycled Claim Standard 100",
    subtext:
      "The RCS verifies the presence and amount of recycled material in a final product. It is used as a chain of custody standard to track recycled raw materials through the supply chain.",
  },
  {
    image: image6,
    text: "Higg Index",
    subtext:
      "Higg Index is a suite of tools for the standardized measurement of value chain sustainability, and it is central to the SAC's mission to transform businesses for exponential impact.",
  },
  {
    image: image7,
    text: "Better Cotton Initiative",
    subtext:
      "Better Cotton Initiative is the world's leading sustainability initiative for cotton. It promotes better standards in cotton farming.",
  },
  {
    image: image8,
    text: "Global Organic Textile Standard",
    subtext:
      "GOTS is the worldwide leading textile processing standard for organic fibres, including ecological and social criteria, backed up by independent certification of the entire textile supply chain.",
  },
  {
    image: image9,
    text: "Global Recycled Standard",
    subtext:
      "The GRS is an international, voluntary that sets requirements for third-party certification of recycled content, chain of custody, social and environmental practices and chemical restrictions.",
  },
];
