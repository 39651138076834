import React from "react";
import { useState } from "react";
import { ADDRESS } from "../Footer/address";
const DEFAULT_INDEX = 0;
const CarouselStep = ({
  index,
  activeIndex,
  title,
  description,
  onMouseEnter,
}) => {
  return (
    <div
      className={`carouselStep ${
        index === activeIndex && "carouselStep--active"
      }`}
      onMouseEnter={() => onMouseEnter(index)}
    >
      <div className="address">
        <h4 className="titles "  id="ad" style={{marginBottom:8,marginTop:0}}>
          {title}
        </h4>
        <p className="description">{description}</p>
      </div>
    </div>
  );
};
const FabricTypesWrapper = () => {
  const [activeIndex, setActiveIndex] = useState(DEFAULT_INDEX);

  const onMouseEnter = (index) => {
    setActiveIndex(index);
  };

  return (
    <div className="platformWrapper__fabricTypesWrapper carouselView">
      {Object.values(ADDRESS).map((step, index) => (
        <CarouselStep
          index={index}
          key={index}
          {...step}
          activeIndex={activeIndex}
          onMouseEnter={onMouseEnter}
        />
      ))}
    </div>
  );
};

const FooterCarousel = () => {
  return <FabricTypesWrapper />;
};

export default FooterCarousel;
