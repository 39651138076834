import React from "react";
import Accordion from "./Accordion";
import "./style.scss";

const questionsAnswers = [
  {
    question: "What does Locofast do?",
    answer:
      "We are a B2B tech enabled platform that connects textile manufacturers and suppliers.",
  },
  {
    question: "How should I register with Locofast as a customer?",
    answer:
      "For registration, customers can visit our website and register themselves at https://locofast.com/customers",
  },
  {
    question: "How should I register with Locofast as a supplier?",
    answer: `For registration, suppliers can visit our website and register themselves at https://locofast.com/suppliers`,
  },
  {
    question: "Do I have to pay a registration fee?",
    answer: `No, we don’t charge any registration fees from our partners or customers.`,
  },
  {
    question: "How will I receive orders?",
    answer: `Once the customer has raised a query, based on your expertise, the query will show up on your app. Now you can simply place your bid and the most relevant and best priced offer will win the order. This is done so as to maintain a transparent process.`,
  },
];
const questionsAnswerstwo = [
  {
    question: "Does Locofast provide logistics facilities?",
    answer:
      "Yes, we have a network of logistics service providers who ensures timely delivery of all orders.",
  },
  {
    question: "Do you provide financial assistance to customers?",
    answer: "Yes, we provide working capital loans to our customers.",
  },
  {
    question: "How long is the credit period?",
    answer: `The customer can take the working capital loan for upto 90 days.`,
  },
  {
    question: "Do I need to provide any collateral for the loan?",
    answer: `No, we provide the credit facilities without any collateral.`,
  },
  {
    question: "Can I get advance payment before supplying the products?",
    answer: `Yes. We provide advance payments to our suppliers.`,
  },
];
const FaqSection = ({ heroRef }) => {
  return (
    <div className="container" ref={heroRef}>
      <div className="heading">
        <h1>FAQs</h1>
      </div>
      <div className="component">
        <Accordion questionsAnswers={questionsAnswers} />
        <Accordion questionsAnswers={questionsAnswerstwo} />
      </div>
    </div>
  );
};

export default FaqSection;
