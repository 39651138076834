import React from "react";
import { useState } from "react";
import "./style.scss";
import { Pridearray } from "../PrideSection/Pridearray";
import PrideCard from "../PrideCard";
const DEFAULT_INDEX = 0;
const CarouselStep = ({
  index,
  activeIndex,
  img,
  title,
  description,
  onMouseEnter,
}) => {
  return (
    <div
      className={`carouselStep ${
        index === activeIndex && "carouselStep--active"
      }`}
      onMouseEnter={() => onMouseEnter(index)}
    >
      <PrideCard img={img} heading={title} description={description} />
    </div>
  );
};
const FabricTypesWrapper = () => {
  const [activeIndex, setActiveIndex] = useState(DEFAULT_INDEX);

  const onMouseEnter = (index) => {
    setActiveIndex(index);
  };

  return (
    <div className="platformWrapper__fabricTypesWrapper carouselView">
      {Object.values(Pridearray).map((step, index) => (
        <CarouselStep
          index={index}
          key={index}
          {...step}
          activeIndex={activeIndex}
          onMouseEnter={onMouseEnter}
        />
      ))}
    </div>
  );
};
const PrideCarousel = () => {
  return <FabricTypesWrapper />;
};

export default PrideCarousel;
