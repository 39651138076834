import BiddingIcon from "../../assets/images/partner/banknotes.svg";
import CapacityIcon from "../../assets/images/partner/ReliableDelivery.svg";
import PaymentsIcon from "../../assets/images/partner/singlePlatform.svg";
import SecurityIcon from "../../assets/images/partner/Credit (1).svg";
import BiddingIcon2 from "../../assets/images/partner/s1.svg";
import CapacityIcon2 from "../../assets/images/partner/s2.svg";
import PaymentsIcon2 from "../../assets/images/partner/s3.svg";
import SecurityIcon2 from "../../assets/images/partner/w4.svg";

export const PARTNER_SECTION_CONTENT = [
  {
    icon: BiddingIcon,
    text:'Overall savings of upto 6%',
  },
  {
    icon: CapacityIcon,
    text:'Reliable delivery with  95% OTIF',
  },
  {
    icon: PaymentsIcon,
    text:'Large choice on a single platform'
  },
  {
    icon: SecurityIcon,
    text:'Credit at affordable rates from NBFCs/Banks'
  },
];
export const PARTNER_SECTION2_CONTENT = [
  {
    icon: BiddingIcon2,
  },
  {
    icon: CapacityIcon2,
  },
  {
    icon: PaymentsIcon2,
  },
  {
    icon: SecurityIcon2,
  },
];
