import React from "react";
import ErrorUI from "../../components/ErrorUI";
import Footer from "../../components/Footer";
function Error() {
  return (
    <>
      <ErrorUI />
      <Footer />
    </>
  );
}

export default Error;
