import PlaceOrder from '../../assets/images/banner/PlaceOrdersInstantly.svg';
import TrackLiveOrders from '../../assets/images/banner/TrackLiveOrders.svg';
import RequestFabric from '../../assets/images/banner/RequestFabric.svg';
import AccountManager from '../../assets/images/banner/AccountManager.svg';
import OrderUpdates from '../../assets/images/banner/OrderUpdates.svg';
import SupplierNetwork from '../../assets/images/banner/SupplierNetwork.svg';

export const customerFacilities=[
    {
        img:PlaceOrder,
        title:"Place orders instantly",
    },
    {
        img:TrackLiveOrders,
        title:"Track live orders",
    },
    {
        img:RequestFabric,
        title:"Request fabric of your choice",
    },
    {
        img:AccountManager,
        title:"Dedicated account managers",
    },
    {
        img:OrderUpdates,
        title:"Get timely order updates",
    },
    {
        img:SupplierNetwork,
        title:"Get best quotes from our robust supplier network",
    },
]