
export const LOOM_TYPE_REVERSE = {
  1: 'Power Loom',
  2: 'Sulzer',
  3: 'Air Jet',
  4: 'Water Jet'
};
export const cottonArray=[
  "https://miscellaneous-lf.s3.ap-south-1.amazonaws.com/static/category/Cotton/live-rate-1.jpg",
  "https://miscellaneous-lf.s3.ap-south-1.amazonaws.com/static/category/Cotton/live-rate-2.jpg",
  "https://miscellaneous-lf.s3.ap-south-1.amazonaws.com/static/category/Cotton/live-rate-3.jpg",
  "https://miscellaneous-lf.s3.ap-south-1.amazonaws.com/static/category/Cotton/live-rate-4.jpg",
]
export const rayonArray=[
  "https://miscellaneous-lf.s3.ap-south-1.amazonaws.com/static/category/Rayon/live-rate-1.jpg",
  "https://miscellaneous-lf.s3.ap-south-1.amazonaws.com/static/category/Rayon/live-rate-2.jpg",
  "https://miscellaneous-lf.s3.ap-south-1.amazonaws.com/static/category/Rayon/live-rate-3.jpg",
  "https://miscellaneous-lf.s3.ap-south-1.amazonaws.com/static/category/Rayon/live-rate-4.jpg",
]
export const requestLinkUrl = (path, data) => {
  let link = path ? path + '/?' : '';
  let newData = Object.keys(data);
  newData.map((key, index) => {
    link += `${key}=${data[key]}${index === newData.length - 1 ? '' : '&'}`;
  });
  return link;
};
export const Navigate={
  liveQualityCreateOrderConfirmDetails: '/customer/create-order/Live-Quality/ConfirmDetails',
  editspecifications: '/customer/editspecifications',
}
export const PRINTED_REQUIREMENT_INDEX = 3;
