import React, { useState } from "react";
import "./style.scss";
import { questionsAnswers } from "./base";
import add from "../../assets/images/faq/add.svg";
import minus from "../../assets/images/faq/minus.svg";

const HeroSectionFAQ = ({faqData}) => {
  const [selectedIndex, setSelectedIndex] = useState(null);

  return (
    <div className="heroSectionFAQ_container">
      <h1 className="faqtitle">Frequently asked questions</h1>
      <p className="subTitle">
        If you have any further questions please contact us.
      </p>
      <div style={{ marginTop: 48 }}>
        {faqData?.map((item, index) => {
          return (
            <div
              className="faqContainer"
              style={{
                borderColor: selectedIndex === index ? "#0067E2" : "#D4D4D4",
              }}
              onClick={() => {
                if (selectedIndex == index) {
                  setSelectedIndex(null);
                } else {
                  setSelectedIndex(index);
                }
              }}
              key={index}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p className="question"> {item?.question}</p>
                <img src={selectedIndex === index ? minus : add} alt="" />
              </div>

              {selectedIndex === index && (
                <p className="answer"> {item?.answer}</p>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default HeroSectionFAQ;
