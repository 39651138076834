import React, { useState ,useRef} from "react";
import BuyingHousesImg from "../../assets/images/banner/Buying.webp";
import "./style.scss";
import InfoContainer from "../InfoContainer";
import Modal from "../Modal";
import CategorySubmissionForm from "../CategorySubmissionForm";

const BuyingHouse = () => {
  const [showModal, setShowModal] = useState(false);
  const [isSubmitSuccessful, setSubmitSuccess] = useState(false);
  const pointerData=[
    'Nominate manufacturers to help them source high quality fabrics',
    'Nominate brands and attract more with Locofast’s collections on emerging trends'
  ]
  const formRef=useRef()
  return (
    <div className="buyingHouseContainer">
      <div  className="buyingHouseImg"> 
        <img src={BuyingHousesImg} alt="icon " width="100%" height="100%" />
      </div>
      <InfoContainer
        title="Buying houses"
        subHeading="Help your customers by referring Locofast!"
        pointerData={pointerData}
        btnTxt="Send us a message"
        onClick={() => {
          setShowModal(true)
          formRef?.current?.resetForm();
        }}
      />
        <Modal
        show={showModal}
        _handleClose={() => {
          setShowModal(false)
          setSubmitSuccess(false);
        }}
        title={!isSubmitSuccessful ? "Fill details" : ""}
      >
        <CategorySubmissionForm
          closedText={"Close"}
          submitText={"Request a call back"}
          _handleClose={() => {
            setShowModal(false)
            setSubmitSuccess(false);
          }}
          isSubmitSuccessful={isSubmitSuccessful}
          setSubmitSuccess={setSubmitSuccess}
          fillDetails={true}
          formType="buyingHouse"
          formRef={formRef}
        />
      </Modal>
    </div>
  );
};
export default BuyingHouse;
