import Certificate1 from "../../assets/images/certificates/certificate1.svg";
import Certificate2 from "../../assets/images/certificates/certificate2.svg";
import Certificate3 from "../../assets/images/certificates/certificate5.svg";
import Certificate4 from "../../assets/images/certificates/certificate3.svg";
import Certificate5 from "../../assets/images/certificates/certificate4.svg";
import Certificate6 from "../../assets/images/certificates/certificate6.svg";

export const CERTIFICATES = [
  {
    certificate: Certificate1,
  },
  {
    certificate: Certificate2,
  },
  {
    certificate: Certificate4,
  },
  {
    certificate: Certificate3,
  },
  {
    certificate: Certificate5,
  },
  {
    certificate: Certificate6,
  },
];
