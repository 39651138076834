import React from "react";
import BenefitCard from "../../BenefitCard";
import styled from "styled-components";
import "./styles.scss";
import { useState } from "react";
import { useRef } from "react";
import leftarw from "../../../assets/images/testimonial/leftarrow.svg";
import rightarw from "../../../assets/images/testimonial/rightarrow.svg";
import seperator from "../../../assets/images/testimonial/separator.svg";
import disable from "../../../assets/images/testimonial/disable.svg";
import disabler from "../../../assets/images/testimonial/disabler.svg";
import { TESTIMONIALS } from "../../Carousel/Testimonialsarray";

export const SlideButton = styled.button`
  color: #ffffff;
  padding: 10px;
  background-color: #f6f7f9;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  float: right;
  &:active {
    position: relative;
    top: 1px;
  }
  &:focus {
    outline: 0;
  }
`;
const DEFAULT_INDEX = 0;
const CarouselStep = ({
  index,
  activeIndex,
  img,
  customer,
  subtitle,
  description,
  onMouseEnter,
  starImg,
  
}) => {
  return (
    <div
      className={`carouselStep ${
        index === activeIndex && "carouselStep--active"
      }`}
    >
      <BenefitCard
        icon={img}
        title={customer}
        subtitle={subtitle}
        description={description}
        isAdwords={true}
        starImg={starImg}
      />
    </div>
  );
};
const CarouselTypesWrapper = (props) => {
  return (
    <div
      className="testimonialList__testimonialList carouselView"
      ref={props.reference}
    >
      {Object.values(TESTIMONIALS).map((step, index) => (
        <CarouselStep
          index={index}
          key={index}
          {...step}
          activeIndex={props.state}
        />
      ))}
    </div>
  );
};
const TestimonialsSection = () => {
  const [activeIndex, setActiveIndex] = useState(DEFAULT_INDEX);
  const ref = useRef(null);

  const scrollRight = (scrollOffset) => {
    if (activeIndex >= 2) {
      setActiveIndex(2);
    } else {
      ref.current.scrollLeft += scrollOffset;
      setActiveIndex(activeIndex + 1);
    }
  };
  const scrollLeft = (scrollOffset) => {
    if (activeIndex <= 0) {
      setActiveIndex(0);
    } else {
      ref.current.scrollLeft -= scrollOffset;
      setActiveIndex(activeIndex - 1);
    }
  };
  return (
    <div className="testimonialsSection">
      <div className="testimonialsSection__content">
        <div className="mainContent">
          <div className="firstLine">
            <div className="section__heading title">Customer first, Always</div>
            <div className="arrw">
              <div className="right">
                <img
                  src={activeIndex >= 2 ? disabler : rightarw}
                  alt="icon"
                  loading="lazy"
                  onClick={() => scrollRight(650)}
                />
              </div>
              <div className="left">
                <img
                  src={activeIndex <= 0 ? disable : leftarw}
                  alt="icon"
                  loading="lazy"
                  onClick={() => scrollLeft(650)}
                />
                <img
                  src={seperator}
                  alt="separator"
                  style={{ paddingLeft: "20px" }}
                />
              </div>
            </div>
          </div>
          <div className="section__subheading">
            Our customer-oriented approach helps us build long-term
            relationships with customer. We are obssessed with making the
            textile supply chain more efficient and transparent for our
            customers, while delivering the best results.
          </div>
        </div>
        <CarouselTypesWrapper state={activeIndex} reference={ref} />
      </div>
    </div>
  );
};

export default TestimonialsSection;
