import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AboutUs from "./pages/AboutUs";
import Careers from "./pages/Careers";
import HomePage from "./pages/HomePage";
import NavBar from "./components/NavBar";
import SupplierPage from "./pages/SupplierPage";
import WorkWithUs from "./pages/WorkPage";
import SwatchBox from "./pages/SwatchBox";
import Sustainability from "./pages/SustainPage";
import FaqPage from "./pages/FAQPage";
import MediaPage from "./pages/MediaPage";
import Navbar2 from "./components/NavBar2";
import Adwords from "./pages/Adwords";
import Error from "./pages/Error";
import {
  ABOUT_US_ROUTE,
  APP_ROUTE,
  SUPPLIERS_ROUTE,
  CAREERS_ROUTE,
  WORKWITHUS_ROUTE,
  FAQ_ROUTE,
  MEDIA_ROUTE,
  SWATCH_ROUTE,
  ADWORDS_ROUTE,
  SUSTAINABILITY_ROUTE,
  PRIVACY_POLICY_ROUTE,
  SUPPLIER_PROFILE,
  LINK_PAGE,
} from "./constants";

import "./assets/styles/global.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-tooltip/dist/react-tooltip.css";
import { hydrate, render } from "react-dom";
import PrivacyPage from "./pages/PrivacyPage";
import SupplierProfilePage from "./pages/SupplierProfilePage";
import LinkPage from "./pages/LinkPage";

const App = () => {
  const heroRef = useRef();
  const [scroll, setscroll] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleChange = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleChange);

    return () => window.removeEventListener("resize", handleChange);
  });
  return (
    <>
      <Router>
        <Switch>
          <Route
            exact
            render={(props) => (
              <div
                style={
                  !scroll
                    ? {
                        overflow: "hidden",
                        height: "100vh",
                      }
                    : null
                }
              >
                <NavBar heroRef={heroRef} setscroll={setscroll} />
                <HomePage {...props} heroRef={heroRef} />
              </div>
            )}
            path={APP_ROUTE}
          />
          <Route
            exact
            component={(props) => (
              <div
                style={
                  !scroll
                    ? {
                        overflow: "hidden",
                        height: "100vh",
                      }
                    : null
                }
              >
                <NavBar heroRef={heroRef} setscroll={setscroll} />
                <SupplierPage {...props} heroRef={heroRef} />
              </div>
            )}
            path={SUPPLIERS_ROUTE}
          />
          <Route
            exact
            component={(props) => (
              <div
                style={
                  !scroll
                    ? {
                        overflow: "hidden",
                        height: "100vh",
                      }
                    : null
                }
              >
                <Navbar2 heroRef={heroRef} setscroll={setscroll} />
                <AboutUs {...props} heroRef={heroRef} />
              </div>
            )}
            path={ABOUT_US_ROUTE}
          />
          <Route
            exact
            component={(props) => (
              <div
                style={
                  !scroll
                    ? {
                        overflow: "hidden",
                        height: "100vh",
                      }
                    : null
                }
              >
                <NavBar heroRef={heroRef} setscroll={setscroll} />
                <Careers {...props} heroRef={heroRef} />
              </div>
            )}
            path={CAREERS_ROUTE}
          />
          <Route
            exact
            component={(props) => (
              <div
                style={
                  !scroll
                    ? {
                        overflow: "hidden",
                        height: "100vh",
                      }
                    : null
                }
              >
                <Navbar2 heroRef={heroRef} setscroll={setscroll} />
                <WorkWithUs {...props} heroRef={heroRef} />
              </div>
            )}
            path={WORKWITHUS_ROUTE}
          />
          <Route
            exact
            component={(props) => (
              <div
                style={
                  !scroll
                    ? {
                        overflow: "hidden",
                        height: "100vh",
                      }
                    : null
                }
              >
                <Navbar2 heroRef={heroRef} setscroll={setscroll} />
                <FaqPage {...props} heroRef={heroRef} />
              </div>
            )}
            path={FAQ_ROUTE}
          />
          <Route
            exact
            component={(props) => (
              <div
                style={
                  !scroll
                    ? {
                        overflow: "hidden",
                        height: "100vh",
                      }
                    : null
                }
              >
                <Navbar2 heroRef={heroRef} setscroll={setscroll} />
                <MediaPage {...props} heroRef={heroRef} />
              </div>
            )}
            path={MEDIA_ROUTE}
          />
          <Route
            exact
            component={(props) => (
              <div
                style={
                  !scroll
                    ? {
                        overflow: "hidden",
                        height: "100vh",
                      }
                    : null
                }
              >
                {width > 576 ? (
                  <Navbar2
                    heroRef={heroRef}
                    setscroll={setscroll}
                    swatchPage={true}
                  />
                ) : (
                  <NavBar heroRef={heroRef} setscroll={setscroll} />
                )}
                <SwatchBox {...props} heroRef={heroRef} />
              </div>
            )}
            path={SWATCH_ROUTE}
          />
          <Route
            exact
            component={(props) => (
              <div
                style={
                  !scroll
                    ? {
                        overflow: "hidden",
                        height: "100vh",
                      }
                    : null
                }
              >
                {width > 576 ? (
                  <Navbar2
                    heroRef={heroRef}
                    setscroll={setscroll}
                    swatchPage={true}
                  />
                ) : (
                  <NavBar heroRef={heroRef} setscroll={setscroll} />
                )}
                <Sustainability {...props} heroRef={heroRef} />
              </div>
            )}
            path={SUSTAINABILITY_ROUTE}
          />
          <Route
            exact
            component={(props) => (
              <div
                style={
                  !scroll
                    ? {
                        overflow: "hidden",
                        height: "100vh",
                      }
                    : null
                }
              >
                <NavBar
                  heroRef={heroRef}
                  setscroll={setscroll}
                  adwords={true}
                />
                <Adwords {...props} heroRef={heroRef} />
              </div>
            )}
            path={ADWORDS_ROUTE}
          />
          <Route
            exact
            component={(props) => (
              <div
                style={
                  !scroll
                    ? {
                        overflow: "hidden",
                        height: "100vh",
                      }
                    : null
                }
              >
                <Navbar2
                  heroRef={heroRef}
                  setscroll={setscroll}
                  adwords={true}
                />
                <PrivacyPage />
              </div>
            )}
            path={PRIVACY_POLICY_ROUTE}
          />
          <Route
            exact
            path={SUPPLIER_PROFILE}
            component={(props) => (
              <div
                style={
                  !scroll
                    ? {
                        overflow: "hidden",
                        height: "100vh",
                      }
                    : null
                }
              >
                <NavBar
                  heroRef={heroRef}
                  setscroll={setscroll}
                  adwords={true}
                  supplierProfilePage
                />
                <SupplierProfilePage />
              </div>
            )}
          />
          <Route
            exact
            path={LINK_PAGE}
            component={(props) => (
              <div
                style={
                  !scroll
                    ? {
                        overflow: "hidden",
                        height: "100vh",
                      }
                    : null
                }
              >
                <NavBar
                  heroRef={heroRef}
                  setscroll={setscroll}
                  adwords={true}
                  supplierProfilePage
                />
                <LinkPage />
              </div>
            )}
          />
          <Route
            path="*"
            component={(props) => (
              <>
                <Navbar2 heroRef={heroRef} />
                <Error />
              </>
            )}
          />
        </Switch>
      </Router>
    </>
  );
};

// We need to only hydrate supplier profile pages 
const hydrateRoutePattern = /^\/suppliers\/[^/]+\/[^/]+\/[^/]+\/id=\d+\/?$/;

// Function to check if hydration is needed for the current page
function shouldHydrate() {
  const currentPath = window.location.pathname;
  return hydrateRoutePattern.test(currentPath);
}

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes() && shouldHydrate()) {
  console.log('hydrate', window.location.pathname)
  hydrate(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    rootElement
  );
} else {
  console.log('render', window.location.pathname)
  render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    rootElement
  );
}
