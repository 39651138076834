import starImg1 from "../../assets/images/testimonial/Stars.png";
import RaviImg from "../../assets/images/testimonial/ravi.png";
import PankajImg from "../../assets/images/testimonial/pankaj.png";
import BharatImg from "../../assets/images/testimonial/bharat.png";
import SonuImg from "../../assets/images/testimonial/Sonu.png";
import DeepakImg from "../../assets/images/testimonial/Deepak.png";
import VentakshImg from "../../assets/images/testimonial/Venkatsh.png";
import AshishImg from "../../assets/images/testimonial/Ashish.png";
import RakeshImg from "../../assets/images/testimonial/Rakesh.png";
import DineshImg from "../../assets/images/testimonial/Dinesh.png";
import KaushikImg from "../../assets/images/testimonial/Kaushik.png";
import starImg2 from "../../assets/images/testimonial/Stars4.5.png"

export const TESTIMONIALS = [
  {
    img: RaviImg,
    customer: "Ravi Khandelwal",
    subtitle: "Jaipur",
    description:
      "The first time, I was placing the order, I was doubtful about the fabric quality. In the past when I ordered online, I have had a very disappointing product. But the team sent me a sample. I became more confident and place the order.",
      starImg: starImg1
  },
  {
    img: PankajImg,
    customer: "Pankaj",
    subtitle: "Gurugram",

    description:
      "I was surprised to see my order being delivered before time, without any follow up. I have worked with many suppliers in the past but only with Locofast I had an easy, worry-free experience. I surely see myself placing orders on Locofast again.",
      starImg: starImg2
  },
  {
    img: BharatImg,
    customer: "Bharat Kumar ",
    subtitle: "Jaipur",
    description:
      "The entire team is very professional and transparent in their process. Their team went above and beyond in ensuring that my order gets delivered to me on-time and hassel free.The best part was that I could trace my order status until it was delivered.",
      starImg: starImg1
  },
  {
    img:SonuImg,
    customer: "Sonu Singh",
    subtitle: "Faridabad",
    description:
      "Locofast is transparent with what they do. In textile industry, it is quite unusual to find such partners whom you can trust.",
      starImg: starImg2
  },
  {
    img: DeepakImg,
    customer: "Deepak Kumar",
    subtitle: "Noida",

    description:
      "Locofast is one complete solution. The best thing about them is that they have a committed team who helps you with everything like assured quality and best price rate. I am glad to have partnered with them.",
      starImg: starImg1
  },
];
export const SUPPLIERTESTIMONIALS = [
  {
    img:VentakshImg,
    customer: "Venkatesh K",
    subtitle: "Tamil Nadu",
    description:
      "Locofast is transparent with its process and has clear guidelines for payment terms.Without any follow-up, I have always received my full payment on time, which reflects proficiency.",
      starImg: starImg1
  },
  {
    img: AshishImg,
    customer: "Ashish Kabra",
    subtitle: "Rajasthan",

    description:
      "With Locofast, I can plan my production timelines well in advance and become more efficient. The team is very proactive and kept me updated with the order status. Their dedication towards their partners is truly commendable. ",
      starImg: starImg2
  },
  {
    img:RakeshImg,
    customer: "Rakesh Jain",
    subtitle: "Maharashtra",
    description:
      "The best thing about Locofast is, you get orders according to your expertise. This saves a lot of time and reduces effort. With their platform, you also get the right customer and serve them efficiently.",
      starImg: starImg1
  },
  {
    img: DineshImg,
    customer: "Dinesh Jain",
    subtitle: "Maharashtra",
    description:
      "Locofast has helped me win many orders. After signing up with them I don't have to struggle to secure orders.",
      starImg: starImg1
  },
  {
    img:KaushikImg,
    customer: "Kaushik Heda",
    subtitle: "Maharashtra",

    description:
      "After signing up at their platform, I have been getting continue opportunity to serve customers across India and globally. ",
      starImg: starImg2
  },
];
