import React from "react";
import  Brand1  from "../../assets/images/adwords/brands/1.svg";
import  Brand2  from "../../assets/images/adwords/brands/2.svg";
import  Brand3  from "../../assets/images/adwords/brands/3.svg";
import  Brand4  from "../../assets/images/adwords/brands/4.svg";
import  Brand5  from "../../assets/images/adwords/brands/5.svg";
import  Brand6  from "../../assets/images/adwords/brands/6.svg";

const CUSTOMERS = [
  {
    title: "First Cry",
    src:
     Brand1
  },
  {
    title: "Vishal Mega Mart",
    src:
     Brand2
  },
  {
    title: "Campus Sutra",
    src:
      Brand3,
  },
  {
    title: "Cherry Crumble",
    src:
     Brand4,
  },
  {
    title: "First Cry",
    src:
      Brand6,
  },
  {
    title: "Myntra",
    src:
      Brand5,
  },

];
const BRANDS= [
  {
    title: "First Cry",
    src:
     Brand1
  },
  {
    title: "Vishal Mega Mart",
    src:
     Brand2
  },
  {
    title: "Campus Sutra",
    src:
      Brand3,
  },
  {
    title: "Cherry Crumble",
    src:
     Brand4,
  },
  {
    title: "Myntra",
    src:
      Brand5,
  },

];
const Customers = ({ featureSectionRef }) => (
  <section ref={featureSectionRef} className="customersSection">
    <h3 className="section__heading heading">Our customers work for</h3>
    <div className="customers desktop">
      {BRANDS.map(({ title, src }, index) => (
        <img src={src} alt={title} key={index} />
      ))}
    </div>
    <div className="customers mobile">
      {CUSTOMERS.map(({ title, src }, index) => (
        <img src={src} alt={title} key={index} />
      ))}
    </div>
    <div className="customersSection__meta">30+ more brands</div>
  </section>
);

export default Customers;
