import React from "react";
import Phone from "../../assets/images/support/phone.svg";
import Message from "../../assets/images/support/message.svg";
import "./style.scss";

const Support = () => {
  return (
    <div className="support">
      <div className="heading">Get support when you need them</div>
      <p>
        Our team delivers first-class support round the clock and helps you at
        every step of your journey with us.
      </p>
      <div className="support__options">
        <div className="box">
          <img src={Phone} alt="call" loading="lazy" />
          <div className="box2">
            <div className="agenda">Connect with Fabric expert</div>
            <div className="help">+91 89203 92418</div>
          </div>
        </div>
        <div className="box">
          <img src={Message} alt="message" loading="lazy" />
          <div className="box2">
            <div className="agenda">Email your questions</div>
            <div className="help">marketing@locofast.com</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
