import React, { Component } from "react";
import { Link } from "react-router-dom";
import Modal from "../Modal";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsAndConditions from "./TermsAndConditions";
import LogoBlue from "../../assets/images/logo-blue.svg";
import Logoo from "../../assets/images/logoo-blue.svg";
import { ReactComponent as PhoneIcon } from "../../assets/images/generic/phone.svg";
import { ReactComponent as MailIcon } from "../../assets/images/generic/mail.svg";
import { ReactComponent as PlayIcon } from "../../assets/images/generic/play-store.svg";
import { ReactComponent as Linkedin } from "../../assets/images/generic/LinkedIn.svg";
import { ReactComponent as FaceBook } from "../../assets/images/generic/facebook.svg";
import { ReactComponent as Instagram } from "../../assets/images/generic/instagram.svg";
import { ReactComponent as YouTube } from "../../assets/images/generic/youtube.svg";
import {
  ABOUT_US_ROUTE,
  CAREERS_ROUTE,
  SUPPLIERS_ROUTE,
  WORKWITHUS_ROUTE,
  FAQ_ROUTE,
  MEDIA_ROUTE,
  BLOG_ROUTE,
  PRIVACY_POLICY_ROUTE,
} from "../../constants";

import "./style.scss";
import FooterCarousel from "../FooterCarousel";

const MODALS = {
  Privacy: "privacy",
  TermsNCondition: "termsNConditions",
};
class Footer extends Component {
  state = { show: false, modal: "" };

  _showModal = (modal) => {
    document.body.style.overflow = "hidden";
    this.setState({ show: true, modal });
  };

  _hideModal = () => {
    document.body.style.overflow = "auto";
    this.setState({ show: false });
  };

  Modals = {
    [MODALS.Privacy]: {
      title: "Privacy Policy",
      component: <PrivacyPolicy _handleClose={this._hideModal} />,
    },
    [MODALS.TermsNCondition]: {
      title: "Terms and Conditions",
      component: <TermsAndConditions _handleClose={this._hideModal} />,
    },
  };

  render() {
    return (
      <div className={this.props.isHomePage ? "page-footer homefooterClass":"page-footer " }>
        {this.props.isUpper && (
          <>
            {" "}
            <div className="main-heading">
              <h2>Our offices</h2>
            </div>
            <div className="upper-footer">
              <div className="address">
                <h3>New Delhi</h3>
                <p>
                Desk Connect - First Floor, Plot No-2, Kh.No. 384/2, 100 feet Rd, Mehrauli-Gurgaon Rd, Opp. Corporation Bank, Ghitorni, New Delhi 110030{" "}
                </p>
              </div>
              <div className="address">
                <h3>Noida</h3>
                <p>
                  1st Floor, Plot No.D-107, Vyapar Marg, D Block, Noida Sector-2
                  Uttar Pradesh 201301
                </p>
              </div>
              <div className="address">
                <h3>Gurugram</h3>
                <p>
                  NH8-Udyog Vihar, 90B, Delhi - Jaipur Expy, Udyog Vihar, Sector
                  18, Gurugram, Haryana 122008{" "}
                </p>
              </div>
              <div className="address">
                <h3>Jaipur</h3>
                <p className="jaipur">
                  34/6, Kiran Path, Mansarovar Sector 3, Mansarovar, Jaipur,
                  Rajasthan 302020
                </p>
              </div>
              <div className="address">
                <h3>Ahmedabad</h3>
                <p>
                  SSPACIA 4th floor, Agrawal Complex, Chimanlal Girdharlal Rd,
                  Ahmedabad, Gujarat, 380009{" "}
                </p>
              </div>
              <div className="address">
                <h3>Bangladesh</h3>
                <p>
                  Workstation 101, Uttara Tower, Level 4. 1 Jashimuddin Avenue,
                  Sector 3, Uttara C/A Dhaka 1230, Bangladesh
                </p>
              </div>
            </div>
            <div className="carousel">
              <FooterCarousel />
            </div>
            <hr />
          </>
        )}
        <div className="page-footer__content">
          <div className="page-footer__block page-footer__block--left">
            <div className="page-footer__block__header">
              <img src={Logoo} className="symbol" alt="Logoo" />
              <img src={LogoBlue} alt="Locofast logo" />
            </div>
            <div className="page-footer__description">
              <div className="social-icons">
                <div className="social-icon">
                  <Linkedin />
                  <a
                    href="https://in.linkedin.com/company/locofast"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Linkedin
                  </a>
                </div>
                <div className="social-icon">
                  <FaceBook />
                  <a
                    href=" https://www.facebook.com/Locofast/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Facebook
                  </a>
                </div>
                <div className="social-icon">
                  <Instagram />
                  <a
                    href=" https://www.instagram.com/locofast_official"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Instagram
                  </a>
                </div>
                <div className="social-icon">
                  <YouTube />
                  <a
                    href=" https://www.youtube.com/channel/UCpbLo84Y_BVTcIzHHrBjIkw"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Youtube
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="page-footer__block page-footer__block--wrapper">
            <div className="page-footer__block page-footer__block--wrapper--center">
              <Link to={ABOUT_US_ROUTE}>About us</Link>
              <Link to={CAREERS_ROUTE}>Customers</Link>
              <Link to={SUPPLIERS_ROUTE}>Suppliers</Link>

              <Link to={MEDIA_ROUTE}>Media & awards</Link>
            </div>
            <div className="page-footer__block page-footer__block--wrapper--center">
              <Link to={WORKWITHUS_ROUTE}>Life at Locofast</Link>
              <a href={BLOG_ROUTE}>Blog</a>
              <Link to={FAQ_ROUTE}>FAQs</Link>
              <Link to={PRIVACY_POLICY_ROUTE}>Privacy Policy</Link>
            </div>
          </div>
          <div className="page-footer__block page-footer__block--right">
            <div className="contact mobile">
              <PhoneIcon />{" "}
              <a href="tel:+918920392418" className="number">
                +91 8920 392 418
              </a>
            </div>
            <div className="contact">
              <MailIcon />
              <a href="mailto:mail@locofast.com">mail@locofast.com</a>
            </div>
            <div className="download">
              <p>Download the mobile app</p>
              <a
                href="https://play.google.com/store/apps/dev?id=4992841798607401290"
                target="_blank"
                rel="noopener noreferrer"
              >
                <PlayIcon />
              </a>
            </div>
          </div>
        </div>
        <div className="copyrights">
          <hr />
          <p>© {new Date().getFullYear()} Locofast Online Services Pvt. Ltd.</p>
        </div>
        {this.state.show && (
          <Modal
            show={this.state.show}
            _handleClose={this._hideModal}
            title={this.Modals[this.state.modal].title}
            modalClass="privacy-modal"
          >
            {this.Modals[this.state.modal].component}
          </Modal>
        )}
      </div>
    );
  }
}

export default Footer;
