import React, { useRef } from "react";
import { Helmet } from "react-helmet";

import HeroSection from "../../components/Hero/SwatchBox";
import Footer from "../../components/Footer";
import DesignSection from "../../components/DesignSection";
import BoxSection from "../../components/BoxSection";
import Category from "../../components/Category";
import Support from "../../components/Support";
import SourceSection from "../../components/SourceSection";
import VarietySection from "../../components/VarietySection";
import ProductSection from "../../components/ProductSection";

const AboutUs = ({ heroRef }) => {
  const featureSectionRef = useRef(null);
  const handleScroll = () => {
    featureSectionRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <>
      <Helmet>
        <title>Locofast: Cloud Textile Factory | Swatch Box</title>
        <meta
          name="description"
          content="Locofast is the leading marketplace for fabrics. We provide a wide range of cotton, rayon, and sustainable, eco-friendly fabrics at the best price."
        />
      </Helmet>
      <HeroSection heroRef={heroRef} handleScroll={handleScroll} />
      <DesignSection />
      <SourceSection />
      <BoxSection />
      <Category />
      <ProductSection />
      <VarietySection />
      <Support />
      <Footer />
    </>
  );
};

export default AboutUs;
