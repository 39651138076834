import wCottonImg from "../../assets/images/platform/webp/cotton.webp";
import wRayonImg from "../../assets/images/platform/webp/rayon.webp";
import wPolyesterImg from "../../assets/images/platform/webp/polyester.webp";
import wLinenImg from "../../assets/images/platform/webp/linen.webp";
import wDobbiesImg from "../../assets/images/platform/webp/dobbies.webp";
import wDenimImg from "../../assets/images/platform/webp/denim.webp";

import CottonImg from "../../assets/images/platform/cotton.png";
import RayonImg from "../../assets/images/platform/rayon.png";
import PolyesterImg from "../../assets/images/platform/polyester.png";
import LinenImg from "../../assets/images/platform/linen.png";
import DobbiesImg from "../../assets/images/platform/dobbies.png";
import DenimImg from "../../assets/images/platform/denim.png";

export const FABRIC_TYPES = [
    {
      image: CottonImg,
      webpImage: wCottonImg,
      title: "Cotton",
      description:
        "Count range - 10's to 100's, 100% cotton & cotton blends including cotton-polyester, cotton spandex, cotton-rayon, etc.",
    },
    {
      image: RayonImg,
      webpImage: wRayonImg,
      title: "Rayon",
      description:
        "Count range - 30's & 40's. GSM - 60 to 180 gsm. 100% viscose & viscose blends, modal, tencel, rayon, etc.",
    },
    {
      image: PolyesterImg,
      webpImage: wPolyesterImg,
      title: "Polyster",
      description:
        "60 to 180 gsm. 100% polyester & polyester blends including polyester-cotton, polyester spandex & polyester-rayon, etc.",
    },
  ];
  export const FABRIC_TYPES_INTERNATIONAL = [
    {
      image: CottonImg,
      webpImage: wCottonImg,
      title: "Cotton",
      description:
        "Count range - 10's to 100's, 100% cotton & cotton blends including cotton-polyester, cotton spandex, cotton-rayon, etc.",
    },
    {
      image: LinenImg,
      webpImage: wLinenImg,
      title: "Linen",
      description:
        "Linen is a versatile fabric. 125 to 150 gsm. Pure 100% linen, Cotton and viscose linen blends, Dyed/Printed Linens",
    },
    {
      image: DobbiesImg,
      webpImage: wDobbiesImg,
      title: "Dobbies",
      description:
        "Dobby is a fabric weave pattern produced on the dobby loom. Cotton dobby, viscose lurex, Silver/Gold lurex, Floral dobby, etc.",
    },
    {
        image: DenimImg,
        webpImage: wDenimImg,
        title: "Denim",
        description:
          "Weight - 4.5 oz to 13 oz Width - 57-58” to 66” Composition - 100% cotton / 98% cotton, 2% spandex / Cotton poly spandex ",
      }
  ];