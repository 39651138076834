const ValidationTick = ({height = 16, width = 16, fill = "#D4D4D4", ...restProps}) => (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <circle cx={8} cy={8} r={6.667} fill={fill} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.272 5.662c.26.26.26.682 0 .943L7.54 10.338a.667.667 0 0 1-.943 0L4.73 8.471a.667.667 0 1 1 .943-.942l1.396 1.395 3.261-3.262c.26-.26.683-.26.943 0Z"
        fill="#fff"
      />
    </svg>
  )
  
const LabelWithTick = ({
    name = '',
    label,
    isValid,
    labelClass = '',
    containerClass,
    withValidation = true,
    style = {},
  }) => (
    <div
      className={`flex ${containerClass}`}
    >
      <label className={`${labelClass}`}>
        {label}
        </label>
  
      {
        withValidation ? (
          !isValid ? <ValidationTick />
            : <ValidationTick fill='#27AE60' />
        ) : ''
      }
    </div>
  );
  
  export default LabelWithTick;
  