import React from "react";

import { ReactComponent as ScrollDown } from "../../../assets/images/hero/scroll-down.svg";
import iconarrow from "../../../assets/images/hero/Vector.svg";
import "../heroStyles.scss";

const Hero = ({ navigateToForm, heroRef, handleScroll }) => {
  return (
    <section className="hero-suppliers" ref={heroRef}>
      <div className="hero__content">
        <h1 className="hero__heading heading-style">
          Let’s Grow, <br /> Together. Fast.
        </h1>
        <div className="hero__summary text-center">
          <p className="text-center text-white">
            Become a part of our Pan-India network and <br />
            grow your business at no additional cost.
          </p>
        </div>
        <button
          type="submit"
          className="btn btn-primary hero__action"
          value="button"
          onClick={navigateToForm}
        >
          Become a partner
          <img className="arrow" src={iconarrow} alt="icon" loading="lazy" />
        </button>
        <div className="scroll-down" onClick={handleScroll}>
          <ScrollDown />
        </div>
      </div>
    </section>
  );
};

export default Hero;
